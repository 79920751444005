import { useEffect, useState } from 'react';

import ProcessState from 'components/ProcessState';

import 'scss/draw/AfterDraw.scss';

type AfterDrawProps = {
  processState: boolean;
  setProcessState: React.Dispatch<React.SetStateAction<boolean>>;
};

const AfterDraw: React.FC<AfterDrawProps> = ({
  processState,
  setProcessState,
}) => {
  const [waitingState, setWaitingState] = useState<boolean>(false);
  const [metaDataState, setMetaDataState] = useState<boolean>(false);

  useEffect(() => {
    const processing: NodeJS.Timeout = setTimeout(() => {
      setWaitingState(true);
      setTimeout(() => {
        setMetaDataState(true);
        setProcessState(true);
      }, 4000);
    }, 4000);

    if (processing && processState) return clearTimeout(processing);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="after-draw">
        <h4>Pending Transaction</h4>

        <ul>
          <li className={`process-done`}>
            <ProcessState processState={true} />
            Confirming Transaction
          </li>
          <li className={`${waitingState ? 'process-done' : ''}`}>
            <ProcessState processState={waitingState} />
            Waiting for Block Confirmation
          </li>
          <li className={`${metaDataState ? 'process-done' : ''}`}>
            <ProcessState processState={metaDataState} />
            Generating Meta Data
          </li>
        </ul>
      </div>
    </>
  );
};

export default AfterDraw;
