import { useMemo, useState } from 'react';

import '../scss/CollectionSelectBox.scss';

import { ReactComponent as ArrowDark } from '../assets/icons/arrow_dark.svg';

type CollectionSelectBoxProps = {
  title: string;
  options: string[];
  active: boolean;
  onBoxClick: () => void;
  onOptionSelected: (selected: string) => void;
};

const CollectionSelectBox: React.FC<CollectionSelectBoxProps> = (props) => {
  const [selected, setSelected] = useState<string>('All');

  const options = useMemo(() => ['All', ...props.options], [props.options]);
  const { title, active, onBoxClick, onOptionSelected } = props;

  return (
    <section className="classification-class">
      <h3>{title}</h3>
      <button
        onClick={() => onBoxClick()}
        className={`${selected === 'All' ? '' : 'selected-option'}`}
      >
        {selected}
        <ArrowDark />
      </button>
      {active && (
        <div>
          {options.map((option) => (
            <p
              onClick={() => {
                setSelected(option);
                onOptionSelected(option);
              }}
              key={option}
            >
              {option}
            </p>
          ))}
        </div>
      )}
    </section>
  );
};

export default CollectionSelectBox;
