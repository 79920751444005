import classes from './WeaponImage.module.scss';

const WeaponImage = ({ weaponImage }: { weaponImage: string }) => {
  return (
    <div className={classes.container}>
      <img style={{ width: '100%' }} src={weaponImage} alt="weapon_img" />
    </div>
  );
};

export default WeaponImage;
