import { useCallback, useState } from 'react';

import axios from 'axios';
import { ethers } from 'ethers';

import {
  useWeaponBaseContract,
  useWeaponSettingContract,
} from 'hooks/useContract';

import { useWeaponCollection } from './useWeaponCollection';

const useWeapon = () => {
  const { getWeaponTokenURI } = useWeaponCollection();
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawing, setIsDrawing] = useState(false);
  const [baseUri, setBaseUri] = useState('');
  const [drawWeaponTokenIDs, setDrawWeaponTokenIDs] = useState<Array<string>>(
    [],
  );

  const weaponBase = useWeaponBaseContract();

  const drawWeapon = useCallback(
    async (amount: number) => {
      const _tokenIDs: Array<string> = [];
      try {
        const estimated_gasPrice = await weaponBase.estimateGas.drawZodiWeapon(
          _typecasting(amount),
        );
        const padding_gasLimit = estimated_gasPrice
          .div(10)
          .add(estimated_gasPrice);

        const txHash = await weaponBase.drawZodiWeapon(_typecasting(amount), {
          gasLimit: padding_gasLimit,
        });
        setIsDrawing(true);
        const receipt = await txHash.wait();
        if (receipt.status === 0) {
          alert('Please try again later');
          throw new Error('network error');
        }
        receipt.events?.forEach(async (x: any) => {
          if (x.event === 'DrawZodiWeapon') {
            for (const item of x.args.tokenIds) {
              _tokenIDs.push(item.toString());
            }
            const _URI = await getWeaponTokenURI(
              _tokenIDs[_tokenIDs.length - 1],
            );
            const retrying = setInterval(async () => {
              await axios
                .get(_URI)
                .then((res) => {
                  setBaseUri(_URI);
                  setDrawWeaponTokenIDs(_tokenIDs);
                  setIsLoading(false);
                  clearInterval(retrying);
                })
                .catch((err) => {
                  console.log(err);
                });
            }, 600);
          }
        });
      } catch (error) {
        throw error;
      }
    },
    [weaponBase, getWeaponTokenURI],
  );

  const weaponSettingContract = useWeaponSettingContract();

  const getWeaponDrawPayRate = useCallback(async () => {
    try {
      let payRate = await weaponSettingContract.getPayRate();
      return Number(payRate.toString());
    } catch (error) {
      throw error;
    }
  }, [weaponSettingContract]);

  return {
    drawWeapon,
    getWeaponDrawPayRate,
    // verificationDraw,
    isLoading,
    drawWeaponTokenIDs,
    baseUri,
    isDrawing,
  };
};

//NOTE x util
const _typecasting = (v: number) => {
  return ethers.BigNumber.from(v).mul(ethers.BigNumber.from(10).pow(18));
};

export { useWeapon };
