import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DrawResult from '../containers/draw/DrawResult';
import Drawing from '../containers/draw/Drawing';

import CardAd from '../components/CardAd';
import NonConnect from '../components/NonConnect';
import WalletAddress from '../components/WalletAddress';

import useCleanUpFlow from '../hooks/useCleanUpFlow';
import { useWeb3 } from '../hooks/useWeb3';
import useZodiac from '../hooks/useZodiac';
import useZodium from '../hooks/useZodium';

import '../scss/draw/DrawZodiac.scss';

import { RootState } from '../store';

const DrawZodiac: React.FC = () => {
  useCleanUpFlow();
  const { active } = useWeb3();
  const { getBalanceOf } = useZodium();
  const { getDrawPayRate } = useZodiac();

  const [balance, setBalance] = useState('0');
  const [payRate, setPayRate] = useState<number>(0);
  const drawed = useSelector((state: RootState) => state.drawed.drawed);

  useEffect(() => {
    if (active) {
      getBalanceOf().then((res) => {
        setBalance(res);
      });

      getDrawPayRate().then((res) => {
        setPayRate(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, drawed]);

  return (
    <main className="draw-zodiac">
      {active ? (
        drawed ? (
          <DrawResult />
        ) : (
          <>
            <h1 style={{ paddingTop: '120px' }}>Draw 12KINDs NFT Card Packs</h1>
            <p>
              One Zodiac Pack includes <span>3 Zodiac</span> <br />
              characters for <span>{payRate} ZODI</span>
            </p>
            <div>
              <h3>Buy ZODI on PancakeSwap</h3>
              <div className="wallet-address-wrapper">
                <WalletAddress
                  account={'0x0ccA2F5561Bb0fCa88e5b9B48b7Fbf000349c357'}
                  prepend="ZODI Contract Address: "
                />
                <WalletAddress
                  account={'0x0ccA2F5561Bb0fCa88e5b9B48b7Fbf000349c357'}
                  short
                  prepend="ZODI Contract Address: "
                />
              </div>
              <p className="balance">ZODI Balance : {balance}</p>
              <p>
                To open ZODI Packs, <br />
                you need to have ZODI ready <br />
                in your wallet.
              </p>
            </div>
            <div>
              <Drawing token={balance} drawed={drawed} payRate={payRate} />
              <CardAd />
            </div>
          </>
        )
      ) : (
        <>
          <NonConnect
            title="Draw 12KINDs NFT Card Packs"
            description={`One Zodiac Pack includes 3 Zodiac characters for 100 ZODI`}
            buttonTitle="Connect Wallet"
          />
          <NonConnect
            title="Draw 12KINDs NFT Card Packs"
            description={`One Zodiac Pack includes\n3 Zodiac characters for 100 ZODI`}
            buttonTitle="Connect Wallet"
          />
        </>
      )}
    </main>
  );
};

export default DrawZodiac;
