import { useCallback, useState } from 'react';

export type WeaponClassificationRarity = {
  rarity: number;
  checked: boolean;
}[];

const useWeaponClassification = () => {
  const weaponRarityOptions = Array.from(Array(5).keys()).map((key: number) => {
    return {
      rarity: key + 1,
      checked: true,
    };
  });

  const [weaponClassficationRarity, setWeaponClassficationRarity] =
    useState<WeaponClassificationRarity>(weaponRarityOptions);

  const selectRarity = useCallback(
    (rarity) => {
      setWeaponClassficationRarity(
        weaponClassficationRarity.map((item) =>
          item.rarity === rarity
            ? { ...item, checked: !item.checked }
            : { ...item, checked: item.checked },
        ),
      );
    },
    [weaponClassficationRarity],
  );

  const [selectedWeapon, setSelectedWeapon] = useState('All');
  const [selectedElement, setSelectedElement] = useState('All');

  const [weaponSelectActive, setWeaponSelectActive] = useState<boolean>(false);
  const [elementSelectActive, setElementSelectActive] =
    useState<boolean>(false);

  return {
    weaponClassficationRarity,
    selectRarity,

    selectedWeapon,
    setSelectedWeapon,

    selectedElement,
    setSelectedElement,

    weaponSelectActive,
    setWeaponSelectActive,

    elementSelectActive,
    setElementSelectActive,
  };
};

export type useWeaponClassificationType = ReturnType<
  typeof useWeaponClassification
>;

export default useWeaponClassification;
