export type WeaponType = {
  id: number;
  booster_id: number;
  tx_hash: string;
  random_number: string;
  image: string;
  external_url: string;
  description: string;
  name: string;
  attributes: WeaponAttributes;
  signature: string;
};

export type WeaponAttributes = {
  rarity: number;
  name: string;
  element: string;
  stackedExp: number;
  power: number;
  level: number;
};

export const weaponElements = ['Water', 'Wood', 'Fire', 'Earth', 'Metal'];
export const weaponNames = [
  'Ninja Star',
  'Horn',
  'Knuckles',
  'Carrot',
  'Fire Ball',
  'Flute',
  'Hoof',
  'Shield',
  'Magic Stick',
  'Eggs Bucket',
  'Bone',
  'Pearl Necklace',
];
