import { starSignArray, tierArray, tierArrayUntouchable } from '../lib/zodiac';

import '../scss/CardPropertiesGallery.scss';

import { ReactComponent as ArrowDark } from '../assets/icons/arrow_dark.svg';

type CardPropertiesProps = {
  tier: string;
  starSign: string;
  setTier: React.Dispatch<React.SetStateAction<string>>;
  setStarSign: React.Dispatch<React.SetStateAction<string>>;
  untouchable?: boolean;
};

const CardPropertiesGallery: React.FC<CardPropertiesProps> = ({
  tier,
  starSign,
  setTier,
  setStarSign,
  untouchable = false,
}) => {
  const previous = (
    current: string,
    array: string[],
    setter: React.Dispatch<React.SetStateAction<string>>,
  ): void => {
    const nowIndex: number = array.indexOf(current);

    if (nowIndex > 0) setter(array[nowIndex - 1]);
  };

  const next = (
    current: string,
    array: string[],
    setter: React.Dispatch<React.SetStateAction<string>>,
  ): void => {
    const nowIndex: number = array.indexOf(current);

    if (nowIndex < array.length - 1) setter(array[nowIndex + 1]);
  };

  return (
    <table className="card-properties-gallery">
      <thead>
        <tr>
          <th className="table-title">Properties</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Tier:</th>
          <td>
            <ArrowDark
              onClick={
                untouchable
                  ? () => previous(tier, tierArrayUntouchable, setTier)
                  : () => previous(tier, tierArray, setTier)
              }
            />
            {tier}
            <ArrowDark
              onClick={
                untouchable
                  ? () => next(tier, tierArrayUntouchable, setTier)
                  : () => next(tier, tierArray, setTier)
              }
            />
          </td>
        </tr>
        <tr>
          <th>Star Sign:</th>
          <td>
            <ArrowDark
              onClick={() => previous(starSign, starSignArray, setStarSign)}
            />
            {starSign}
            <ArrowDark
              onClick={() => next(starSign, starSignArray, setStarSign)}
            />
          </td>
        </tr>
        <tr>
          <th>Land:</th>
          <td>{tier === 'Common' ? '-' : 'Yes'}</td>
        </tr>
        <tr>
          <th>Motion:</th>
          <td>
            {tier === 'Legendary' ||
            tier === 'Untouchable' ||
            tier === 'Soul Zodiac'
              ? 'Yes'
              : '-'}
          </td>
        </tr>
        <tr>
          <th>Edge:</th>
          <td>{tier === 'Soul Zodiac' ? 'Yes' : '-'}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default CardPropertiesGallery;
