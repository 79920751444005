import { useEffect, useState } from 'react';

import Button from '../../components/Button';

import { useWeb3 } from '../../hooks/useWeb3';
import useZodiac from '../../hooks/useZodiac';

import { WariningStateType } from '../../types/warning';

import '../../scss/draw/BeforeDraw.scss';

import { ReactComponent as Minus } from '../../assets/icons/minus.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as Warning } from '../../assets/icons/warning.svg';

let REMAININGDRAWCOUNT: number = 0;

type BeforeDrawProps = {
  token: string;
  packs: number;
  setPacks: React.Dispatch<React.SetStateAction<number>>;
  verityAllowance: () => Promise<void>;
  payRate: number;
};

const BeforeDraw: React.FC<BeforeDrawProps> = ({
  token,
  packs,
  setPacks,
  verityAllowance,
  payRate,
}) => {
  const { verificationDraw } = useZodiac();
  const { active } = useWeb3();
  const [isDraw, setIsDraw] = useState(false);
  const [warningState, setWarningState] = useState<WariningStateType>({
    warning: false,
    description: '',
  });

  const packPlus = () => {
    if (packs < 10) setPacks(packs + 1);
  };

  const packMinus = () => {
    if (packs > 0) setPacks(packs - 1);
  };

  const drawZodiac = async () => {
    if (packs === 0) {
      setWarningState({
        ...warningState,
        warning: true,
        description: 'Zero',
      });
    } else if (
      // 사용자가 갖고 있는 토큰 보다 필요한 토큰 수(팩 수 * 팩당 토큰 수)가 많은 경우 || 남은 뽑기 수 보다 뽑으려는 팩 수가 많은 경우
      packs * payRate > parseInt(token) ||
      packs > REMAININGDRAWCOUNT
    ) {
      setWarningState({
        ...warningState,
        warning: true,
        description: 'Over',
      });
    } else {
      setWarningState({ ...warningState, warning: false });
      await verityAllowance();
    }
  };

  useEffect(() => {
    if (active) {
      verificationDraw().then((res) => {
        if (res <= 0) {
          setIsDraw(true);
        }
        REMAININGDRAWCOUNT = res;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <div className="before-draw">
      <p>Number of Packs Drawing</p>
      <div>
        <Minus onClick={packMinus} />
        {packs}
        <Plus onClick={packPlus} />
      </div>

      <div className="button-wrapper">
        <Button
          title="Buy ZODI"
          buttonStyle="orange-border"
          onClick={() => window.open('https://pancakeswap.finance/swap')}
        />

        <div>
          <Button
            title="Draw Zodiac"
            onClick={drawZodiac}
            isDisabled={isDraw}
          />
          {warningState.warning && (
            <p className="warning">
              <Warning />
              Please choose the number
              <br className="mobile" /> of packs to draw.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BeforeDraw;
