import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MaxUint256 } from '@ethersproject/constants';
import { ReactComponent as Confirm } from 'assets/icons/confirm.svg';
import { BigNumber, ContractReceipt } from 'ethers';
import { modalClose, modalOpen } from 'store/modal';

import Loading from 'components/Loading';

import { ApprovalState } from 'types/contract';

import { useERC20Contract, useZodiumContract } from './useContract';
import useTokenAllowance from './useTokenAllowance';
import { useWeb3 } from './useWeb3';

export const useApproveToken = (
  tokenAddress: string,
  spender: string,
  amount: BigNumber,
): [ApprovalState, () => Promise<ContractReceipt | undefined>] => {
  const { account } = useWeb3();
  const tokenContract = useERC20Contract(tokenAddress);
  const [allowance, getAllowance] = useTokenAllowance(
    tokenAddress,
    account ?? undefined,
    spender,
  );
  const dispatch = useDispatch();

  const approve = useCallback(async () => {
    try {
      dispatch(
        modalOpen({
          alertImage: <Loading />,
          title: 'Waiting for Approve Token',
          description: `\nConfirm this transaction in your wallet\n\n\n`,
          dimZ: 2500,
        }),
      );
      const tx = await tokenContract.approve(spender, MaxUint256);
      dispatch(
        modalOpen({
          alertImage: <Loading />,
          title: 'BSC Transaction Submitted',
          description: `\nWaiting for transaction confirmed\n\n\n`,
          dimZ: 2500,
        }),
      );

      const receipt = await tx.wait();
      getAllowance();
      dispatch(
        modalOpen({
          alertImage: <Confirm />,
          title: 'BSC Transaction Confirmed',
          description: undefined,
          mainButtonTitle: 'Close',
          mainButtonEvent: () => {
            dispatch(modalClose());
          },
          dimZ: 2500,
        }),
      );

      return receipt;
    } catch (e) {
      console.error(e);
      dispatch(
        modalOpen({
          title: 'Alert!',
          description: 'BSC Transaction Error',
          mainButtonTitle: 'OK',
          mainButtonEvent: () => {
            dispatch(modalClose());
          },
          dimZ: 2500,
        }),
      );
    }
  }, [tokenContract, spender, dispatch, getAllowance]);

  return useMemo(() => {
    if (allowance.lt(amount)) {
      return [ApprovalState.NOT_APPROVED, approve];
    } else {
      return [ApprovalState.APPROVED, approve];
    }
  }, [allowance, amount, approve]);
};

export const useApproveZodi = (
  spender: string,
  amount: BigNumber,
): [ApprovalState, () => Promise<ContractReceipt | undefined>] => {
  const { account } = useWeb3();
  const zodiumContract = useZodiumContract();
  const [allowance, setAllowance] = useState(BigNumber.from(0));
  const dispatch = useDispatch();

  const getAllowance = useCallback(async () => {
    if (!account) return;
    const allowance = await zodiumContract.allowance(account, spender);
    setAllowance(allowance);
  }, [account, spender, zodiumContract]);

  const approve = useCallback(async () => {
    try {
      dispatch(
        modalOpen({
          alertImage: <Loading />,
          title: 'Waiting for Approve Token',
          description: `\nConfirm this transaction in your wallet\n\n\n`,
          dimZ: 2500,
        }),
      );
      const tx = await zodiumContract.approve(spender, MaxUint256);
      dispatch(
        modalOpen({
          alertImage: <Loading />,
          title: 'BSC Transaction Submitted',
          description: `\nWaiting for transaction confirmed\n\n\n`,
          dimZ: 2500,
        }),
      );

      const receipt = await tx.wait();
      getAllowance();
      dispatch(
        modalOpen({
          alertImage: <Confirm />,
          title: 'BSC Transaction Confirmed',
          description: undefined,
          mainButtonTitle: 'Close',
          mainButtonEvent: () => {
            dispatch(modalClose());
          },
          dimZ: 2500,
        }),
      );

      return receipt;
    } catch (e) {
      console.error(e);
      dispatch(
        modalOpen({
          title: 'Alert!',
          description: 'BSC Transaction Error',
          mainButtonTitle: 'OK',
          mainButtonEvent: () => {
            dispatch(modalClose());
          },
          dimZ: 2500,
        }),
      );
    }
  }, [zodiumContract, spender, dispatch, getAllowance]);

  useEffect(() => {
    getAllowance();
  }, [getAllowance]);

  return useMemo(() => {
    if (allowance.lt(amount)) {
      return [ApprovalState.NOT_APPROVED, approve];
    } else {
      return [ApprovalState.APPROVED, approve];
    }
  }, [allowance, amount, approve]);
};
