import CardPropertiesTable from '../../components/CardPropertiesTable';
import Paragraph from '../../components/Paragraph';
import VideoTag from '../../components/VideoTag';

import { getCardPath } from '../../lib/cardPath';

import '../../scss/home/AboutUniverse.scss';

import { ReactComponent as ImageBackground } from '../../assets/icons/image_background.svg';

const AboutUniverseUpdate: React.FC = () => {
  return (
    <article className="about-universe-wrapper">
      <section className="about-universe">
        <Paragraph
          title={'The Universe of Zodium'}
          description={`12 KINDs are devided into five tiers: Common, Rare, Legendary, Soul Zodiac, and Untouchable.\nEach zodiac sign has its inherent traits and unique qualities assigned.\nEach NFT is access to the upcoming web, mobile, and metaverse games.\nHigher tier NFT provides better and more in-game benefits.`}
          whiteTitle={true}
        />

        <Paragraph
          title={'The Universe of Zodium'}
          description={`12 KINDs are devided into five tiers:\nCommon, Rare, Legendary, Soul Zodiac, and Untouchable.\nEach zodiac sign has its inherent traits and unique qualities assigned.\nEach NFT is access to the upcoming web, mobile, and metaverse games.\nHigher tier NFT provides better and more in-game benefits.`}
          whiteTitle={true}
        />

        <Paragraph
          title={'The Universe of Zodium'}
          description={`12 KINDs are devided into five tiers:\nCommon, Rare, Legendary, Soul Zodiac,\nand Untouchable.\nEach zodiac sign has its inherent traits\nand unique qualities assigned.\nEach NFT is access to\nthe upcoming web, mobile,\nand metaverse games.\nHigher tier NFT provides better and more\nin-game benefits.`}
          whiteTitle={true}
        />

        <div className="zodiac-sign">
          <div className="zodiac-sign-item">
            <div>
              <ImageBackground />
              <img src={getCardPath('Common', 'Tiger', 'Virgo')} alt="common" />
            </div>
            <CardPropertiesTable
              title="Tier 1 : Common"
              tier="Common"
              starSign="Random"
              land="No"
              motion="No"
              edge="No"
            />
          </div>
          <div className="zodiac-sign-item">
            <div>
              <ImageBackground />
              <img src={getCardPath('Rare', 'Tiger', 'Virgo')} alt="Rare" />
            </div>
            <CardPropertiesTable
              title="Tier 2 : Rare"
              tier="Rare"
              starSign="Random"
              land="Yes"
              motion="No"
              edge="No"
            />
          </div>
          <div className="zodiac-sign-item">
            <div>
              <ImageBackground />
              <VideoTag path={getCardPath('Legendary', 'Tiger', 'Virgo')} />
            </div>
            <CardPropertiesTable
              title="Tier 3 : Legendary"
              tier="Legendary"
              starSign="Random"
              land="Yes"
              motion="Yes"
              edge="No"
            />
          </div>
        </div>
      </section>
    </article>
  );
};

export default AboutUniverseUpdate;
