import { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import useObserverTriggerMutation from 'api/useObserverTriggerMutation';
import { ReactComponent as Confirm } from 'assets/icons/confirm.svg';
import axios from 'axios';
import { CHARIS } from 'config';
import { ContractReceipt } from 'ethers';
import { modalClose, modalOpen } from 'store/modal';
import { setUserOwnWeaponTokenIDs } from 'store/userWeapon';
import { clearForUpgrade } from 'store/weaponUpgrade';

import Loading from 'components/Loading';

import { useWeaponBaseContract } from './useContract';
import { useWeaponCollection } from './useWeaponCollection';
import { useWeaponCustomize } from './useWeaponCustomize';
import { useWeb3 } from './useWeb3';

const useWeaponUpgradeCallback = () => {
  const { account } = useWeb3();
  const { getWeaponTokenURI } = useWeaponCollection();
  const { getWeaponTokenIDs } = useWeaponCustomize();
  const weaponbaseContract = useWeaponBaseContract();
  const observerTrigger = useObserverTriggerMutation();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const tokenIdsForUse = useRef<number[]>();
  const idListBeforeUpgrade = useRef<string[]>();
  const [isSucess, setIsSuccess] = useState(false);
  const [receipt, setReceipt] = useState<ContractReceipt | null>(null);

  // refetch users weapon data
  const refetch = useCallback(() => {
    tokenIdsForUse.current?.forEach((id) => {
      queryClient.removeQueries(['weapon', String(id)]);
    });

    const id = setInterval(async () => {
      const tokenIds = await getWeaponTokenIDs();
      console.log({
        tokenIds: tokenIds.length,
        idListBeforeUpgrade: idListBeforeUpgrade.current?.length,
        tokenIdsForUse: tokenIdsForUse.current?.length,
      });
      if (
        idListBeforeUpgrade.current &&
        tokenIdsForUse.current &&
        tokenIds.length ===
          idListBeforeUpgrade.current.length - tokenIdsForUse.current.length + 1
      ) {
        clearInterval(id);
        dispatch(setUserOwnWeaponTokenIDs(tokenIds));
        dispatch(
          modalOpen({
            alertImage: <Confirm />,
            title: 'BSC Transaction Confirmed',
            description: undefined,
            mainButtonTitle: 'Close',
            mainButtonEvent: () => {
              dispatch(modalClose());
            },
            dimZ: 2500,
          }),
        );
      }
    }, 1000);
  }, [dispatch, getWeaponTokenIDs, idListBeforeUpgrade, queryClient]);

  // check new weapon metadata is uploaded
  const checkNewWeaponUploaded = useCallback(
    async (newId: string) => {
      const uri = await getWeaponTokenURI(newId);
      const intervalId = setInterval(() => {
        axios
          .get(uri)
          .then(() => {
            clearInterval(intervalId);
            refetch();
          })
          .catch(console.error);
      }, 1000);
    },
    [getWeaponTokenURI, refetch],
  );

  // when got receipt and trigger observer suceessfully
  useEffect(() => {
    if (isSucess && receipt) {
      dispatch(clearForUpgrade());

      const event = receipt.events?.find(
        (e) => e.event === 'UpgradeZodiWeapon',
      );
      if (event) {
        const newId = event.args?.['tokenId'];
        if (newId) {
          checkNewWeaponUploaded(newId);
        }
      }
    }
  }, [isSucess, receipt, dispatch, checkNewWeaponUploaded]);

  return useCallback(
    async (tokenIds: number[]) => {
      try {
        dispatch(
          modalOpen({
            alertImage: <Loading />,
            title: 'Waiting for Confirmation',
            description: `\nConfirm this transaction in your wallet\n\n\n`,
            dimZ: 2500,
          }),
        );

        console.log('tokenIds', tokenIds);
        tokenIdsForUse.current = tokenIds;
        idListBeforeUpgrade.current = await getWeaponTokenIDs();

        const tx = await weaponbaseContract.upgradeWeapons(tokenIds);

        dispatch(
          modalOpen({
            alertImage: <Loading />,
            title: 'BSC Transaction Submitted',
            description: `\nWaiting for transaction confirmed\n\n\n`,
            dimZ: 2500,
          }),
        );

        const receipt = await tx.wait();
        setReceipt(receipt);

        observerTrigger(undefined, {
          async onSuccess() {
            setIsSuccess(true);
            queryClient.invalidateQueries(['zodiBalance']);
            queryClient.invalidateQueries(['balance', CHARIS, account]);
          },
        });

        return receipt;
      } catch (e) {
        dispatch(
          modalOpen({
            title: 'Alert!',
            description: 'BSC Transaction Error',
            mainButtonTitle: 'OK',
            mainButtonEvent: () => {
              dispatch(modalClose());
            },
            dimZ: 2500,
          }),
        );
        throw e;
      }
    },
    [
      dispatch,
      weaponbaseContract,
      observerTrigger,
      getWeaponTokenIDs,
      queryClient,
      account,
    ],
  );
};

export default useWeaponUpgradeCallback;
