import { createAction, handleActions } from 'redux-actions';

import { WeaponType } from '../types/weapon';

const SET_WEAPON_PACK = 'newPack/SET_WEAPON_PACK' as const;
const CLEAR_WEAPON_PACK = 'newPack/CLEAR_WEAPON_PACK' as const;

export const setWwapon = createAction(SET_WEAPON_PACK);
export const clearWeapon = createAction(CLEAR_WEAPON_PACK);

export type NewPackAction =
  | ReturnType<typeof setWwapon>
  | ReturnType<typeof clearWeapon>;

const initialState: WeaponType[] = [];

const newWeapon = handleActions(
  {
    [SET_WEAPON_PACK]: (state: WeaponType[], action: NewPackAction) =>
      state.concat(action.payload),
    [CLEAR_WEAPON_PACK]: (state: WeaponType[]) => (state = []),
  },
  initialState,
);

export default newWeapon;
