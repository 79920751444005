import { createAction, handleActions } from 'redux-actions';

const OPEN = 'modal/OPEN' as const;
const CLOSE = 'modal/CLOSE' as const;

export const modalOpen = createAction(OPEN);
export const modalClose = createAction(CLOSE);

export type ModalAction =
  | ReturnType<typeof modalOpen>
  | ReturnType<typeof modalClose>;

export type ModalState = {
  open: boolean;
  alertImage?: any;
  title: string;
  description?: any;
  mainButtonTitle?: string;
  mainButtonEvent?: () => void;
  subButtonTitle?: string;
  subButtonEvent?: () => void;
  dimClose?: () => void;
  dimZ: number;
};

const initialState: ModalState = {
  open: false,
  alertImage: undefined,
  title: '',
  description: undefined,
  mainButtonTitle: undefined,
  mainButtonEvent: undefined,
  subButtonTitle: undefined,
  subButtonEvent: undefined,
  dimClose: undefined,
  dimZ: 0,
};

const modal = handleActions(
  {
    [OPEN]: (state: ModalState, action: ModalAction) => {
      const {
        alertImage,
        title,
        description,
        mainButtonTitle,
        mainButtonEvent,
        subButtonTitle,
        subButtonEvent,
        dimClose,
        dimZ,
      } = action.payload;
      return {
        ...state,
        open: true,
        alertImage: alertImage,
        title: title,
        description: description,
        mainButtonTitle: mainButtonTitle,
        mainButtonEvent: mainButtonEvent,
        subButtonTitle: subButtonTitle,
        subButtonEvent: subButtonEvent,
        dimClose: dimClose,
        dimZ: dimZ,
      };
    },
    [CLOSE]: (state: ModalState) => ({ ...state, open: false }),
  },
  initialState,
);

export default modal;
