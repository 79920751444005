import MintedWeaponStatus from 'components/MintedWeaponStatus';

import { AnimalEnum } from 'lib/zodiac';

import 'scss/draw/MintedWeaponListContainer.scss';

export default function MintedWeaponListContainer({
  weaponLimits,
}: {
  weaponLimits: number[];
}) {
  const renderList = () => {
    const weaponList = [];
    let i = 0;
    for (const animal in AnimalEnum) {
      weaponList.push(
        <MintedWeaponStatus
          key={i++}
          zodiacType={animal as AnimalEnum}
          totalLimit={weaponLimits[i]}
        />,
      );
    }
    return weaponList;
  };

  return (
    <section className="remain-weapon">
      <div className="desc">10,000 for each weapon</div>
      {renderList()}
    </section>
  );
}
