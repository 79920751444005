export type Contract = {
  addr: string;
  abi: any;
};

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}
