import { useDispatch } from 'react-redux';

import CardUpgrade from '../../components/CardUpgrade';

import { CardType } from '../../types/card';

import { modalUpgradeOpen } from '../../store/modalUpgrade';
import { MyPackState } from '../../store/myPack';

type SelectedProps = {
  myPack: MyPackState;
  resultCard: CardType | undefined;
  usedCard: CardType[];
};

const SelectedList: React.FC<SelectedProps> = ({
  myPack,
  resultCard,
  usedCard,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="selected-list">
      {resultCard
        ? usedCard.map((card: CardType) => (
            <CardUpgrade
              id={card.id}
              tier={card.tier}
              animal={card.animal}
              starSign={card.starSign}
              image={card.image}
              key={card.id}
              used={true}
            />
          ))
        : myPack.pack.map(
            (card: CardType) =>
              card.checked && (
                <CardUpgrade
                  id={card.id}
                  tier={card.tier}
                  animal={card.animal}
                  starSign={card.starSign}
                  image={card.image}
                  key={card.id}
                  used={false}
                />
              ),
          )}
      {myPack.checkedLength < 6 && !resultCard && (
        <div
          className={`select-card ${myPack.checkedLength === 0 ? 'short' : ''}`}
          onClick={() => dispatch(modalUpgradeOpen())}
        >
          Select your Zodiac Characters
        </div>
      )}
    </div>
  );
};

export default SelectedList;
