import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Button from '../components/Button';
import Loading from '../components/Loading';
import Pagination from '../components/Pagination';
import WeaponCard from '../components/Weapon/WeaponCard';
import WeaponProperties from '../components/Weapon/WeaponProperties';

import { WeaponClassificationRarity } from '../hooks/useWeaponClassification';

import { WeaponType } from '../types/weapon';

import '../scss/CollectionList.scss';

import { ReactComponent as ArrowDark } from '../assets/icons/arrow_dark.svg';

type WeaponCollectionListProp = {
  modal?: boolean;
  active: boolean;
  weaponCollection: WeaponType[];
  classificationRarity: WeaponClassificationRarity;
  weaponOption: string;
  elementOption: string;
  collectionLoading?: boolean;
  onSelect?: (selected: WeaponType) => void;
  type?: 'levelUP' | 'upgrade';
};

export default function WeaponCollectionList(props: WeaponCollectionListProp) {
  const [sortBy, setSortBy] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const filteredItem = props.weaponCollection
    .filter((w) => {
      let rarityFilter = false,
        weaponNameFilter = false,
        elementFilter = false;
      if (
        props.classificationRarity.find(
          (el) => el.checked && el.rarity === w.attributes.rarity,
        )
      ) {
        rarityFilter = true;
      }

      if (props.weaponOption === 'All' || props.weaponOption === w.name) {
        weaponNameFilter = true;
      }

      if (
        props.elementOption === 'All' ||
        props.elementOption === w.attributes.element
      ) {
        elementFilter = true;
      }
      return rarityFilter && weaponNameFilter && elementFilter;
    })
    .sort((a, b) => {
      if (sortBy) return b.attributes.rarity - a.attributes.rarity;
      else return a.attributes.rarity - b.attributes.rarity;
    });

  const indexOfFirst = currentPage * 12;
  const indexOfLast = indexOfFirst + 12;

  const currentCards = (): WeaponType[] => {
    let cur: WeaponType[] = filteredItem.slice(indexOfFirst, indexOfLast);

    return cur;
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [props.classificationRarity, props.weaponOption, props.elementOption]);

  return (
    <section
      className={
        props.modal
          ? 'collection-list-wrapper-modal'
          : 'collection-list-wrapper'
      }
    >
      <h2>{filteredItem.length} Weapons</h2>
      <p>
        sort by.{' '}
        <span onClick={() => setSortBy(!sortBy)}>
          {sortBy ? 'Highest Rarity' : 'Lowest Rarity'}
          <ArrowDark />
        </span>
      </p>

      {props.collectionLoading ? (
        <Loading />
      ) : filteredItem.length ? (
        <>
          <div className="collection-list">
            {currentCards().map((weapon: WeaponType) => (
              <div key={weapon.id} className="weapon">
                <div
                  id={String(weapon.id)}
                  style={{
                    cursor: props.onSelect ? 'pointer' : 'auto',
                  }}
                  onClick={() => props.onSelect?.(weapon)}
                >
                  <WeaponCard weapon={weapon} forModal={props.modal} />
                </div>
                <WeaponProperties weapon={weapon} forModal={props.modal} />
              </div>
            ))}
          </div>

          {filteredItem.length > 12 && (
            <Pagination
              current={currentPage}
              perPage={12}
              totalLength={filteredItem.length}
              setCurrentPage={setCurrentPage}
              modal={props.modal}
            />
          )}
        </>
      ) : (
        <div className="no-card">
          {props.type === 'upgrade' && <p>There is no upgradable weapon</p>}
          {props.type !== 'upgrade' && (
            <p>
              Craft Soul weapons <br className="pad" />
              to makes the Zodiac Animals <br className="mobile" />
              even more powerful.
            </p>
          )}
          <Link to="/drawWeapon">
            <Button
              title="Draw Weapon"
              buttonStyle="orange-bright"
              width="260px"
              height="60px"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }}
            />
          </Link>
        </div>
      )}
    </section>
  );
}
