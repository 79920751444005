import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../components/Button';
import Input from '../components/Input';
import Loading from '../components/Loading';

import useCollectionV1 from '../hooks/useCollectionV1';
import useV2Swap from '../hooks/useV2Swap';
import { useWeb3 } from '../hooks/useWeb3';
import useZodiCollection from '../hooks/useZodiCollection';

import accountString from '../lib/accountString';

import styles from '../scss/V2Transform.module.scss';

import swapDiscovering from '../api/swap_discovery';
import arrow from '../assets/icons/arrow_white.png';
import { ReactComponent as Confirm } from '../assets/icons/confirm.svg';
import { ReactComponent as Minus } from '../assets/icons/minus.svg';
import { ReactComponent as Plus } from '../assets/icons/plus.svg';
import { connectWalletToggle } from '../store/connectWallet';
import { modalClose, modalOpen } from '../store/modal';

const V2Transform: React.FC = () => {
  const { active, account } = useWeb3();
  const dispatch = useDispatch();
  const collection = useZodiCollection();
  const collectionV1 = useCollectionV1();
  const v2Swap = useV2Swap();

  const [v1Balance, setV1Balance] = useState('0');
  const [v2Balance, setV2Balance] = useState('0');
  const [swapCnt, setSwapCnt] = useState(0); // swap할 수량

  useEffect(() => {
    if (!active) return;

    collectionV1
      .getBalanceOf()
      .then((b) => setV1Balance(b))
      .catch((e) => console.error(e));
    collection
      .getBalanceOf()
      .then((b) => setV2Balance(b))
      .catch((e) => console.error(e));
  }, [active, collection, collectionV1]);

  const onTransformBtnClick = async () => {
    if (!account) return;
    dispatch(
      modalOpen({
        alertImage: <Loading />,
        title: 'Waiting for Confirmation',
        description: `\nConfirm this transaction in your wallet\n\n\n`,
        dimZ: 1200,
      }),
    );
    try {
      const isApprovedAll = await collectionV1.isApprovedForAllToSwap(account);
      // console.log('isApprovedAll', isApprovedAll);
      if (!isApprovedAll) {
        await collectionV1.approveForAllToSwap();
      }

      const tokenIdArr: number[] = [];
      for (let i = 0; i < swapCnt; i++) {
        const tokenId = await collectionV1.getTokenOfOwnerByIndex(i);
        tokenIdArr.push(Number(tokenId));
      }

      // console.log(tokenIdArr);
      const { discovered_items, hashed, v, r, s } = await swapDiscovering(
        tokenIdArr,
      );

      // console.log(discovered_items, hashed, v, r, s);

      await v2Swap.updateTokenForV2(discovered_items, hashed, v, r, s);

      dispatch(
        modalOpen({
          alertImage: <Confirm />,
          title: 'V2 Transformation Completed!',
          description: undefined,
          mainButtonTitle: 'OK',
          mainButtonEvent: () => {
            dispatch(modalClose());
            setSwapCnt(0);
          },
          dimZ: 1200,
        }),
      );
    } catch (e) {
      console.error(e);
      dispatch(modalClose());
    }
  };

  const plusSwapCnt = () => {
    const limit = Number(v1Balance) < 5 ? Number(v1Balance) : 5;
    if (swapCnt < limit) {
      setSwapCnt((cur) => cur + 1);
    }
  };

  const minusSwapCnt = () => {
    if (swapCnt > 0) {
      setSwapCnt((cur) => cur - 1);
    }
  };

  return (
    <main className={styles.main}>
      <div className={styles.banner}>
        <h1>The NFT V2 Transform</h1>
        <p className={styles.pc}>
          The 12KINDs NFT will be transformed into V2.{<br />}
          Upon completion of the transformation, the V1 will be disposed of.
          {<br />}
          For mint, only V2 will be available.
        </p>
        <p className={styles.mobile}>
          The 12KINDs NFT will be{<br />}
          transformed into V2.{<br />}
          Upon completion of the{<br />}
          transformation,{<br />}
          the V1 will be disposed of.{<br />}
          For mint, only V2 will be available.
        </p>
        <div className={styles.hints}>
          <div className={styles.hintWhyv2}>Why V2?</div>
          <div className={styles.hintWhy5nfts}>
            Why 5 NFTs<span>/Transform?</span>
          </div>
        </div>
        {active ? (
          <>
            <div className={styles.addressPc}>
              Your Wallet: {accountString(account, false)}
            </div>
            <div className={styles.addressMobile}>
              Your Wallet: {accountString(account, true)}
            </div>
            <div className={styles.nfts}>
              <div className={styles.v1}>
                <h3>V1 NFT</h3>
                <Input
                  type={'number'}
                  placeholder={'0'}
                  suffix={'KINDs'}
                  value={v1Balance}
                  readonly={true}
                />
              </div>
              <img src={arrow} alt={''} />
              <div className={styles.v2}>
                <h3>V2 NFT</h3>
                <Input
                  type={'number'}
                  placeholder={v2Balance}
                  suffix={'KINDs'}
                  readonly={true}
                />
              </div>
            </div>
            <div className={styles.swapCnt}>
              <Minus onClick={minusSwapCnt} />
              {swapCnt}
              <Plus onClick={plusSwapCnt} />
            </div>
            <div>
              <Button
                title={'Transform'}
                isDisabled={swapCnt <= 0}
                buttonStyle={'orange'}
                width="156px"
                height="62px"
                fontSize="18px"
                textColor={swapCnt <= 0 ? '#9F9F9F' : 'white'}
                onClick={() => onTransformBtnClick()}
              />
            </div>
          </>
        ) : (
          <div style={{ marginTop: '74px' }}>
            <Button
              title="Connect Wallet"
              width="174px"
              height="62px"
              fontSize="18px"
              buttonStyle="orange"
              onClick={() => dispatch(connectWalletToggle())}
            />
          </div>
        )}
      </div>
    </main>
  );
};

export default V2Transform;
