import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import axios from 'axios';

import ButtonUpgrade from '../containers/upgrade/ButtonUpgrade';
import SelectedList from '../containers/upgrade/SelectedList';

import Button from '../components/Button';
import Card from '../components/Card';
import Loading from '../components/Loading';
import Paragraph from '../components/Paragraph';

import useCheckError from '../hooks/useCheckError';
import useCleanUpFlow from '../hooks/useCleanUpFlow';
import { useWeb3 } from '../hooks/useWeb3';
import useZodiCollection from '../hooks/useZodiCollection';
import useZodiac from '../hooks/useZodiac';

import { CardType } from '../types/card';

import '../scss/Upgrade.scss';

import { RootState } from '../store';
import { modalClose, modalOpen } from '../store/modal';
import { modalSoulZodiacOpen } from '../store/modalSoulZodiac';

let baseURI: string = '';

const Upgrade: React.FC = () => {
  useCleanUpFlow();
  const dispatch = useDispatch();
  const { fuseZodiac, isLoading } = useZodiac();
  const { active } = useWeb3();
  const { approvalForAll, getBaseUriFromContract } = useZodiCollection();

  const myPack = useSelector((state: RootState) => state.myPack);

  const [resultCard, setResultCard] = useState<CardType>();
  const [resultCardTmp, setResultCardTmp] = useState<string>();
  const [usedCard, setUsedCard] = useState<CardType[]>([]);

  const upgrade = async () => {
    try {
      dispatch(
        modalOpen({
          alertImage: <Loading />,
          title: 'Waiting for Confirmation',
          description: '\nConfirm this transaction in your wallet\n\n\n',
          mainButtonTitle: undefined,
          mainButtonEvent: undefined,
          subButtonTitle: undefined,
          subButtonEvent: undefined,
          dimZ: 1500,
        }),
      );

      await approvalForAll();

      let tokenIDs: Array<number> = [];
      let usedCardTmp: CardType[] = [];
      myPack.pack.map((card: CardType) => {
        if (card.checked) {
          tokenIDs.push(Number(card.id));

          usedCardTmp.push(card);
        }

        return null;
      });
      setUsedCard(usedCardTmp);
      await fuseZodiac(tokenIDs, setResultCardTmp);
    } catch (error) {
      console.error(error);
      dispatch(modalClose());
    }
  };

  const [warningState, checkError] = useCheckError(myPack, upgrade);

  const getResultCard = async () => {
    const res = await axios.get(`${baseURI}${resultCardTmp}`);

    if (
      res.data.attributes[2].value === 'Common' ||
      res.data.attributes[2].value === 'Rare'
    ) {
      setResultCard({
        id: res.data.id,
        tier: res.data.attributes[2].value,
        animal: res.data.name,
        starSign: res.data.attributes[0].value,
        image: res.data.image,
        checked: false,
      });
    } else {
      setResultCard({
        id: res.data.id,
        tier: res.data.attributes[2].value,
        animal: res.data.name,
        starSign: res.data.attributes[0].value,
        image: res.data.animation_url,
        checked: false,
      });
    }
  };

  useEffect(() => {
    if (active) {
      getBaseUriFromContract().then((res) => {
        baseURI = res;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useEffect(() => {
    if (resultCardTmp) getResultCard();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultCardTmp]);

  useEffect(() => {
    if (resultCard?.tier === 'Soul Zodiac')
      dispatch(modalSoulZodiacOpen(resultCard));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultCard]);

  useEffect(() => {
    if (!isLoading) {
      //NOTE x isLoading 값이 false가 되면 로딩창이 사라진다.
      dispatch(modalClose());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <main className="upgrade">
      <Paragraph
        title="Upgrade Zodiac Characters"
        description={`Zodiac cards are upgradable by achieving specific conditions\nand merging specific cards with cards.`}
      />
      <Paragraph
        title="Upgrade Zodiac Characters"
        description={`Zodiac cards are upgradable\nby achieving specific conditions\nand merging specific cards with cards.`}
      />
      <div className="note">
        <h6>Note.</h6>
        <p>
          Refer to our ‘
          <a
            href={'https://whitepaper.zodium.io/guide'}
            target="_blank"
            rel="noreferrer"
          >
            Upgrading Rule
          </a>
          ’ page to understand <br />
          how to upgrade zodiac characters.
        </p>
      </div>
      <SelectedList
        myPack={myPack}
        resultCard={resultCard}
        usedCard={usedCard}
      />
      <ButtonUpgrade
        resultCard={resultCard}
        checkError={checkError}
        warningState={warningState}
      />

      {resultCard && (
        <div className="upgrade-complete">
          <h3>Find out the Upgrading Result Below</h3>
          <div>
            <Card
              id={resultCard.id}
              tier={resultCard.tier}
              animal={resultCard.animal}
              starSign={resultCard.starSign}
              image={resultCard.image}
            />
            <Link to={`/myCollection`}>
              <Button
                title="Go to Collection"
                width="100%"
                height="65px"
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                }
              />
            </Link>
          </div>
        </div>
      )}
    </main>
  );
};

export default Upgrade;
