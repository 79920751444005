import { contract_WEAPONBASE, contract_ZODIBASE } from 'config';
import { ethers } from 'ethers';

import { useZodiumContract } from './useContract';
import { useWeb3 } from './useWeb3';

const useZodium = () => {
  const { account } = useWeb3();

  const zodiumContract = useZodiumContract();

  const getBalanceOf = async () => {
    try {
      if (!account) return '0';

      const token = await zodiumContract.balanceOf(account);
      return token.div(ethers.BigNumber.from(10).pow(18)).toString();
    } catch (error) {
      throw error;
    }
  };

  const tokenApprove = async () => {
    const MAX_VALUE =
      '115792089237316195423570985008687907853269984665640564039457584007913129639935';
    try {
      await zodiumContract.approve(contract_ZODIBASE.addr, MAX_VALUE);
    } catch (error) {
      throw error;
    }
  };

  const weaponTokenApprove = async () => {
    const MAX_VALUE =
      '115792089237316195423570985008687907853269984665640564039457584007913129639935';
    try {
      await zodiumContract.approve(contract_WEAPONBASE.addr, MAX_VALUE);
    } catch (error) {
      throw error;
    }
  };

  const getAllowance = async () => {
    try {
      if (!account) return '0';

      const allowanceAmount = await zodiumContract.allowance(
        account,
        contract_ZODIBASE.addr,
      );
      return allowanceAmount.div(ethers.BigNumber.from(10).pow(18)).toString();
    } catch (error) {
      throw error;
    }
  };

  const getWeaponAllowance = async () => {
    try {
      if (!account) return '0';

      const allowanceAmount = await zodiumContract.allowance(
        account,
        contract_WEAPONBASE.addr,
      );
      return allowanceAmount.div(ethers.BigNumber.from(10).pow(18)).toString();
    } catch (error) {
      throw error;
    }
  };

  return {
    getBalanceOf,
    tokenApprove,
    getAllowance,
    weaponTokenApprove,
    getWeaponAllowance,
  };
};

export default useZodium;
