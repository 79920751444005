import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from 'axios';

import Loading from '../../components/Loading';

import useZodiCollection from '../../hooks/useZodiCollection';
import useZodiac from '../../hooks/useZodiac';
import useZodium from '../../hooks/useZodium';

import { CardType } from '../../types/card';

import '../../scss/draw/Drawing.scss';

import { ReactComponent as Confirm } from '../../assets/icons/confirm.svg';
import { drawedTrue } from '../../store/drawed';
import { modalClose, modalOpen } from '../../store/modal';
import { concatMyPack } from '../../store/myPack';
import { setPack } from '../../store/newPack';

import AfterDraw from './AfterDraw';
import BeforeDraw from './BeforeDraw';

type DrawingProps = {
  token: string;
  drawed: boolean;
  payRate: number;
};

const Drawing: React.FC<DrawingProps> = ({ token, drawed, payRate }) => {
  const dispatch = useDispatch();
  const { drawzodiac, isLoading, drawTokenIDs } = useZodiac();
  const { tokenApprove, getAllowance } = useZodium();
  const { getBaseUriFromContract } = useZodiCollection();

  const videoRef = useRef<HTMLVideoElement>(null);

  const [packs, setPacks] = useState<number>(0);
  const [drawState, setDrawState] = useState<boolean>(false);
  const [processState, setProcessState] = useState<boolean>(false);

  let drawNewPacks: CardType[] = useMemo(() => [], []);

  //NOTE x Token mint
  const getDrawZodiac = async () => {
    try {
      await drawzodiac(packs * payRate);
    } catch (error) {
      dispatch(modalClose());
    }
  };

  // NOTE x getAllowance
  const verityAllowance = async () => {
    if (!drawed) {
      try {
        dispatch(
          modalOpen({
            alertImage: <Loading />,
            title: 'Waiting for Confirmation',
            description: `\nConfirm this transaction in your wallet\n\n\n`,
            dimZ: 1200,
          }),
        );

        let approvalAmount = await getAllowance();
        if (packs * payRate > Number(approvalAmount)) {
          await tokenApprove();
        }
        getDrawZodiac();
      } catch (error) {
        console.error(error);
        dispatch(modalClose());
      }
    }
  };

  // //NOTE x tokenURI 유효성 부여
  const getMetaData = async () => {
    try {
      let baseURI = await getBaseUriFromContract();
      let URIs: Array<string> = [];
      for (const id of drawTokenIDs) {
        const _url = `${baseURI}${id}`;
        URIs.push(_url);
      }
      showDrawZodi(URIs);
    } catch (error) {
      console.error(error);
    }
  };

  // //NOTE x JSON schema 데이터 가져와 보여주기
  const showDrawZodi = async (uriList: Array<string>) => {
    try {
      for (const uri of uriList) {
        let res = await axios.get(`${uri}`);
        if (
          res.data.attributes[2].value === 'Common' ||
          res.data.attributes[2].value === 'Rare'
        ) {
          drawNewPacks.push({
            id: res.data.id,
            tier: res.data.attributes[2].value,
            animal: res.data.name,
            starSign: res.data.attributes[0].value,
            image: res.data.image,
            checked: false,
          });
        } else {
          drawNewPacks.push({
            id: res.data.id,
            tier: res.data.attributes[2].value,
            animal: res.data.name,
            starSign: res.data.attributes[0].value,
            image: res.data.animation_url,
            checked: false,
          });
        }
      }

      dispatch(setPack(drawNewPacks));
      dispatch(concatMyPack(drawNewPacks));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      //NOTE x isLoading 값이 false가 되면 로딩창이 사라진다.
      dispatch(
        modalOpen({
          alertImage: <Confirm />,
          title: 'BSC Transaction Submitted',
          description: undefined,
          mainButtonTitle: 'OK',
          mainButtonEvent: () => {
            dispatch(modalClose());
            setDrawState(true);
          },
          dimZ: 1200,
        }),
      );
      getMetaData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (processState) {
      videoRef.current?.play(); // 영상 시작

      const drawEffect = setTimeout(() => {
        // 영상 끝난 후 언팩 결과
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch(drawedTrue());
      }, 5500);

      if (drawEffect && drawed) return clearTimeout(drawEffect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processState]);

  return (
    <section className="drawing">
      <video muted playsInline className="draw-effect" ref={videoRef}>
        <source
          src={`${process.env.PUBLIC_URL}/cardpack_open_effect.mp4`}
          type="video/mp4"
        />
      </video>

      {drawState ? (
        <AfterDraw
          processState={processState}
          setProcessState={setProcessState}
        />
      ) : (
        <BeforeDraw
          token={token}
          packs={packs}
          setPacks={setPacks}
          verityAllowance={verityAllowance}
          payRate={payRate}
        />
      )}
    </section>
  );
};

export default Drawing;
