import CardGallery from '../components/CardGallery';

import useCleanUpFlow from '../hooks/useCleanUpFlow';

import { animalArray } from '../lib/zodiac';

const Zodiac: React.FC = () => {
  useCleanUpFlow();

  return (
    <section className="zodiac-classes">
      <CardGallery animal={animalArray[0]} />
      <CardGallery animal={animalArray[1]} />
      <CardGallery animal={animalArray[2]} />
      <CardGallery animal={animalArray[3]} />
      <CardGallery animal={animalArray[4]} />
      <CardGallery animal={animalArray[5]} />
      <CardGallery animal={animalArray[6]} />
      <CardGallery animal={animalArray[7]} />
      <CardGallery animal={animalArray[8]} />
      <CardGallery animal={animalArray[9]} />
      <CardGallery animal={animalArray[10]} />
      <CardGallery animal={animalArray[11]} />
    </section>
  );
};

export default Zodiac;
