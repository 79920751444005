import ABI_WALLETBASE from 'abi/walletBase.json';
import ABI_WEAPON from 'abi/weapon/weapon.json';
import ABI_WEAPONBASE from 'abi/weapon/weaponBase.json';
import ABI_WEAPONCOLLECTION from 'abi/weapon/weaponCollection.json';
import ABI_WEAPONSETTING from 'abi/weapon/weaponSetting.json';
import ABI_ZODIUM12KINDS from 'abi/zodium/zodium12KindsV2.json';
import ABI_ZODIBASE from 'abi/zodium/zodiumBaseV2.json';
import ABI_ZODIINFOVIEWER from 'abi/zodium/zodiumInfoViewerV2.json';
import ABI_ZODIUM from 'abi/zodium/zodiumTokenV2.json';

import { Contract } from 'types/contract';

export enum Network {
  MAINNET, // chainId 56
  BETA, // chainId 56
  PRIVATE, // chainId 1336
}

const getContractEnv = () => {
  const contractEnv = process.env.REACT_APP_CONTRACT_ENV;
  switch (contractEnv) {
    case 'test':
      return Network.PRIVATE;
    case 'beta':
      return Network.BETA;
    default:
      return Network.MAINNET;
  }
};
const getChainId = () => {
  const network = getContractEnv();
  switch (network) {
    case Network.MAINNET:
      return 56;
    case Network.BETA:
      return 56;
    case Network.PRIVATE:
      return 1337;
  }
};
export const chainId = getChainId();
export const contractEnv = getContractEnv();

type ContractsKey =
  | 'zodium'
  | '12kinds'
  | 'infoViewer'
  | 'walletBase'
  | 'zodiBase'
  | 'zodiSetting'
  | 'weaponBase'
  | 'weaponSetting'
  | 'weapon'
  | 'weaponUpgrade'
  | '12weapons'
  | 'charis';

export const addressMap: Record<Network, Record<ContractsKey, string>> = {
  [Network.MAINNET]: {
    zodium: '0x0ccA2F5561Bb0fCa88e5b9B48b7Fbf000349c357',
    '12kinds': '0xf861f631CE45Aea5f60FeebB59b8A4a92fEe1383',
    infoViewer: '0xE4175b215ce09388E1B77d87AC8eE7A3e7394d1d',
    walletBase: '0x8EB80227418769B2AaDAFC2FF35a0650216B2777',
    zodiBase: '0x59444139ca9d9733188D1A3a657A7e718EBfa1ba',
    zodiSetting: '0xBF9b6F33F696951499934a8abe01d07502BD3BD5',
    weapon: '0xA84056eF76ab8A9f68195457C1908574c52b958C',
    weaponBase: '0x11bda869963cd99Bf3BFde5A62A5d5CB2409367E',
    weaponSetting: '0xB7f5199F76c7c8bdfA3CcaE1ada3829E34177A1A',
    weaponUpgrade: '0xdBA4b7f67CA655EB3c0CF2b96535Bdd745511B08',
    '12weapons': '0xF85c7BeF8BbB400A1457f27217B57046B85B0606',
    charis: '0xD438e4cE4b22e5BBb830FFDE87c2A600C69032c5',
  },
  [Network.BETA]: {
    zodium: '0xd513b5914c6b4aee50abe729c76bd9c6a1899b5d',
    '12kinds': '',
    infoViewer: '',
    walletBase: '',
    zodiBase: '',
    zodiSetting: '',
    weapon: '0x5201a2c02780a0b5C1CFF45Ac1CF70d0f5733f0c',
    weaponBase: '0x200170968b811c0783918672421586225B34FFB3',
    weaponSetting: '0x71bB25d5826744C309045150B701966d9a0987f0',
    weaponUpgrade: '0x8592Bc9a3959BBDCA7b2DC858c42cE53fd72eC62',
    '12weapons': '0x31A40978AeA74737B02F007cD414341d02A9d22D',
    charis: '0x924B7852D146Fd9D0AB2D4c8E08855930EC52CE7',
  },
  [Network.PRIVATE]: {
    zodium: '0xb363E19A5EF94BC2b86f57C62FBd60cEe86B62dc',
    '12kinds': '',
    infoViewer: '',
    walletBase: '',
    zodiBase: '',
    zodiSetting: '',
    weapon: '0x842B635Ee29ae9e0d84c824c45d5eF989Cc25e81',
    weaponBase: '0xaA6fF95155e51FBd0d853c15B8dC70A175D5e252',
    weaponSetting: '0x32CFF93f4Df0bdc770706743fcd8718A7699CdF6',
    weaponUpgrade: '0x5F0e60c788E139795e8FC0F3CC8fEA120aFc82c9',
    '12weapons': '0x4d11e061D88430bc11769B237EbE260777258808',
    charis: '0xe57E3a8a845EB657653c8eE67f2b9C18C72701f7',
  },
};

export const contract_ZODIUM: Contract = {
  addr: addressMap[contractEnv].zodium,
  abi: ABI_ZODIUM,
};

export const contract_ZODIBASE: Contract = {
  addr: addressMap[contractEnv].zodiBase,
  abi: ABI_ZODIBASE,
};

export const contract_WALLETBASE: Contract = {
  addr: addressMap[contractEnv].walletBase,
  abi: ABI_WALLETBASE,
};

export const contract_ZODIUM12KINDS: Contract = {
  addr: addressMap[contractEnv]['12kinds'],
  abi: ABI_ZODIUM12KINDS,
};

export const contract_ZODIINFOVIEWER: Contract = {
  addr: addressMap[contractEnv].infoViewer,
  abi: ABI_ZODIINFOVIEWER,
};

export const contract_WEAPONBASE: Contract = {
  addr: addressMap[contractEnv].weaponBase,
  abi: ABI_WEAPONBASE,
};

export const contract_12WEAPONS: Contract = {
  addr: addressMap[contractEnv]['12weapons'],
  abi: ABI_WEAPONCOLLECTION,
};

export const contract_WEAPONSETTING: Contract = {
  addr: addressMap[contractEnv].weaponSetting,
  abi: ABI_WEAPONSETTING,
};

export const contract_WEAPON: Contract = {
  addr: addressMap[contractEnv].weapon,
  abi: ABI_WEAPON,
};

export const ZODIUM = addressMap[contractEnv].zodium;

export const CHARIS = addressMap[contractEnv].charis;

export const WEAPON_BASE = addressMap[contractEnv].weaponBase;

export const WEAPON_COLLECTION = addressMap[contractEnv]['12weapons'];
