import { useState } from 'react';

import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';

import Button from 'components/Button';

import { WariningStateType } from 'types/warning';

import 'scss/draw/BeforeWeaponDraw.scss';

let REMAININGDRAWCOUNT: number = 10;

type BeforeDrawProps = {
  token: string;
  packs: number;
  setPacks: React.Dispatch<React.SetStateAction<number>>;
  weaponVerityAllowance: () => Promise<void>;
  weaponPayRate: number;
  isDrawable: boolean;
};

const BeforeDraw: React.FC<BeforeDrawProps> = ({
  token,
  packs,
  setPacks,
  weaponVerityAllowance,
  weaponPayRate,
  isDrawable,
}) => {
  const [warningState, setWarningState] = useState<WariningStateType>({
    warning: false,
    description: '',
  });

  const packPlus = () => {
    if (packs < 10) setPacks(packs + 1);
  };

  const packMinus = () => {
    if (packs > 0) setPacks(packs - 1);
  };

  const drawWeapon = async () => {
    if (packs === 0) {
      setWarningState({
        ...warningState,
        warning: true,
        description: 'Please choose the number of packs to draw.',
      });
    } else if (
      // 사용자가 갖고 있는 토큰 보다 필요한 토큰 수(팩 수 * 팩당 토큰 수)가 많은 경우 || 남은 뽑기 수 보다 뽑으려는 팩 수가 많은 경우
      packs * weaponPayRate > parseInt(token) ||
      packs > REMAININGDRAWCOUNT
    ) {
      setWarningState({
        ...warningState,
        warning: true,
        description: 'Please check the token you have',
      });
    } else if (weaponPayRate === 0) {
      setWarningState({
        ...warningState,
        warning: true,
        description: 'Please check the payrate.',
      });
    } else {
      setWarningState({ ...warningState, warning: false });
      await weaponVerityAllowance();
    }
  };

  return (
    <div className="before-draw">
      <p>Number of Weapons Drawing</p>
      <div>
        <Minus onClick={packMinus} />
        {packs}
        <Plus onClick={packPlus} />
      </div>

      <div className="button-wrapper">
        <Button
          title="Buy ZODI"
          buttonStyle="orange-border"
          onClick={() => window.open('https://pancakeswap.finance/swap')}
        />
        <div>
          <Button
            title="Draw Weapon"
            onClick={drawWeapon}
            isDisabled={!isDrawable}
          />
          {warningState.warning && (
            <p className="warning">
              <Warning />
              {warningState.description}
              <br className="mobile"></br>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BeforeDraw;
