// increment, bookCnt, required_date 필드는 사용안함

export const expMap = [
  {
    level: 0,
    required_exp: 0,
    increment: 0,
    stacked: 0,
    bookCnt: 0,
    required_date: 0,
  },
  {
    level: 1,
    required_exp: 0,
    increment: 0,
    stacked: 0,
    bookCnt: 0,
    required_date: 0,
  },
  {
    level: 2,
    required_exp: 40,
    increment: 0,
    stacked: 40,
    bookCnt: 2,
    required_date: 1,
  },
  {
    level: 3,
    required_exp: 60,
    increment: 20,
    stacked: 100,
    bookCnt: 4,
    required_date: 1,
  },
  {
    level: 4,
    required_exp: 80,
    increment: 20,
    stacked: 180,
    bookCnt: 6,
    required_date: 1,
  },
  {
    level: 5,
    required_exp: 100,
    increment: 20,
    stacked: 280,
    bookCnt: 10,
    required_date: 2,
  },
  {
    level: 6,
    required_exp: 120,
    increment: 20,
    stacked: 400,
    bookCnt: 14,
    required_date: 2,
  },
  {
    level: 7,
    required_exp: 150,
    increment: 30,
    stacked: 550,
    bookCnt: 19,
    required_date: 3,
  },
  {
    level: 8,
    required_exp: 180,
    increment: 30,
    stacked: 730,
    bookCnt: 25,
    required_date: 4,
  },
  {
    level: 9,
    required_exp: 210,
    increment: 30,
    stacked: 940,
    bookCnt: 32,
    required_date: 4,
  },
  {
    level: 10,
    required_exp: 240,
    increment: 30,
    stacked: 1180,
    bookCnt: 40,
    required_date: 5,
  },
  {
    level: 11,
    required_exp: 270,
    increment: 30,
    stacked: 1450,
    bookCnt: 49,
    required_date: 7,
  },
  {
    level: 12,
    required_exp: 300,
    increment: 30,
    stacked: 1750,
    bookCnt: 59,
    required_date: 8,
  },
  {
    level: 13,
    required_exp: 330,
    increment: 30,
    stacked: 2080,
    bookCnt: 70,
    required_date: 9,
  },
  {
    level: 14,
    required_exp: 360,
    increment: 30,
    stacked: 2440,
    bookCnt: 82,
    required_date: 11,
  },
  {
    level: 15,
    required_exp: 390,
    increment: 30,
    stacked: 2830,
    bookCnt: 95,
    required_date: 12,
  },
  {
    level: 16,
    required_exp: 420,
    increment: 30,
    stacked: 3250,
    bookCnt: 109,
    required_date: 14,
  },
  {
    level: 17,
    required_exp: 460,
    increment: 40,
    stacked: 3710,
    bookCnt: 124,
    required_date: 16,
  },
  {
    level: 18,
    required_exp: 500,
    increment: 40,
    stacked: 4210,
    bookCnt: 141,
    required_date: 18,
  },
  {
    level: 19,
    required_exp: 540,
    increment: 40,
    stacked: 4750,
    bookCnt: 159,
    required_date: 20,
  },
  {
    level: 20,
    required_exp: 590,
    increment: 50,
    stacked: 5340,
    bookCnt: 178,
    required_date: 23,
  },
  {
    level: 21,
    required_exp: 650,
    increment: 60,
    stacked: 5990,
    bookCnt: 200,
    required_date: 25,
  },
  {
    level: 22,
    required_exp: 710,
    increment: 60,
    stacked: 6700,
    bookCnt: 224,
    required_date: 28,
  },
  {
    level: 23,
    required_exp: 790,
    increment: 80,
    stacked: 7490,
    bookCnt: 250,
    required_date: 32,
  },
  {
    level: 24,
    required_exp: 870,
    increment: 80,
    stacked: 8360,
    bookCnt: 279,
    required_date: 35,
  },
  {
    level: 25,
    required_exp: 960,
    increment: 90,
    stacked: 9320,
    bookCnt: 311,
    required_date: 39,
  },
  {
    level: 26,
    required_exp: 1060,
    increment: 100,
    stacked: 10380,
    bookCnt: 346,
    required_date: 44,
  },
  {
    level: 27,
    required_exp: 1160,
    increment: 100,
    stacked: 11540,
    bookCnt: 385,
    required_date: 49,
  },
  {
    level: 28,
    required_exp: 1280,
    increment: 120,
    stacked: 12820,
    bookCnt: 428,
    required_date: 54,
  },
  {
    level: 29,
    required_exp: 1410,
    increment: 130,
    stacked: 14230,
    bookCnt: 475,
    required_date: 60,
  },
  {
    level: 30,
    required_exp: 1560,
    increment: 150,
    stacked: 15790,
    bookCnt: 527,
    required_date: 66,
  },
  {
    level: 31,
    required_exp: 1730,
    increment: 170,
    stacked: 17520,
    bookCnt: 584,
    required_date: 73,
  },
  {
    level: 32,
    required_exp: 1910,
    increment: 180,
    stacked: 19430,
    bookCnt: 648,
    required_date: 81,
  },
  {
    level: 33,
    required_exp: 2100,
    increment: 190,
    stacked: 21530,
    bookCnt: 718,
    required_date: 90,
  },
  {
    level: 34,
    required_exp: 2310,
    increment: 210,
    stacked: 23840,
    bookCnt: 795,
    required_date: 100,
  },
  {
    level: 35,
    required_exp: 2540,
    increment: 230,
    stacked: 26380,
    bookCnt: 880,
    required_date: 110,
  },
  {
    level: 36,
    required_exp: 2780,
    increment: 240,
    stacked: 29160,
    bookCnt: 972,
    required_date: 122,
  },
  {
    level: 37,
    required_exp: 3040,
    increment: 260,
    stacked: 32200,
    bookCnt: 1074,
    required_date: 135,
  },
  {
    level: 38,
    required_exp: 3320,
    increment: 280,
    stacked: 35520,
    bookCnt: 1184,
    required_date: 148,
  },
  {
    level: 39,
    required_exp: 3610,
    increment: 290,
    stacked: 39130,
    bookCnt: 1305,
    required_date: 164,
  },
  {
    level: 40,
    required_exp: 3920,
    increment: 310,
    stacked: 43050,
    bookCnt: 1435,
    required_date: 180,
  },
  {
    level: 41,
    required_exp: 4250,
    increment: 330,
    stacked: 47300,
    bookCnt: 1577,
    required_date: 198,
  },
  {
    level: 42,
    required_exp: 4600,
    increment: 350,
    stacked: 51900,
    bookCnt: 1730,
    required_date: 217,
  },
  {
    level: 43,
    required_exp: 4970,
    increment: 370,
    stacked: 56870,
    bookCnt: 1896,
    required_date: 237,
  },
  {
    level: 44,
    required_exp: 5360,
    increment: 390,
    stacked: 62230,
    bookCnt: 2075,
    required_date: 260,
  },
  {
    level: 45,
    required_exp: 5770,
    increment: 410,
    stacked: 68000,
    bookCnt: 2267,
    required_date: 284,
  },
  {
    level: 46,
    required_exp: 6200,
    increment: 430,
    stacked: 74200,
    bookCnt: 2474,
    required_date: 310,
  },
  {
    level: 47,
    required_exp: 6650,
    increment: 450,
    stacked: 80850,
    bookCnt: 2695,
    required_date: 337,
  },
  {
    level: 48,
    required_exp: 7120,
    increment: 470,
    stacked: 87970,
    bookCnt: 2933,
    required_date: 367,
  },
  {
    level: 49,
    required_exp: 7610,
    increment: 490,
    stacked: 95580,
    bookCnt: 3186,
    required_date: 399,
  },
  {
    level: 50,
    required_exp: 8110,
    increment: 500,
    stacked: 103690,
    bookCnt: 3457,
    required_date: 433,
  },
  {
    level: 50,
    required_exp: 8110,
    increment: 500,
    stacked: 103690,
    bookCnt: 3457,
    required_date: 433,
  },
];
