import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { expMap } from 'constants/weaponExp';
import { powerMap } from 'constants/weaponPower';
import { RootState } from 'store';

import useDerivedWeaponInfo from 'hooks/useDerivedWeaponInfo';

import WeaponDetailCard from './WeaponDetailCard';

const LevelUpDetailInfo = () => {
  const { targetWeapon, level, predictedExp, tierMaxLevel, tierMaxExp } =
    useSelector((state: RootState) => state.levelupReducer);

  const { stackedExp, power } = useDerivedWeaponInfo(targetWeapon ?? undefined);

  const predictNextLevel = useMemo(() => {
    const foundLevel = expMap.findIndex((e) => e.stacked > predictedExp) - 1;
    if (foundLevel === -2) return tierMaxLevel; // -2 means there is no higher item in expMap. so return tierMaxLevel
    return foundLevel < tierMaxLevel ? foundLevel : tierMaxLevel;
  }, [predictedExp, tierMaxLevel]);

  const nextPower = useMemo(
    () => powerMap[predictNextLevel],
    [predictNextLevel],
  );

  return (
    <WeaponDetailCard
      curLevel={level}
      curMaxLevel={tierMaxLevel}
      nextLevel={predictNextLevel}
      nextMaxLevel={tierMaxLevel}
      curExp={stackedExp ?? 0}
      curMaxExp={tierMaxExp}
      nextExp={predictedExp > tierMaxExp ? tierMaxExp : predictedExp}
      nextMaxExp={tierMaxExp}
      power={power ?? 0}
      nextPower={nextPower}
    />
  );
};

export default LevelUpDetailInfo;
