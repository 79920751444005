import { useCallback, useEffect, useState } from 'react';

import { useWeaponBaseContract } from 'hooks/useContract';

import { useWeb3 } from './useWeb3';

const useWeaponCustomize = () => {
  const { account } = useWeb3();

  const weaponBase = useWeaponBaseContract();
  const [tokenIds, setTokenIds] = useState<string[]>([]);

  const getWeaponTokenIDs = useCallback(async () => {
    try {
      const IDs: Array<string> = [];
      if (!account) return IDs;

      const res = await weaponBase.getTokenURIListForAll(account);
      for (const id of res) {
        IDs.push(id.toString());
      }
      setTokenIds(IDs);
      return IDs;
    } catch (error) {
      throw error;
    }
  }, [weaponBase, account]);

  useEffect(() => {
    getWeaponTokenIDs();
  }, [getWeaponTokenIDs]);

  return { tokenIds, getWeaponTokenIDs };
};

export { useWeaponCustomize };
