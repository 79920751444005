import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useTokenBalanceQuery from 'api/useTokenBalanceQuery';
import useZodiBalanceQuery from 'api/useZodiBalanceQuery';
import CharisIcon from 'assets/images/CHARIS.png';
import ZodiIcon from 'assets/images/ZODI.png';
import { CHARIS, WEAPON_BASE, WEAPON_COLLECTION } from 'config';
import { BigNumber } from 'ethers';
import { RootState } from 'store';

import useApproveForAllNFT from 'hooks/useApproveForAllNFT';
import { useApproveToken, useApproveZodi } from 'hooks/useApproveToken';
import useWeaponUpgradeCallback from 'hooks/useWeaponUpgradeCallback';
import { useWeb3 } from 'hooks/useWeb3';

import { ApprovalState } from 'types/contract';

import classes from './UsedTokenForWeaponUpgrade.module.scss';

// TODO get charis amount from contract
// current tier -> next tier needed charis token amount
const charisAmountMap = [0, 10000, 25000, 60000, 100000];

const UsedTokenForWeaponUpgrade = () => {
  const { account } = useWeb3();
  const { canUpgradeNow, target, weaponsForUpgrade } = useSelector(
    (state: RootState) => ({
      canUpgradeNow: state.weaponUpgradeReducer.canUpgradeNow,
      target: state.weaponUpgradeReducer.targetWeapon,
      weaponsForUpgrade: state.weaponUpgradeReducer.weaponsForUpgrade,
    }),
  );

  const charisAmount = useMemo(() => {
    if (!target) return null;
    const rarity = target.attributes.rarity;
    if (charisAmountMap[rarity]) {
      return charisAmountMap[rarity];
    }
    return null;
  }, [target]);

  // balance
  const { balance: zodiBalance = '0' } = useZodiBalanceQuery();
  const { balance: charisBalance = BigNumber.from(0) } = useTokenBalanceQuery(
    CHARIS,
    account ?? undefined,
  );

  // approve token
  const [zodiApproval, approveZodi] = useApproveZodi(
    WEAPON_BASE,
    BigNumber.from(100),
  );
  const [charisApproval, approveCharis] = useApproveToken(
    CHARIS,
    WEAPON_BASE,
    BigNumber.from(charisAmount),
  );

  // approve nfts
  const [nftsApproval, approveNFTs] = useApproveForAllNFT(
    WEAPON_COLLECTION,
    WEAPON_BASE,
  );

  const weaponUpgrade = useWeaponUpgradeCallback();

  const isActive =
    canUpgradeNow &&
    Number(zodiBalance) >= 100 &&
    charisBalance.gte(BigNumber.from(charisAmount));

  const handleBtnClick = async () => {
    if (!isActive) return;

    try {
      if (zodiApproval === ApprovalState.NOT_APPROVED) {
        const receipt = await approveZodi();
        console.log('receipt', receipt);
        return;
      }
      if (charisApproval === ApprovalState.NOT_APPROVED) {
        const receipt = await approveCharis();
        console.log('receipt', receipt);
        return;
      }
      if (nftsApproval === ApprovalState.NOT_APPROVED) {
        const receipt = await approveNFTs();
        console.log('receipt', receipt);
        return;
      }

      const receipt = await weaponUpgrade(weaponsForUpgrade.map((w) => w.id));
      console.log('receipt', receipt);
    } catch (e) {
      console.error(e);
    }
  };

  const btnText = () => {
    if (zodiApproval === ApprovalState.NOT_APPROVED) {
      return 'Approve ZODI';
    }
    if (charisApproval === ApprovalState.NOT_APPROVED) {
      return 'Approve CHARIS';
    }
    if (nftsApproval === ApprovalState.NOT_APPROVED) {
      return 'Approve NFTs';
    }
    return 'Upgrade';
  };

  return (
    <div className={classes.footer}>
      <div className={classes.usedTokenBox}>
        <div className={classes.usedZodi}>
          <img className={classes.tokenIcon} src={ZodiIcon} alt="zodi" />
          <span>100</span> ZODI
        </div>
        <div className={classes.dot}></div>
        <div className={classes.usedCharis}>
          <img className={classes.tokenIcon} src={CharisIcon} alt="charis" />
          <span>{charisAmount}</span> CHARIS
        </div>
      </div>
      <div
        className={classes[isActive ? 'btnPC' : 'btnPC-grey']}
        onClick={handleBtnClick}
      >
        {btnText()}
      </div>
      <div
        className={classes[isActive ? 'btnMobile' : 'btnMobile-grey']}
        onClick={handleBtnClick}
      >
        {btnText()}
      </div>
    </div>
  );
};

export default UsedTokenForWeaponUpgrade;
