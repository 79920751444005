import '../scss/Dim.scss';

type DimProps = {
  zIndex: number;
  dimClose?: () => void;
};

const Dim: React.FC<DimProps> = ({ zIndex, dimClose }) => {
  return <div className="dim" style={{ zIndex: zIndex }} onClick={dimClose} />;
};

export default Dim;
