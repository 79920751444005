import { useCallback } from 'react';

import { useWeaponClassificationType } from '../../hooks/useWeaponClassification';

import { weaponElementList, weaponNameList } from '../../lib/weapon';

import './WeaponCollectionNav.scss';

import ClassificationRarity from '../ClassificationRarity';
import CollectionSelectBox from '../CollectionSelectBox';

export default function WeaponCollectionNav({
  classification,
  forModal,
}: {
  classification: useWeaponClassificationType;
  forModal?: boolean;
}) {
  const {
    setSelectedWeapon,
    setSelectedElement,
    setWeaponSelectActive,
    setElementSelectActive,
  } = classification;

  const handleWeaponSelect = useCallback(
    (selected: string) => {
      setSelectedWeapon(selected);
      setWeaponSelectActive(false);
    },
    [setSelectedWeapon, setWeaponSelectActive],
  );

  const handleElementSelect = useCallback(
    (selected: string) => {
      setSelectedElement(selected);
      setElementSelectActive(false);
    },
    [setSelectedElement, setElementSelectActive],
  );

  const handleWeaponBoxClick = useCallback(() => {
    setWeaponSelectActive((prev) => !prev);
    setElementSelectActive(false);
  }, [setWeaponSelectActive, setElementSelectActive]);

  const handleElementBoxClick = useCallback(() => {
    setElementSelectActive((prev) => !prev);
    setWeaponSelectActive(false);
  }, [setElementSelectActive, setWeaponSelectActive]);

  return (
    <section
      className={forModal ? 'classification-nav-modal' : 'classification-nav'}
      id="classification-nav"
    >
      <ClassificationRarity
        classificationRarity={classification.weaponClassficationRarity}
        selectRarity={classification.selectRarity}
        forModal={forModal}
      />
      <div
        className={
          forModal
            ? 'classification-class-wrapper-modal'
            : 'classification-class-wrapper'
        }
      >
        <CollectionSelectBox
          title="Weapons"
          options={weaponNameList}
          active={classification.weaponSelectActive}
          onOptionSelected={handleWeaponSelect}
          onBoxClick={handleWeaponBoxClick}
        />
        <CollectionSelectBox
          title="Elements"
          options={weaponElementList}
          active={classification.elementSelectActive}
          onOptionSelected={handleElementSelect}
          onBoxClick={handleElementBoxClick}
        />
      </div>
    </section>
  );
}
