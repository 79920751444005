import { createAction, handleActions } from 'redux-actions';

const OPEN = 'modalUpgrade/OPEN' as const;
const CLOSE = 'modalUpgrade/CLOSE' as const;

export const modalUpgradeOpen = createAction(OPEN);
export const modalUpgradeClose = createAction(CLOSE);

export type ModalUpgradeAction =
  | ReturnType<typeof modalUpgradeOpen>
  | ReturnType<typeof modalUpgradeClose>;

export type ModalUpgradeState = {
  open: boolean;
};

const initialState: ModalUpgradeState = {
  open: false,
};

const modal = handleActions(
  {
    [OPEN]: (state: ModalUpgradeState) => ({ ...state, open: true }),
    [CLOSE]: (state: ModalUpgradeState) => ({ ...state, open: false }),
  },
  initialState,
);

export default modal;
