import '../scss/Token.scss';

import { ReactComponent as BuyButton } from '../assets/icons/buy-btn.svg';

export default function Token({
  name,
  amount,
  buyUrl,
  tokenImg,
  style,
}: {
  name: string;
  amount: string | number;
  buyUrl: string;
  tokenImg: string;
  style: React.CSSProperties;
}) {
  return (
    <div className="token-container" style={style}>
      <div className="token-info">
        <div className="info__name">{name}</div>
        <div className="info__amount">{amount}</div>
        <div className="info__USD">USD</div>
        <BuyButton onClick={() => window.open(buyUrl)} />
      </div>
      <div>
        <img src={tokenImg} alt={name} />
      </div>
    </div>
  );
}
