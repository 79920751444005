import { useState } from 'react';

import { WeaponType } from '../../types/weapon';

import classes from './WeaponProperties.module.scss';

import arrowDown from '../../assets/icons/arrow_down.png';
import arrowUp from '../../assets/icons/arrow_up.png';

function get12KINDsFromWeapon(weaponName: string): string {
  if (weaponName === 'Bone') {
    return 'Dog';
  }
  if (weaponName === 'Fire Ball') {
    return 'Dragon';
  }
  if (weaponName === 'Eggs Bucket') {
    return 'Hen';
  }
  if (weaponName === 'Hoof') {
    return 'Horse';
  }
  if (weaponName === 'Magic Stick') {
    return 'Monkey';
  }
  if (weaponName === 'Horn') {
    return 'Ox';
  }
  if (weaponName === 'Pearl Necklace') {
    return 'Pig';
  }
  if (weaponName === 'Carrot') {
    return 'Rabbit';
  }
  if (weaponName === 'Ninja Star') {
    return 'Rat';
  }
  if (weaponName === 'Shield') {
    return 'Sheep';
  }
  if (weaponName === 'Flute') {
    return 'Snake';
  }
  if (weaponName === 'Knuckles') {
    return 'Tiger';
  }

  return '';
}

export default function WeaponProperties({
  weapon,
  forModal = false,
}: {
  weapon: WeaponType;
  forModal?: boolean;
}) {
  const [isOpen, setOpen] = useState(false);

  const { rarity, name, element, level, stackedExp, power } = weapon.attributes;

  let properties: any = {
    '12KINDs': get12KINDsFromWeapon(name),
    Element: element,
    Rarity: rarity,
    Level: level,
    EXP: stackedExp,
    Power: power,
  };

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      <div
        className={
          forModal ? classes['properties-modal'] : classes['properties']
        }
        onClick={handleClick}
      >
        <span className="name">{name}</span>
        {isOpen ? (
          <img className="arrow-up" src={arrowUp} alt="" />
        ) : (
          <img className="arrow-down" src={arrowDown} alt="" />
        )}
      </div>
      {isOpen && (
        <div
          className={
            classes[forModal ? 'propertiesBox-modal' : 'propertiesBox']
          }
        >
          <div className={classes.desc}>Properties</div>
          <div className={classes.props}>
            {Object.keys(properties)
              .filter((k) => k !== 'id')
              .map((k) => (
                <div className={classes.prop} key={k}>
                  <div className={classes.prop__key}>{k}</div>
                  <div className={classes.prop__val}>{properties[k]}</div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}
