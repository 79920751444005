import { useCallback, useMemo } from 'react';

import TearIcon from 'assets/images/weapon/Star-tear-icon.png';
import { expMap } from 'constants/weaponExp';
import { powerMap } from 'constants/weaponPower';

import { WeaponType } from 'types/weapon';

const useDerivedWeaponInfo = (weapon?: WeaponType, nextTier?: boolean) => {
  const derivedInfo = useMemo(() => {
    if (!weapon) return null;
    const { rarity, name, element, stackedExp, power, level } =
      weapon.attributes;
    const rarityToShow = nextTier ? rarity + 1 : rarity;
    let levelToShow = nextTier ? rarity * 10 + 1 : level;
    levelToShow = levelToShow < 50 ? levelToShow : 50; // max level is 50

    const nextRequiredExp = expMap[levelToShow + 1].required_exp;
    const nextStackedExp = expMap[levelToShow + 1].stacked;
    let tierMaxLevel = rarityToShow * 10;
    const tierMaxExp = expMap[tierMaxLevel + 1].stacked;

    return {
      rarity,
      name,
      element,
      stackedExp,
      power: nextTier ? powerMap[levelToShow] : power,
      level: nextTier ? levelToShow : level,
      nextRequiredExp,
      nextStackedExp,
      tierMaxLevel,
      tierMaxExp,
      rarityToShow,
    };
  }, [weapon, nextTier]);

  const stars = useCallback(() => {
    return Array.from(Array(derivedInfo?.rarityToShow), (_, i) => (
      <img key={i} src={TearIcon} alt="star"></img>
    ));
  }, [derivedInfo?.rarityToShow]);

  return { ...derivedInfo, stars };
};

export default useDerivedWeaponInfo;
