import { monthArray, monthArrayMobile } from '../lib/date';

import { starSignArray } from './zodiac';

const animal: string[] = [
  'Monkey',
  'Hen',
  'Dog',
  'Pig',
  'Rat',
  'Ox',
  'Tiger',
  'Rabbit',
  'Dragon',
  'Snake',
  'Horse',
  'Sheep',
];

export const checkAnimal = (year: string): string => {
  const YEAR = parseInt(year);

  return animal[YEAR % 12];
};

export const checkStarSign = (month: string, day: string) => {
  const MONTH =
    month.length === 2
      ? parseInt(month) - 1
      : month.length === 3
      ? monthArrayMobile.indexOf(month)
      : monthArray.indexOf(month);
  const DAY = parseInt(day);

  if (MONTH === 2) {
    if (DAY < 21) return starSignArray[11];
    else return starSignArray[0];
  } else if (MONTH === 3) {
    if (DAY < 20) return starSignArray[0];
    else return starSignArray[1];
  } else if (MONTH === 4) {
    if (DAY < 21) return starSignArray[1];
    else return starSignArray[2];
  } else if (MONTH === 5) {
    if (DAY < 22) return starSignArray[2];
    else return starSignArray[3];
  } else if (MONTH === 6) {
    if (DAY < 23) return starSignArray[3];
    else return starSignArray[4];
  } else if (MONTH === 7) {
    if (DAY < 23) return starSignArray[4];
    else return starSignArray[5];
  } else if (MONTH === 8) {
    if (DAY < 24) return starSignArray[5];
    else return starSignArray[6];
  } else if (MONTH === 9) {
    if (DAY < 23) return starSignArray[6];
    else return starSignArray[7];
  } else if (MONTH === 10) {
    if (DAY < 23) return starSignArray[7];
    else return starSignArray[8];
  } else if (MONTH === 11) {
    if (DAY < 25) return starSignArray[8];
    else return starSignArray[9];
  } else if (MONTH === 0) {
    if (DAY < 20) return starSignArray[9];
    else return starSignArray[10];
  } else if (MONTH === 1) {
    if (DAY < 19) return starSignArray[10];
    else return starSignArray[11];
  }
};
