import { useCallback } from 'react';

import { useWeaponContract } from './useContract';

const useWeaponLimit = () => {
  const weaponContract = useWeaponContract();

  const getWeaponLimit = useCallback(async () => {
    try {
      const limits = await weaponContract.getWeaponLimitList();
      return limits;
    } catch (err) {
      console.log(err);
    }
  }, [weaponContract]);

  const getWeaponLimitElement = useCallback(async () => {
    try {
      const element = await weaponContract.getWeaponElementList();
      return element;
    } catch (err) {
      console.log(err);
    }
  }, [weaponContract]);

  const getWeaponNames = useCallback(async () => {
    try {
      const nameList = await weaponContract.getWeaponNameList();
      return nameList;
    } catch (err) {
      console.log(err);
    }
  }, [weaponContract]);

  return { getWeaponLimit, getWeaponLimitElement, getWeaponNames };
};

export { useWeaponLimit };
