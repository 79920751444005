import { useSelector } from 'react-redux';

import { RootState } from 'store';

import WeaponCard from 'components/Weapon/WeaponCard';
import WeaponProperties from 'components/Weapon/WeaponProperties';

import styles from './WeaponDrawResult.module.scss';

const WeaponDrawResult: React.FC = () => {
  const newWeapon = useSelector((state: RootState) => state.newWeapon);
  console.log(newWeapon);
  return (
    <>
      <section className={styles['draw-result']}>
        <h2>Weapon #{newWeapon.length}</h2>

        <div className={styles['card-pack']}>
          {newWeapon.map((card, index) => (
            <div key={index} className={styles['weapon']}>
              <div>
                <WeaponCard weapon={card} />
              </div>
              <WeaponProperties weapon={card} />
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default WeaponDrawResult;
