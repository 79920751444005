import { Link } from 'react-router-dom';

import Button from '../components/Button';

import useCleanUpFlow from '../hooks/useCleanUpFlow';

import '../scss/FindZodi.scss';

const FindZodi: React.FC = () => {
  useCleanUpFlow();

  return (
    <main className="find-zodi">
      <div className="web-wrapper">
        <h1>What are Zodiac Signs?</h1>
        <p>
          Are you a tiger with a Scorpio horoscope? Is your friend a dragon with
          a Leo horoscope?
          <br />
          Zodiac Signs are created based on the ancient beliefs of animal and
          star signs by birth.
          <br />
          Each astrological and animal sign has its inherent traits and
          qualities assigned.
          <br />
          By simply letting us know your date of birth, you can discover your
          zodiac signs and see what your inherent blessings are.
        </p>

        <h2>Animal Zodiac Signs and Their Meanings</h2>
        <p>
          The animal zodiac sign is a term that symbolizes the inherent blessing
          of the year a person was born in 12 kinds of animalized creatures.
          <br />
          Accordingly, each zodiac animal year comes around every 12 years, and
          each year is associated with specific zodiac animals.
          <br />
          What’s meaningful about the animal zodiac sign is that each animal has
          its unique personality and attribute.
          <br />
          <br />
          This ancient belief has served as a fundamental framework in numerous
          aspects of old Asian lives.
          <br />
          <br />
          You can find your zodiac animal below:
        </p>
      </div>

      <div className="pad-wrapper">
        <h1>What are Zodiac Signs?</h1>
        <p>
          Are you a tiger with a Scorpio horoscope?
          <br />
          Is your friend a dragon with a Leo horoscope?
          <br />
          <br />
          Zodiac Signs are created based on
          <br />
          the ancient beliefs of animal and star signs by birth.
          <br />
          <br />
          Each astrological and animal sign has its inherent traits and
          qualities assigned.
          <br />
          <br />
          By simply letting us know your date of birth,
          <br />
          you can discover your zodiac signs and see what your inherent
          blessings are.
        </p>

        <h2>Animal Zodiac Signs and Their Meanings</h2>
        <p>
          The animal zodiac sign is a term that symbolizes
          <br />
          the inherent blessing of the year a person was born
          <br />
          in 12 kinds of animalized creatures.
          <br />
          <br />
          Accordingly, each zodiac animal year comes around
          <br />
          every 12 years, and each year is associated with
          <br />
          specific zodiac animals.
          <br />
          <br />
          What’s meaningful about the animal zodiac sign is that
          <br />
          each animal has its unique personality and attribute.
          <br />
          <br />
          This ancient belief has served as a fundamental
          <br />
          framework in numerous aspects of old Asian lives.
          <br />
          <br />
          You can find your zodiac animal below:
        </p>
      </div>

      <div className="mobile-wrapper">
        <h1>What are Zodiac Signs?</h1>
        <p>
          Are you a tiger with a Scorpio horoscope?
          <br />
          Is your friend a dragon
          <br />
          with a Leo horoscope?
          <br />
          <br />
          Zodiac Signs are created based on
          <br />
          the ancient beliefs of animal
          <br />
          and star signs by birth.
          <br />
          <br />
          Each astrological and animal sign has
          <br />
          its inherent traits and qualities assigned.
          <br />
          <br />
          By simply letting us know your date of birth,
          <br />
          you can discover your zodiac signs
          <br />
          and see what your inherent blessings are.
        </p>

        <h2>
          Animal Zodiac Signs
          <br />
          and Their Meanings
        </h2>
        <p>
          The animal zodiac sign is a term
          <br />
          that symbolizes the inherent blessing
          <br />
          of the year a person was born in 12 kinds
          <br />
          of animalized creatures.
          <br />
          <br />
          Accordingly, each zodiac animal year comes
          <br />
          around every 12 years, and each year
          <br />
          is associated with specific zodiac animals.
          <br />
          <br />
          What’s meaningful about
          <br />
          the animal zodiac sign is that
          <br />
          each animal has its unique personality
          <br />
          and attribute.
          <br />
          <br />
          This ancient belief has served
          <br />
          as a fundamental framework
          <br />
          in numerous aspects of old Asian lives.
          <br />
          <br />
          You can find your zodiac animal below:
        </p>
      </div>

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Zodiac Animal</th>
              <th>Recent Years (birth years)</th>
              <th>Personality Traits</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Rat</td>
              <td>1924, 1936, 1960, 1972, 1984, 1996, 2008, 2020</td>
              <td>Quick-witted, Resourceful, Versatile, Kind</td>
            </tr>
            <tr>
              <td>Ox</td>
              <td>1925, 1937, 1949, 1961, 1973, 1985, 1997, 2009, 2021</td>
              <td>Diligent, dependable, strong, determined</td>
            </tr>
            <tr>
              <td>Tiger</td>
              <td>1926, 1938, 1950, 1962, 1974, 1986, 1998, 2010, 2022</td>
              <td>Brave, confident, competitive</td>
            </tr>
            <tr>
              <td>Rabbit</td>
              <td>1927, 1939, 1951, 1963, 1975, 1987, 1999, 2011, 2023</td>
              <td>Quiet, elegant, kind, responsible</td>
            </tr>
            <tr>
              <td>Dragon</td>
              <td>1928, 1940, 1952, 1964, 1976, 1988, 2000, 2012, 2024</td>
              <td>Confident, intelligent, enthusiastic</td>
            </tr>
            <tr>
              <td>Snake</td>
              <td>1929, 1941, 1953, 1965, 1977, 1989, 2001, 2013, 2025</td>
              <td>Enigmatic, intelligent, wise</td>
            </tr>
            <tr>
              <td>Horse</td>
              <td>1930, 1942, 1954, 1966, 1978, 1990, 2002, 2014, 2026</td>
              <td>Animated, active, energetic</td>
            </tr>
            <tr>
              <td>Sheep</td>
              <td>1931, 1943, 1955, 1967, 1979, 1991, 2003, 2015, 2027</td>
              <td>Calm, gentle, sympathetic</td>
            </tr>
            <tr>
              <td>Monkey</td>
              <td>1932, 1944, 1956, 1968, 1980, 1992, 2004, 2016, 2028</td>
              <td>Sharp, smart, curious</td>
            </tr>
            <tr>
              <td>Hen</td>
              <td>1933, 1945, 1957, 1969, 1981, 1993, 2005, 2017, 2029</td>
              <td>Observant, hardworking, courageous</td>
            </tr>
            <tr>
              <td>Dog</td>
              <td>1934, 1946, 1958, 1970, 1982, 1994, 2006, 2018, 2030</td>
              <td>Lovely, honest, prudent</td>
            </tr>
            <tr>
              <td>Pig</td>
              <td>1935, 1947, 1959, 1971, 1983, 1995, 2007, 2019, 2031</td>
              <td>Compassionate, generous, diligent</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="web-wrapper">
        <h2>Horoscope (Star Signs) and Their Meanings</h2>
        <p>
          Astrological star signs originally came from the ancient process of
          transferring
          <br />
          the knowledge of looking at stars and knowing their azimuths and
          times.
          <br />
          Our antecedents combined a group of stars to make it easier to
          remember their location <br />
          by associating them with images that are well known to us. As history
          went on, numerous mysteries of stars are added <br />
          to these astrological star signs, and just like the animal signs, we
          use them in many aspects of our lives, even in recent years.
          <br />
          <br />
          The 12 star signs are Aries, Taurus, Gemini, Cancer, Leo, Virgo,
          Libra, Scorpio, Sagittarius, Capricorn, Aquarius, and Pisces.
          <br />
          Each star sign has a symbol with a particular attribute and
          personality.
          <br />
          <br />
          You can find your star sign by looking at the months you were born in
          below.
        </p>
      </div>

      <div className="pad-wrapper">
        <h2>Horoscope (Star Signs) and Their Meanings</h2>
        <p>
          Astrological star signs originally came from the ancient process of
          transferring
          <br />
          the knowledge of looking at stars and knowing their azimuths and
          times.
          <br />
          Our antecedents combined a group of stars
          <br />
          to make it easier to remember their location by associating them
          <br />
          with images that are well known to us.
          <br />
          <br />
          As history went on, numerous mysteries of stars are
          <br />
          added to these astrological star signs, and just like the animal
          signs,
          <br />
          we use them in many aspects of our lives, even in recent years.
          <br />
          <br />
          The 12 star signs are Aries, Taurus, Gemini, Cancer, Leo,
          <br />
          Virgo, Libra, Scorpio, Sagittarius, Capricorn, Aquarius, and Pisces.
          <br />
          <br />
          Each star sign has a symbol with a particular attribute and
          personality.
          <br />
          <br />
          You can find your star sign by looking at the months you were born in
          below.
        </p>
      </div>

      <div className="mobile-wrapper">
        <h2>
          Horoscope (Star Signs)
          <br />
          and Their Meanings
        </h2>
        <p>
          Astrological star signs originally came from
          <br />
          the ancient process of transferring
          <br />
          the knowledge of looking at stars
          <br />
          and knowing their azimuths and times.
          <br />
          Our antecedents combined
          <br />a group of stars to make it
          <br />
          easier to remember their location <br />
          by associating them with images
          <br />
          that are well known to us.
          <br />
          <br />
          As history went on, numerous
          <br />
          mysteries of stars are added <br />
          to these astrological star signs,
          <br />
          and just like the animal signs,
          <br />
          we use them in many aspects
          <br />
          of our lives, even in recent years.
          <br />
          <br />
          The 12 star signs are Aries, Taurus, Gemini,
          <br />
          Cancer, Leo, Virgo, Libra, Scorpio, Sagittarius,
          <br />
          Capricorn, Aquarius, and Pisces.
          <br />
          Each star sign has a symbol
          <br />
          with a particular attribute and personality.
          <br />
          <br />
          You can find your star sign by looking at
          <br />
          the months you were born in below.
        </p>
      </div>

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Star Signs</th>
              <th>Dates</th>
              <th>Key Traits</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Aries</td>
              <td>March 21 – April 19</td>
              <td>Competitive, Energetic, Impulsive, Fearless</td>
            </tr>
            <tr>
              <td>Taurus</td>
              <td>April 20 – May 20</td>
              <td>Witty, Curious, Charming, Flighty</td>
            </tr>
            <tr>
              <td>Gemini</td>
              <td>May 21 – June 21</td>
              <td>Brave, confident, competitive</td>
            </tr>
            <tr>
              <td>Cancer</td>
              <td>June 22 – July 22</td>
              <td>Compassionate, Giving, Sentimental, Nurturing</td>
            </tr>
            <tr>
              <td>Leo</td>
              <td>July 23 – August 22</td>
              <td>Charismatic, Generous, Optimistic, Dramatic</td>
            </tr>
            <tr>
              <td>Virgo</td>
              <td>August 23 – September 23</td>
              <td>Health-conscius, Analytical, Service-oriented</td>
            </tr>
            <tr>
              <td>Libra</td>
              <td>September 24 – October 22</td>
              <td>Romantic, Artistic, Indecisive, Diplomatic</td>
            </tr>
            <tr>
              <td>Scorpio</td>
              <td>October 23 – November 22</td>
              <td>Mysterious, Magnetic, Power-seeking, Spiritual</td>
            </tr>
            <tr>
              <td>Sagittarius</td>
              <td>November 23 – December 24</td>
              <td>Philosophical, Free-spirited, Wanderlusting</td>
            </tr>
            <tr>
              <td>Capricorn</td>
              <td>December 25 – January 19</td>
              <td>Traditional, Down-to-earth, Industrious, Disciplined</td>
            </tr>
            <tr>
              <td>Aquarius</td>
              <td>January 20 – February 18</td>
              <td>Humanitarian, Eccentric, Individualistic, Cool</td>
            </tr>
            <tr>
              <td>Pisces</td>
              <td>February 19 – March 20</td>
              <td>Empathic, Artistic, Psychic, Dreamy</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p>
        Collect Your Soul Zodiac <br className="mobile" />
        and Experience the Exclusive Powers!
      </p>
      <Link to="/drawZodiac">
        <Button
          title="Draw Zodiac"
          buttonStyle="orange-bright"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        />
      </Link>
    </main>
  );
};

export default FindZodi;
