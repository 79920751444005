import { useZodiInfoViewerContract } from './useContract';
import { useWeb3 } from './useWeb3';

const useZodiCustomize = () => {
  const { account } = useWeb3();

  const infoViewerContract = useZodiInfoViewerContract();

  const getTokenIDList = async () => {
    try {
      let IDs: Array<string> = [];
      if (!account) return IDs;

      let res = await infoViewerContract.getTokenURIListForAll(account);
      for (const id of res) {
        IDs.push(id.toString());
      }
      return IDs;
    } catch (error) {
      throw error;
    }
  };

  return { getTokenIDList };
};

export default useZodiCustomize;
