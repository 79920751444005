import useTokenBalanceQuery from 'api/useTokenBalanceQuery';
import useZodiBalanceQuery from 'api/useZodiBalanceQuery';
import { CHARIS } from 'config';
import { BigNumber } from 'ethers';

import { useWeb3 } from 'hooks/useWeb3';

import classes from './TokenBalanceBox.module.scss';

const TokenBalanceBox = () => {
  const { account } = useWeb3();
  const { balance: zodiBalance = '0' } = useZodiBalanceQuery();
  const { balance: charisBalance = BigNumber.from(0) } = useTokenBalanceQuery(
    CHARIS,
    account ?? undefined,
  );

  return (
    <>
      <div className={classes.label}>Token Balance</div>
      <div className={classes.balance}>
        <div className={classes.zodi}>
          <span>
            {Number(zodiBalance)
              .toLocaleString(undefined, {
                maximumFractionDigits: 6,
              })
              .replace(/,/g, '')}
          </span>{' '}
          ZODI
        </div>
        <div className={classes.dot}></div>
        <div className={classes.charis}>
          <span>
            {charisBalance
              .toNumber()
              .toLocaleString(undefined, { maximumFractionDigits: 6 })
              .replace(/,/g, '')}
          </span>{' '}
          CHARIS
        </div>
      </div>
    </>
  );
};

export default TokenBalanceBox;
