import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { expBookServerURL } from 'api';
import axios from 'axios';

import { useWeb3 } from 'hooks/useWeb3';

import { ExpBook } from 'types/expbook';

export type GetExpBooksResponse = {
  id: number;
  exp_unit: number;
  amount: number;
  address: string;
  created_at: string;
  deleted_at: string;
  updated_at: string;
}[];

const useGetExpBooksQuery = () => {
  const { account } = useWeb3();

  const fetcher = useCallback(async () => {
    const { data } = await axios.post<GetExpBooksResponse>(
      `${expBookServerURL}/delegater/experience`,
      {
        address: account,
      },
    );

    return data.map<ExpBook>((expBook) => ({
      name: `${expBook.exp_unit} EXP BOOK`,
      cnt: expBook.amount,
      exp: expBook.exp_unit,
    }));
  }, [account]);

  const { data, isLoading, isError } = useQuery(['expBooks', account], fetcher);

  return { expBooks: data, isLoading, isError };
};

export default useGetExpBooksQuery;
