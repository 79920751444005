import '../../scss/home/AboutZodiToken.scss';

import { ReactComponent as LogoZ } from '../../assets/icons/logo_final.svg';

const AboutZodiToken: React.FC = () => {
  return (
    <section className="about-zodi-token">
      <div className="about-zodi-token-image">
        <LogoZ />
      </div>
      <div className="about-zodi-token-description">
        <h1>
          What is
          <br />
          ZODI Token?
        </h1>
        <p>
          Zodium utilizes (ZODI) as an ERC-20
          <br />
          governance token for its cosmos.
          <br />
          By acquiring ZODI through various ways,
          <br />
          token holders can play games,
          <br />
          vote on crucial matters, and claim their rewards.
        </p>
        <p>
          Zodium intends to support chain-swap
          <br />
          between multi main-nets:
          <br />
          BNB (BEP20), Matic (Polygon),
          <br />
          Immutable X, and IOST.
          <br />
          <br />
          Total supply : 888,888,888 ZODI
        </p>
      </div>
    </section>
  );
};

export default AboutZodiToken;
