import '../scss/Footer.scss';

import { ReactComponent as Discord } from '../assets/icons/discord.svg';
import { ReactComponent as LogoBlack } from '../assets/icons/logo_black.svg';
import { ReactComponent as Medium } from '../assets/icons/medium.svg';
import { ReactComponent as Telegram } from '../assets/icons/telegram.svg';
import { ReactComponent as Twitter } from '../assets/icons/twitter.svg';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <ul>
          <li>
            <a
              href="https://twitter.com/zodium"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter />
              <p>Twitter</p>
            </a>
          </li>
          <li>
            <a
              href="https://medium.com/@zodium"
              target="_blank"
              rel="noreferrer"
            >
              <Medium />
              <p>Medium</p>
            </a>
          </li>
          <li>
            <a
              href="https://discord.gg/zodium"
              target="_blank"
              rel="noreferrer"
            >
              <Discord />
              <p>Discord</p>
            </a>
          </li>
          <li>
            <a
              href="https://t.me/zodiumofficial"
              target="_blank"
              rel="noreferrer"
            >
              <Telegram />
              <p>Telegram</p>
            </a>
          </li>
        </ul>

        <LogoBlack />

        <p>Discover Zodiac Signs to Play and Earn!</p>
      </div>
    </footer>
  );
};

export default Footer;
