import WeaponCollectionList from '../../../containers/WeaponCollectionList';

import useScrollLock from '../../../hooks/useScrollLock';
import useWeaponClassification from '../../../hooks/useWeaponClassification';

import { WeaponType } from '../../../types/weapon';

import classes from './ModalWeaponSelect.module.scss';

import { ReactComponent as ButtonXCircle } from '../../../assets/icons/close-btn-circle.svg';
import { ReactComponent as ButtonX } from '../../../assets/icons/x.svg';
import Dim from '../../Dim';
import WeaponCollectionNav from '../WeaponCollectionNav';

const ModalWeaponSelect = ({
  weaponCollection,
  type,
  onClose,
  onSelect,
}: {
  weaponCollection: WeaponType[];
  type: 'levelUP' | 'upgrade';
  onClose: () => void;
  onSelect?: (selected: WeaponType) => void;
}) => {
  useScrollLock();
  const classification = useWeaponClassification();

  return (
    <>
      <div className={classes.weaponSelectContainer}>
        <div className={classes.header}>
          <div className="dummy"></div>
          <div className={classes.title}>
            {type === 'levelUP' ? (
              <>
                Select the Weapons
                <br /> to Level-up
              </>
            ) : (
              <>
                Select the Weapon
                <br /> to Upgrade
              </>
            )}
          </div>
          <figure className={classes.closePC} onClick={() => onClose()}>
            <ButtonX />
          </figure>
          <figure className={classes.closeMobile} onClick={() => onClose()}>
            <ButtonXCircle />
          </figure>
        </div>
        <div>
          <WeaponCollectionNav
            classification={classification}
            forModal={true}
          />
          <WeaponCollectionList
            type={type}
            modal={true}
            active={false}
            weaponCollection={weaponCollection}
            classificationRarity={classification.weaponClassficationRarity}
            weaponOption={classification.selectedWeapon}
            elementOption={classification.selectedElement}
            onSelect={onSelect}
          />
        </div>
      </div>
      <Dim zIndex={1200} />
    </>
  );
};

export default ModalWeaponSelect;
