import { createAction, handleActions } from 'redux-actions';

const SET_OWN_TOKEN_IDS = 'userWeapon/SET_OWN_TOKEN_IDS' as const;

export const setUserOwnWeaponTokenIDs = createAction(
  SET_OWN_TOKEN_IDS,
  (tokenIds: string[]) => tokenIds,
);

export type UserWeaponAction = ReturnType<typeof setUserOwnWeaponTokenIDs>;

export type UserWeaponState = {
  tokenIds: string[];
};

const initialState: UserWeaponState = {
  tokenIds: [],
};

const userWeapon = handleActions(
  {
    [SET_OWN_TOKEN_IDS]: (
      state: UserWeaponState,
      action: UserWeaponAction,
    ) => ({ ...state, tokenIds: action.payload }),
  },
  initialState,
);

export default userWeapon;
