import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import getExpBookNotarizedData from 'api/getExpBookNotarizedData';
import useObserverTriggerMutation from 'api/useObserverTriggerMutation';
import { ReactComponent as Confirm } from 'assets/icons/confirm.svg';
import { CHARIS } from 'config';
import { BigNumber } from 'ethers';
import { RootState } from 'store';
import { clearExpBookForUse } from 'store/levelup';
import { modalClose, modalOpen } from 'store/modal';

import Loading from 'components/Loading';

import { useWeaponBaseContract } from './useContract';
import { useWeb3 } from './useWeb3';

const useExpBookCallback = () => {
  const { account } = useWeb3();
  const { weapon, expBookForUse } = useSelector((state: RootState) => ({
    weapon: state.levelupReducer.targetWeapon,
    expBookForUse: state.levelupReducer.expBookForUse,
  }));
  const weaponBaseContract = useWeaponBaseContract();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const observerTrigger = useObserverTriggerMutation();

  return async () => {
    if (!account || !weapon?.id) return;
    try {
      dispatch(
        modalOpen({
          alertImage: <Loading />,
          title: 'Waiting for Confirmation',
          description: `\nConfirm this transaction in your wallet\n\n\n`,
          dimZ: 2500,
        }),
      );
      const notarizedData = await getExpBookNotarizedData({
        address: account,
        target_token_id: weapon.id,
        use_exp: expBookForUse.map((book) => ({
          amount: book.useCnt,
          unit: book.expBook.exp,
        })),
      });
      console.log('notarizedData', notarizedData);

      const tx = await weaponBaseContract.useExpBook(
        {
          tokenId: BigNumber.from(notarizedData.tx_pack.token_id),
          expAmount: BigNumber.from(notarizedData.tx_pack.exp_amount),
          uniqueKey: notarizedData.tx_pack.unique_key,
          expiredAt: BigNumber.from(notarizedData.tx_pack.expired_at),
          sender: notarizedData.tx_pack.sender,
        },
        notarizedData.hash,
        `0x${notarizedData.v}`,
        notarizedData.r,
        notarizedData.s,
      );

      dispatch(
        modalOpen({
          alertImage: <Loading />,
          title: 'BSC Transaction Submitted',
          description: `\nWaiting for transaction confirmed\n\n\n`,
          dimZ: 2500,
        }),
      );

      const receipt = await tx.wait();
      console.log('receipt', receipt);

      observerTrigger(undefined, {
        onSuccess() {
          //refetch data
          queryClient.invalidateQueries(['weapon', String(weapon.id)]);
          queryClient.invalidateQueries(['expBooks', account]);
          queryClient.invalidateQueries(['zodiBalance']);
          queryClient.invalidateQueries(['balance', CHARIS, account]);
        },
      });

      dispatch(
        modalOpen({
          alertImage: <Confirm />,
          title: 'BSC Transaction Confirmed',
          description: undefined,
          mainButtonTitle: 'Close',
          mainButtonEvent: () => {
            dispatch(modalClose());
          },
          dimZ: 2500,
        }),
      );
      dispatch(clearExpBookForUse());
      return receipt;
    } catch (e) {
      dispatch(
        modalOpen({
          title: 'Alert!',
          description: 'BSC Transaction Error',
          mainButtonTitle: 'OK',
          mainButtonEvent: () => {
            dispatch(modalClose());
          },
          dimZ: 2500,
        }),
      );
      throw e;
    }
  };
};

export default useExpBookCallback;
