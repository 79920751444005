import { ReactComponent as Arrow } from 'assets/icons/triangle-arrow.svg';

import classes from './WeaponDetailCard.module.scss';

type WeaponDetailCardProps = {
  curLevel: number;
  curMaxLevel: number;

  nextLevel: number;
  nextMaxLevel: number;

  curExp: number;
  curMaxExp: number;

  nextExp: number;
  nextMaxExp: number;

  power: number;
  nextPower: number;
};

const WeaponDetailCard = (props: WeaponDetailCardProps) => {
  return (
    <div className={classes.card}>
      <div className={classes.info}>
        <div className={classes.label}>Lv</div>
        <div className={classes.value}>
          <div>
            {props.curLevel}/{props.curMaxLevel}
          </div>
          <figure>
            <Arrow />
          </figure>
          <div>
            <span>{props.nextLevel}</span>/{props.nextMaxLevel}
          </div>
        </div>
      </div>
      <div className={classes.info}>
        <div className={classes.label}>EXP</div>
        <div className={classes.value}>
          <div>
            {props.curExp}/{props.curMaxExp}
          </div>
          <figure>
            <Arrow />
          </figure>
          <div>
            <span>{props.nextExp}</span>/{props.nextMaxExp}
          </div>
        </div>
      </div>
      <div className={classes.info}>
        <div className={classes.label}>Power</div>
        <div className={classes.value}>
          <div>{props.power}</div>
          <figure>
            <Arrow />
          </figure>
          <div>
            <span>{props.nextPower}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeaponDetailCard;
