import { useSwapContract } from './useContract';

const useV2Swap = () => {
  const swapContract = useSwapContract();

  const updateTokenForV2 = async (
    data: any,
    hash: any,
    v: any,
    r: any,
    s: any,
  ) => {
    try {
      const txHash = await swapContract.updateTokenForV2(data, hash, v, r, s);
      await txHash.wait();
    } catch (error) {
      throw error;
    }
  };

  return {
    updateTokenForV2,
  };
};

export default useV2Swap;
