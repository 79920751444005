import { Link } from 'react-router-dom';

import '../scss/Paragraph.scss';

import Button from './Button';

type ParagraphProps = {
  title: string;
  description: string;
  buttonTitle?: string;
  link?: string;
  whiteTitle?: boolean;
  onClick?: any;
};

const Paragraph: React.FC<ParagraphProps> = ({
  title,
  description,
  buttonTitle = '',
  link = '',
  whiteTitle = false,
  onClick,
}) => {
  const isRoute: boolean = link.charAt(0) === '/' ? true : false;
  const white: string = whiteTitle ? '#fff' : '';

  return (
    <div className="paragraph">
      <h1 style={{ color: white }}>{title}</h1>
      <p style={{ color: white }}>{description}</p>
      {buttonTitle === '' ? (
        <></>
      ) : isRoute ? (
        <Link to={link}>
          <Button
            title={buttonTitle}
            buttonStyle="orange"
            width="220px"
            height="60px"
            onClick={onClick}
          />
        </Link>
      ) : (
        <a href={link} target="_blank" rel="noreferrer">
          <Button
            title={buttonTitle}
            buttonStyle="orange"
            width="220px"
            height="60px"
            onClick={onClick}
          />
        </a>
      )}
    </div>
  );
};

export default Paragraph;
