export const animalArray: string[] = [
  'Rat',
  'Ox',
  'Tiger',
  'Rabbit',
  'Dragon',
  'Snake',
  'Horse',
  'Sheep',
  'Monkey',
  'Hen',
  'Dog',
  'Pig',
];

export enum AnimalEnum {
  Rat = 'Rat',
  Ox = 'Ox',
  Tiger = 'Tiger',
  Rabbit = 'Rabbit',
  Dragon = 'Dragon',
  Snake = 'Snake',
  Horse = 'Horse',
  Sheep = 'Sheep',
  Monkey = 'Monkey',
  Hen = 'Hen',
  Dog = 'Dog',
  Pig = 'Pig',
}

export const starSignArray: string[] = [
  'Aries',
  'Taurus',
  'Gemini',
  'Cancer',
  'Leo',
  'Virgo',
  'Libra',
  'Scorpio',
  'Sagittarius',
  'Capricorn',
  'Aquarius',
  'Pisces',
];

export const tierArray: string[] = [
  'Common',
  'Rare',
  'Legendary',
  'Soul Zodiac',
];

export const tierArrayUntouchable: string[] = [
  'Common',
  'Rare',
  'Legendary',
  'Soul Zodiac',
  'Untouchable',
];

export enum ZodiacTier {
  Common = 'Common',
  Rare = 'Rare',
  Legendary = 'Legendary',
  SoulZodiac = 'Soul Zodiac',
  Untouchable = 'Untouchable',
}

type cardAdArrayType = {
  tier: string;
  animal: string;
  starSign: string;
  comment: any;
};

export const cardAdArray: cardAdArrayType[] = [
  {
    tier: 'Untouchable',
    animal: 'Tiger',
    starSign: 'Virgo',
    comment: `2022 is the year of the Black Tiger. The Black Tiger brings courage and solidity!`,
  },
  // {
  //     tier: "Untouchable",
  //     animal: "Tiger",
  //     starSign: "Taurus",
  //     comment: `2021 is the year of the White Ox.\nThe White Ox is considered to bring\nAspiration and luck to its owner!`,
  // },
];
