import { SWAP_ADDRESS, contract_ZODIBASE } from 'config/v1_config';
import { ethers } from 'ethers';

import { use12KindsContractV1 } from './useContract';
import { useWeb3 } from './useWeb3';

const useCollectionV1 = () => {
  const { account } = useWeb3();

  const contract = use12KindsContractV1();

  const getBalanceOf = async () => {
    try {
      if (!account) return '0';

      const balance = await contract.balanceOf(account);
      return balance.toString();
    } catch (error) {
      throw error;
    }
  };

  const getTokenOfOwnerByIndex = async (index: number) => {
    try {
      if (!account) return undefined;

      const tokenID = await contract.tokenOfOwnerByIndex(
        account,
        _typecasting(index),
      );
      return tokenID.toString();
    } catch (error) {
      throw error;
    }
  };

  const getTokenURI = async (tokenID: string) => {
    try {
      const uri = await contract.tokenURI(_typecasting(Number(tokenID)));
      return uri;
    } catch (error) {
      throw error;
    }
  };

  const approvalForAll = async () => {
    try {
      const txHash = await contract.setApprovalForAll(
        contract_ZODIBASE.addr,
        true,
      );
      await txHash.wait();
    } catch (error) {
      throw error;
    }
  };

  const getBaseUriFromContract = async () => {
    try {
      const uri = await contract.getBaseURI();
      return uri;
    } catch (error) {
      throw error;
    }
  };

  const approveForAllToSwap = async () => {
    try {
      const txHash = await contract.setApprovalForAll(SWAP_ADDRESS, true);
      await txHash.wait();
    } catch (e) {
      throw e;
    }
  };

  const isApprovedForAllToSwap = async (owner: string) => {
    try {
      return await contract.isApprovedForAll(owner, SWAP_ADDRESS);
    } catch (error) {
      throw error;
    }
  };

  return {
    getBalanceOf,
    getTokenOfOwnerByIndex,
    getTokenURI,
    approvalForAll,
    getBaseUriFromContract,
    approveForAllToSwap,
    isApprovedForAllToSwap,
  };
};

//NOTE x util
const _typecasting = (v: number) => {
  return ethers.BigNumber.from(v);
};

export default useCollectionV1;
