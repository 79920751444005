import { createAction, handleActions } from 'redux-actions';

const DRAWED_TRUE = 'drawed/DRAWED_TRUE' as const;
const DRAWED_FALSE = 'drawed/DRAWED_FALSE' as const;

export const drawedTrue = createAction(DRAWED_TRUE);
export const drawedFalse = createAction(DRAWED_FALSE);

export type NewPackAction =
  | ReturnType<typeof drawedTrue>
  | ReturnType<typeof drawedFalse>;

export type DrawedState = {
  drawed: boolean;
};

const initialState: DrawedState = {
  drawed: false,
};

const drawed = handleActions(
  {
    [DRAWED_TRUE]: (state: DrawedState) => ({ ...state, drawed: true }),
    [DRAWED_FALSE]: (state: DrawedState) => ({ ...state, drawed: false }),
  },
  initialState,
);

export default drawed;
