import { createAction, handleActions } from 'redux-actions';

const CONNECT = 'connected/CONNECT' as const;
const DISCONNECT = 'connected/DISCONNECT' as const;

export const r_connect = createAction(CONNECT);
export const r_disconnect = createAction(DISCONNECT);

export type ConnectedAction =
  | ReturnType<typeof r_connect>
  | ReturnType<typeof r_disconnect>;

export type ConnectedState = {
  connected: boolean;
  month: string;
  day: string;
  year: string;
};

const initialState: ConnectedState = {
  connected: false,
  month: 'January',
  day: '01',
  year: '1960',
};

const connected = handleActions(
  {
    [CONNECT]: (state: ConnectedState, action: ConnectedAction) => ({
      ...state,
      connected: true,
      month: action.payload.month,
      day: action.payload.day,
      year: action.payload.year,
    }),
    [DISCONNECT]: (state: ConnectedState) => ({
      ...state,
      connected: false,
    }),
  },
  initialState,
);

export default connected;
