import requiredWeaponToUpgradeMap from 'constants/requiredWeaponToUpgrade';
import { expMap } from 'constants/weaponExp';
import { createAction, handleActions } from 'redux-actions';

import { WeaponAttributes, WeaponType } from '../types/weapon';

type WeaponUpgradeState = {
  weaponCollection: WeaponType[];
  targetWeapon: WeaponType | null;
  targetWeaponList: WeaponType[];
  weaponsForUpgrade: WeaponType[];
  canUpgradeNow: boolean;
};

const SET_WEAPON_COLLECTION = 'weaponUpgrade/SET_WEAPON_COLLECTION' as const;
const SELECT_WEAPON = 'weaponUpgrade/SELECT_WEAPON' as const;
const UNSELECT_WEAPON = 'weaponUpgrade/UNSELECT_WEAPON' as const;
const USE_FOR_UPGRADE = 'weaponUpgrade/USE_FOR_UPGRADE' as const;
const UNUSE_FOR_UPGRADE = 'weaponUpgrade/UNUSE_FOR_UPGRADE' as const;
const CLEAR_FOR_UPGRADE = 'weaponUpgrade/CLEAR_FOR_UPGRADE' as const;

export const setWeaponCollection = createAction(
  SET_WEAPON_COLLECTION,
  (collection: WeaponType[]) => {
    return collection.filter((w) => {
      const { stackedExp, level } = w.attributes;

      const isReachTierMaxLevel = level > 0 && level % 10 === 0;
      const isExpFull = stackedExp >= expMap[level + 1].stacked;
      return isReachTierMaxLevel && isExpFull;
    });
  },
);
export const selectWeapon = createAction(
  SELECT_WEAPON,
  (weapon: WeaponType) => weapon,
);
export const unselectWeapon = createAction(UNSELECT_WEAPON);
export const selectForUpgrade = createAction(
  USE_FOR_UPGRADE,
  (weapon: WeaponType) => weapon,
);
export const unSelectForUpgrade = createAction(
  UNUSE_FOR_UPGRADE,
  (weapon: WeaponType) => weapon,
);
export const clearForUpgrade = createAction(CLEAR_FOR_UPGRADE, () => {});

const initialState: WeaponUpgradeState = {
  weaponCollection: [],
  targetWeapon: null,
  targetWeaponList: [],
  weaponsForUpgrade: [],
  canUpgradeNow: false,
};

const weaponUpgradeReducer = handleActions<
  WeaponUpgradeState,
  WeaponType | WeaponType[]
>(
  {
    [SET_WEAPON_COLLECTION]: (state, { payload }) => ({
      ...state,
      weaponCollection: payload as WeaponType[],
    }),
    [SELECT_WEAPON]: (state, { payload }) => {
      const targetAttr = (payload as WeaponType).attributes;

      const targetWeaponList = [...state.weaponCollection].filter((w) => {
        return isSameAttr(targetAttr, w.attributes);
      });
      return {
        ...state,
        targetWeapon: payload as WeaponType,
        targetWeaponList,
        weaponsForUpgrade: [],
        canUpgradeNow: false,
      };
    },
    [UNSELECT_WEAPON]: (state) => ({
      ...initialState,
      weaponCollection: [...state.weaponCollection],
    }),
    [USE_FOR_UPGRADE]: (state, { payload }) => {
      if (state.targetWeapon === null || state.canUpgradeNow) return state;

      const weaponsForUpgrade = [
        ...state.weaponsForUpgrade,
        payload as WeaponType,
      ];

      const { rarity } = state.targetWeapon.attributes;
      const required = requiredWeaponToUpgradeMap[Number(rarity) + 1];

      const canUpgradeNow = weaponsForUpgrade.length === required;

      return {
        ...state,
        weaponsForUpgrade,
        canUpgradeNow,
      };
    },
    [UNUSE_FOR_UPGRADE]: (state, { payload }) => ({
      ...state,
      weaponsForUpgrade: [...state.weaponsForUpgrade].filter(
        (w) => w.id !== (payload as WeaponType).id,
      ),
      canUpgradeNow: false,
    }),
    [CLEAR_FOR_UPGRADE]: (state) => {
      console.log('state.weaponCollection', state.weaponCollection);
      console.log(
        'new weaponCollection',
        [...state.weaponCollection].filter(
          (w) =>
            state.weaponsForUpgrade.findIndex((used) => w.id === used.id) ===
            -1,
        ),
      );
      return {
        ...state,
        weaponCollection: [...state.weaponCollection].filter(
          (w) =>
            state.weaponsForUpgrade.findIndex((used) => w.id === used.id) ===
            -1,
        ),
        targetWeapon: null,
        targetWeaponList: [],
        weaponsForUpgrade: [],
        canUpgradeNow: false,
      };
    },
  },
  initialState,
);

function isSameAttr(w1: WeaponAttributes, w2: WeaponAttributes) {
  return (
    w1.rarity === w2.rarity &&
    w1.name === w2.name &&
    w1.element === w2.element &&
    w1.level === w2.level
  );
}

export default weaponUpgradeReducer;
