import axios from 'axios';

const isProduction = process.env.NODE_ENV === 'production';

export const URL = isProduction
  ? 'https://api-swap.zodium.io/v1/swap/discovering'
  : 'https://apibeta-swap.zodium.io/v1/swap/discovering';

export type SwapDiscovered = {
  discovered_items: any;
  hashed: any;
  r: any;
  s: any;
  v: any;
};

const swapDiscovering = async (tokenIds: Array<number>) => {
  try {
    const response = await axios.post<SwapDiscovered>(`${URL}`, {
      token_ids: tokenIds,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default swapDiscovering;
