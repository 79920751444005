import { useCallback, useState } from 'react';

import useWeaponCollectionQuery from 'api/useWeaponCollectionQuery';

import Button from '../components/Button';
import WeaponCollectionNav from '../components/Weapon/WeaponCollectionNav';

import useWeaponClassification from '../hooks/useWeaponClassification';

import '../scss/WeaponCollection.scss';

import WeaponCollectionList from './WeaponCollectionList';
import WeaponLevelUp from './WeaponLevelUp';
import WeaponUpgrade from './WeaponUpgrade';

export default function WeaponCollection({ active }: { active: boolean }) {
  const { isLoading, data } = useWeaponCollectionQuery();

  const [showWeaponLevelUpModal, setShowWeaponLevelUpModal] = useState(false);
  const [showWeaponUpgradeModal, setShowWeaponUpgradeModal] = useState(false);

  const classification = useWeaponClassification();

  const toggleLevelUpModal = useCallback(
    () => setShowWeaponLevelUpModal((prev) => !prev),
    [],
  );
  const toggleUpgradeModal = useCallback(
    () => setShowWeaponUpgradeModal((prev) => !prev),
    [],
  );

  return (
    <>
      <div className="nav-wrapper">
        <WeaponCollectionNav classification={classification} />
        <div className="weapon-collection-buttons">
          {isLoading ? (
            <>
              <div className="claim-btn">
                <Button
                  title="Level Up"
                  width="100%"
                  height="74px"
                  buttonStyle="grey-disable"
                  isDisabled={true}
                />
              </div>
              <div className="upgrade-btn">
                <Button
                  title="Upgrade Weapons"
                  width="100%"
                  height="74px"
                  buttonStyle="grey-disable"
                  isDisabled={true}
                />
              </div>
            </>
          ) : (
            <>
              <div className="claim-btn">
                <Button
                  title={'Level Up'}
                  width="100%"
                  height="74px"
                  onClick={toggleLevelUpModal}
                />
              </div>
              <div className="upgrade-btn">
                <Button
                  title={'Upgrade Weapons'}
                  width="100%"
                  height="74px"
                  onClick={toggleUpgradeModal}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <WeaponCollectionList
        active={active}
        weaponCollection={data}
        classificationRarity={classification.weaponClassficationRarity}
        weaponOption={classification.selectedWeapon}
        elementOption={classification.selectedElement}
        collectionLoading={isLoading}
      />
      {showWeaponLevelUpModal && (
        <WeaponLevelUp weaponCollection={data} onClose={toggleLevelUpModal} />
      )}
      {showWeaponUpgradeModal && (
        <WeaponUpgrade weaponCollection={data} onClose={toggleUpgradeModal} />
      )}
    </>
  );
}
