import { useState } from 'react';

import styles from '../scss/Input.module.scss';

export default function Input({
  type,
  placeholder,
  suffix,
  value,
  readonly,
  validate,
  onChange,
}: {
  type: 'number' | 'text';
  placeholder: string;
  suffix?: React.ReactChild;
  value?: string;
  readonly?: boolean;
  onChange?: (value: string | number) => void;
  validate?: (value: string | number) => boolean;
}) {
  const [data, setData] = useState('');
  const [isError, setIsError] = useState(false);

  const checkValidate = (value: number | string) => {
    if (validate === undefined) {
      onChange?.(value);
      return;
    }

    if (validate(value)) {
      setIsError(false);
      onChange?.(value);
    } else {
      setIsError(true);
    }
  };

  const inputAttribute = () => {
    let option: any = {
      className: isError ? styles.inputErr : styles.input,
      type: type,
      placeholder: placeholder,
      value: value !== undefined ? value : data,
      readOnly: readonly,
      onInput: (event: any) => {
        if (type === 'number' && event.target.value.length > 13) {
          event.preventDefault();
          return;
        }
        setData(event.target.value);
        checkValidate(event.target.value);
      },
      onWheel: (event: any) => {
        event.target.blur();
      },
    };

    return option;
  };

  return (
    <div className={styles.inputGroup}>
      <div className={isError ? styles.inputBoxErr : styles.inputBox}>
        <input {...inputAttribute()} />
        <div className={styles.suffix}>{suffix}</div>
      </div>
    </div>
  );
}
