import classes from './ProgressBar.module.scss';

export default function ProgressBar({
  value,
  height = '21px',
}: {
  value: number;
  height?: string;
}) {
  const customCss: any = {
    height,
  };

  return (
    <div className={classes.progressContainer}>
      {value >= 100 || value === 0 ? (
        <progress
          style={customCss}
          value={value}
          max={100}
          className={classes.fullbar}
        ></progress>
      ) : (
        <progress
          style={customCss}
          value={value}
          max={100}
          className={classes.bar}
        ></progress>
      )}
    </div>
  );
}
