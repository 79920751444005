import { useCallback, useEffect, useState } from 'react';

import FiveElements from 'assets/images/weapon/elements/five_elements.png';

import WalletAddress from 'components/WalletAddress';

import { useWeaponLimit } from 'hooks/useWeaponLimit';
import { useWeb3 } from 'hooks/useWeb3';

import 'scss/draw/DrawWeaponContainer.scss';

import MintedWeaponListContainer from './MintedWeaponListContainer';
import WeaponDrawing from './WeaponDrawing';

export default function DrawWeaponContainer({
  balance,
  weaponPayRate,
  drawed,
}: {
  balance: string;
  weaponPayRate: number;
  drawed: boolean;
}) {
  const { active } = useWeb3();
  const { getWeaponLimit, getWeaponNames } = useWeaponLimit();
  const [weaponLimits, setWeaponLimits] = useState<number[]>([]);

  const getWeaponInfo = useCallback(async () => {
    const weaponLimits: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const _limit = await getWeaponLimit();
    const _names = await getWeaponNames();

    if (!_limit || !_names) return;

    _names.forEach(
      (v, i) => (weaponLimits[v.toNumber()] += _limit[i].toNumber()),
    );

    setWeaponLimits(weaponLimits);
  }, [getWeaponLimit, getWeaponNames]);

  useEffect(() => {
    if (active) {
      getWeaponInfo();
    }
  }, [active, getWeaponInfo]);

  const isDrawable = weaponLimits.reduce((sum, cur) => (sum += cur), 0) !== 0;

  return (
    <>
      <h1 style={{ paddingTop: '120px' }}>Draw 12Weapons NFT</h1>
      <p className="draw-weapon__desc pc">
        There are Soul Weapons for each Zodiac Animals.
        <br />
        Craft a Soul Weapons to makes the Zodiac Animals even more powerful.{' '}
        <br />
        Requires <span>100 ZODI</span> to draw <span>1 weapon.</span>
      </p>
      <p className="draw-weapon__desc mobile">
        There are Soul Weapons for
        <br />
        each Zodiac Animals.
        <br />
        Craft a Soul Weapons that makes the
        <br />
        Zodiac Animals even more powerful.
        <br />
        Requires <span>100 ZODI</span> to draw <span>1 weapon.</span>
      </p>
      <div className="draw-weapon__walletInfo">
        <h3>Buy ZODI on PancakeSwap</h3>
        <p className="balance">ZODI Balance : {balance}</p>
        <div className="wallet-address-wrapper">
          <WalletAddress
            account={'0x0ccA2F5561Bb0fCa88e5b9B48b7Fbf000349c357'}
            prepend="ZODI Contract Address: "
          />
          <WalletAddress
            account={'0x0ccA2F5561Bb0fCa88e5b9B48b7Fbf000349c357'}
            short
            prepend="ZODI Contract Address: "
          />
        </div>
        <p className="wallet-address-wrapper_p">
          You need $ZODI tokens <br />
          ready in your wallet to draw the weapon
        </p>
      </div>
      <div className="five-elements">
        <img src={FiveElements} alt="Five Elements" />
        <div className="five-elements__desc pc">
          <h3>The Weapon 5 Elements</h3>
          There are five weapon attributes: Fire, Wood, Metal, and Earth. <br />
          They are randomly and drawn.
          <br /> The properties of the weapon are used to calculate player rolls
          for upcoming games.
        </div>
        <div className="five-elements__desc mobile">
          <h3>The Weapon 5 Elements</h3>
          There are five weapon attributes:
          <br />
          Fire, Water, Wood, Metal, and Earth
          <br />
          They are randomly determined and
          <br />
          drawn. The properties of the weapon are
          <br />
          used to calculate player rolls for
          <br />
          upcoming games.
        </div>
      </div>
      <div className="draw-weapon__weaponInfo">
        <WeaponDrawing
          token={balance}
          drawed={drawed}
          weaponPayRate={weaponPayRate}
          isDrawable={isDrawable}
        />
        <MintedWeaponListContainer weaponLimits={weaponLimits} />
      </div>
    </>
  );
}
