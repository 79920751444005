import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';

import WeaponCard from 'components/Weapon/WeaponCard';
import EmptyWeaponCard from 'components/Weapon/WeaponUpgrade/EmptyWeaponCard';
import ModalWeaponSelect from 'components/Weapon/WeaponUpgrade/ModalWeaponSelect';
import ModalWeaponUpgradeWrapper from 'components/Weapon/WeaponUpgrade/ModalWeaponUpgradeWrapper';
import TokenBalanceBox from 'components/Weapon/WeaponUpgrade/TokenBalanceBox';
import UpgradableWeaponList from 'components/Weapon/WeaponUpgrade/UpgradableWeaponList';
import UpgradeDetailInfo from 'components/Weapon/WeaponUpgrade/WeaponDetail/UpgradeDetailInfo';

import { WeaponType } from 'types/weapon';

import {
  selectWeapon,
  setWeaponCollection,
  unselectWeapon,
} from '../store/weaponUpgrade';

const WeaponUpgrade = ({
  weaponCollection,
  onClose,
}: {
  weaponCollection: WeaponType[];
  onClose: () => void;
}) => {
  const dispatch = useDispatch();

  const {
    weaponCollection: upgradable,
    targetWeapon,
    targetWeaponList,
    canUpgradeNow,
  } = useSelector((state: RootState) => state.weaponUpgradeReducer);

  useEffect(() => {
    console.log('weaponCollection props len: ', weaponCollection.length);
    dispatch(setWeaponCollection(weaponCollection));
  }, [dispatch, weaponCollection]);

  const handleClose = useCallback(() => {
    dispatch(unselectWeapon());
  }, [dispatch]);

  const handleSelect = useCallback(
    (selected: WeaponType) => {
      dispatch(selectWeapon(selected));
    },
    [dispatch],
  );

  console.log('upgradable', upgradable);

  return (
    <>
      <ModalWeaponSelect
        weaponCollection={upgradable}
        type={'upgrade'}
        onClose={onClose}
        onSelect={handleSelect}
      />
      {targetWeapon && (
        <ModalWeaponUpgradeWrapper
          type={'upgrade'}
          onClose={handleClose}
          left={
            <>
              {canUpgradeNow ? (
                <WeaponCard
                  weapon={targetWeapon}
                  forModal={true}
                  showNextTier={true}
                />
              ) : (
                <EmptyWeaponCard />
              )}
              <UpgradeDetailInfo />
            </>
          }
          right={
            <>
              <TokenBalanceBox />
              <UpgradableWeaponList weapons={targetWeaponList} />
            </>
          }
        />
      )}
    </>
  );
};

export default WeaponUpgrade;
