import { Web3ReactProvider } from '@web3-react/core';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import { createStore } from 'redux';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { getLibrary } from './provider';

import App from './App';
import reportWebVitals from './reportWebVitals';
import rootReducer from './store';

const store = createStore(rootReducer);
const queryClient = new QueryClient();
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <HashRouter>
          <App />
        </HashRouter>
      </Provider>
    </Web3ReactProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);

reportWebVitals();
