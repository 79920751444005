import { useDispatch } from 'react-redux';

import '../scss/CardUpgrade.scss';

import { ReactComponent as XRed } from '../assets/icons/x_red.svg';
import { unselectCard } from '../store/myPack';

import CardProperties from './CardProperties';

type CardUpgradeProps = {
  id: string;
  tier: string;
  animal: string;
  starSign: string;
  image: string;
  used: boolean;
};

const CardUpgrade: React.FC<CardUpgradeProps> = ({
  id,
  tier,
  animal,
  starSign,
  image,
  used,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="card-upgrade">
      {tier === 'Common' || tier === 'Rare' ? (
        <img src={image} alt="" />
      ) : (
        <video autoPlay muted loop playsInline key={image}>
          <source src={image} type="video/mp4" />
        </video>
      )}
      <h2>{animal}</h2>
      <CardProperties
        tier={tier}
        starSign={starSign}
        land={tier === 'Common' ? 'No' : 'Yes'}
        motion={tier === 'Common' || tier === 'Rare' ? 'No' : 'Yes'}
        edge={tier === 'Soul Zodiac' ? 'Yes' : 'No'}
        border={false}
      />
      {!used && <XRed onClick={() => dispatch(unselectCard(id))} />}
    </div>
  );
};

export default CardUpgrade;
