import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useGetExpBooksQuery from 'api/useGetExpBooksQuery';

import WeaponCard from '../components/Weapon/WeaponCard';
import ExpBookList from '../components/Weapon/WeaponUpgrade/ExpBookList';
import ModalWeaponSelect from '../components/Weapon/WeaponUpgrade/ModalWeaponSelect';
import ModalWeaponUpgradeWrapper from '../components/Weapon/WeaponUpgrade/ModalWeaponUpgradeWrapper';
import TokenBalanceBox from '../components/Weapon/WeaponUpgrade/TokenBalanceBox';
import LevelUpDetailInfo from '../components/Weapon/WeaponUpgrade/WeaponDetail/LevelUpDetailInfo';

import { WeaponType } from '../types/weapon';

import { RootState } from '../store';
import { selectWeapon, unselectWeapon, updateExpBooks } from '../store/levelup';

const WeaponLevelUp = ({
  weaponCollection,
  onClose,
}: {
  weaponCollection: WeaponType[];
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const { targetWeapon, predictedExp } = useSelector(
    (state: RootState) => state.levelupReducer,
  );

  const { expBooks } = useGetExpBooksQuery();

  useEffect(() => {
    if (expBooks) {
      dispatch(updateExpBooks(expBooks));
    }
  }, [expBooks, dispatch]);

  return (
    <>
      <ModalWeaponSelect
        weaponCollection={weaponCollection}
        type={'levelUP'}
        onClose={onClose}
        onSelect={(selected) => dispatch(selectWeapon(selected))}
      />
      {targetWeapon && (
        <ModalWeaponUpgradeWrapper
          type={'levelUP'}
          onClose={() => dispatch(unselectWeapon(targetWeapon))}
          left={
            <>
              <WeaponCard
                weapon={targetWeapon}
                forModal={true}
                predictedExp={predictedExp}
              />
              <LevelUpDetailInfo />
            </>
          }
          right={
            <>
              <TokenBalanceBox />
              <ExpBookList />
            </>
          }
        />
      )}
    </>
  );
};

export default WeaponLevelUp;
