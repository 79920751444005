import { useMemo } from 'react';

import erc20Abi from 'abi/erc20.json';
import erc721Abi from 'abi/erc721.json';
import {
  contract_12WEAPONS,
  contract_WALLETBASE,
  contract_WEAPON,
  contract_WEAPONBASE,
  contract_WEAPONSETTING,
  contract_ZODIBASE,
  contract_ZODIINFOVIEWER,
  contract_ZODIUM,
  contract_ZODIUM12KINDS,
} from 'config';
import {
  contract_SWAP,
  contract_ZODIUM12KINDS as contract_ZODIUM12KINDS_V1,
} from 'config/v1_config';
import { ethers } from 'ethers';

import { useWeb3 } from 'hooks/useWeb3';

import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';

import {
  Erc20,
  Erc721,
  WalletBase,
  Weapon,
  WeaponBase,
  WeaponCollection,
  WeaponSetting,
  Zodium12KindsV1,
  Zodium12KindsV2,
  ZodiumBaseV2,
  ZodiumInfoViewerV2,
  ZodiumSwapForV2,
  ZodiumTokenV2,
} from 'abi/types';

function getProviderOrSigner(
  library: Web3Provider,
  account?: string,
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

export const useWalletBaseContract = () => {
  const { account, library } = useWeb3();
  const signer = useMemo(
    () => getProviderOrSigner(library, account ?? undefined),
    [library, account],
  );

  return useMemo(
    () =>
      new ethers.Contract(
        contract_WALLETBASE.addr,
        contract_WALLETBASE.abi,
        signer,
      ),
    [signer],
  ) as WalletBase;
};

/**
 * weapons
 */
export const useWeaponBaseContract = () => {
  const { account, library } = useWeb3();
  const signer = useMemo(
    () => getProviderOrSigner(library, account ?? undefined),
    [library, account],
  );

  return useMemo(
    () =>
      new ethers.Contract(
        contract_WEAPONBASE.addr,
        contract_WEAPONBASE.abi,
        signer,
      ),
    [signer],
  ) as WeaponBase;
};

export const useWeaponContract = () => {
  const { account, library } = useWeb3();
  const signer = useMemo(
    () => getProviderOrSigner(library, account ?? undefined),
    [library, account],
  );

  return useMemo(
    () =>
      new ethers.Contract(contract_WEAPON.addr, contract_WEAPON.abi, signer),
    [signer],
  ) as Weapon;
};

export const useWeaponSettingContract = () => {
  const { account, library } = useWeb3();
  const signer = useMemo(
    () => getProviderOrSigner(library, account ?? undefined),
    [library, account],
  );

  return useMemo(
    () =>
      new ethers.Contract(
        contract_WEAPONSETTING.addr,
        contract_WEAPONSETTING.abi,
        signer,
      ),
    [signer],
  ) as WeaponSetting;
};

export const use12WeaponsContract = () => {
  const { account, library } = useWeb3();
  const signer = useMemo(
    () => getProviderOrSigner(library, account ?? undefined),
    [library, account],
  );

  return useMemo(
    () =>
      new ethers.Contract(
        contract_12WEAPONS.addr,
        contract_12WEAPONS.abi,
        signer,
      ),
    [signer],
  ) as WeaponCollection;
};

/**
 * zodium
 */
export const use12KindsContract = () => {
  const { account, library } = useWeb3();
  const signer = useMemo(
    () => getProviderOrSigner(library, account ?? undefined),
    [library, account],
  );

  return useMemo(
    () =>
      new ethers.Contract(
        contract_ZODIUM12KINDS.addr,
        contract_ZODIUM12KINDS.abi,
        signer,
      ),
    [signer],
  ) as Zodium12KindsV2;
};

export const use12KindsContractV1 = () => {
  const { account, library } = useWeb3();
  const signer = useMemo(
    () => getProviderOrSigner(library, account ?? undefined),
    [library, account],
  );

  return useMemo(
    () =>
      new ethers.Contract(
        contract_ZODIUM12KINDS_V1.addr,
        contract_ZODIUM12KINDS_V1.abi,
        signer,
      ),
    [signer],
  ) as Zodium12KindsV1;
};

export const useSwapContract = () => {
  const { account, library } = useWeb3();
  const signer = useMemo(
    () => getProviderOrSigner(library, account ?? undefined),
    [library, account],
  );

  return useMemo(
    () => new ethers.Contract(contract_SWAP.addr, contract_SWAP.abi, signer),
    [signer],
  ) as ZodiumSwapForV2;
};

export const useZodibaseContract = () => {
  const { account, library } = useWeb3();
  const signer = useMemo(
    () => getProviderOrSigner(library, account ?? undefined),
    [library, account],
  );

  return useMemo(
    () =>
      new ethers.Contract(
        contract_ZODIBASE.addr,
        contract_ZODIBASE.abi,
        signer,
      ),
    [signer],
  ) as ZodiumBaseV2;
};

export const useZodiInfoViewerContract = () => {
  const { account, library } = useWeb3();
  const signer = useMemo(
    () => getProviderOrSigner(library, account ?? undefined),
    [library, account],
  );

  return useMemo(
    () =>
      new ethers.Contract(
        contract_ZODIINFOVIEWER.addr,
        contract_ZODIINFOVIEWER.abi,
        signer,
      ),
    [signer],
  ) as ZodiumInfoViewerV2;
};

export const useZodiumContract = () => {
  const { account, library } = useWeb3();
  const signer = useMemo(
    () => getProviderOrSigner(library, account ?? undefined),
    [library, account],
  );

  return useMemo(
    () =>
      new ethers.Contract(contract_ZODIUM.addr, contract_ZODIUM.abi, signer),
    [signer],
  ) as ZodiumTokenV2;
};

export const useERC20Contract = (address: string) => {
  const { account, library } = useWeb3();
  const signer = useMemo(
    () => getProviderOrSigner(library, account ?? undefined),
    [library, account],
  );

  return useMemo(
    () => new ethers.Contract(address, erc20Abi, signer),
    [address, signer],
  ) as Erc20;
};

export const useERC721Contract = (address: string) => {
  const { account, library } = useWeb3();
  const signer = useMemo(
    () => getProviderOrSigner(library, account ?? undefined),
    [library, account],
  );

  return useMemo(
    () => new ethers.Contract(address, erc721Abi, signer),
    [address, signer],
  ) as Erc721;
};
