import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as MinusBtn } from 'assets/icons/book-minus-btn.svg';
import { ReactComponent as PlusBtn } from 'assets/icons/book-plus-btn.svg';
import { ReactComponent as DoubleLeftBtn } from 'assets/icons/double-arrow-left-btn.svg';
import { ReactComponent as DoubleRightBtn } from 'assets/icons/double-arrow-right-btn.svg';
import ExpBookImg from 'assets/images/exp_book.png';
import { RootState } from 'store';
import {
  isSameExpBook,
  minusAllExpBookForUse,
  minusExpBookForUse,
  plusAllExpBookForUse,
  plusExpBookForUse,
  updateExpBookForUse,
} from 'store/levelup';

import { ExpBook } from 'types/expbook';

import classes from './ExpBook.module.scss';

const ExpBookItem = ({ book }: { book: ExpBook }) => {
  const disapch = useDispatch();
  const { expBookForUse, tierMaxExp, targetWeapon } = useSelector(
    (state: RootState) => state.levelupReducer,
  );
  const usedBookCnt =
    expBookForUse.find((b) => isSameExpBook(b.expBook, book))?.useCnt || 0;

  const minusBtnHandler = useCallback(() => {
    disapch(minusExpBookForUse(book));
  }, [disapch, book]);

  const plusBtnHandler = useCallback(() => {
    disapch(plusExpBookForUse(book));
  }, [disapch, book]);

  const minusAllBtnHandler = useCallback(() => {
    disapch(minusAllExpBookForUse(book));
  }, [disapch, book]);

  const plusAllBtnHandler = useCallback(() => {
    disapch(plusAllExpBookForUse(book));
  }, [disapch, book]);

  const inputHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const userTyped = e.target.value;
    if (userTyped === '') {
      disapch(minusAllExpBookForUse(book));
      return;
    }
    const num = Number(userTyped);
    if (num <= 0) {
      disapch(minusAllExpBookForUse(book));
      return;
    }
    if (num >= book.cnt) {
      disapch(plusAllExpBookForUse(book));
      return;
    }

    const usedOtherBookExp = expBookForUse
      .filter((b) => !isSameExpBook(b.expBook, book))
      .reduce((sum, book) => (sum += book.useCnt * book.expBook.exp), 0);
    const left =
      tierMaxExp -
      (targetWeapon?.attributes.stackedExp ?? 0) -
      usedOtherBookExp;

    if (num * book.exp > left) {
      const canUse = Math.ceil(left / book.exp);
      disapch(updateExpBookForUse({ expBook: book, useCnt: canUse }));
      return;
    }
    disapch(updateExpBookForUse({ expBook: book, useCnt: num }));
  };

  const inputBlurHandler: React.FocusEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (e.target.value === '') {
          disapch(minusAllExpBookForUse(book));
        }
      },
      [disapch, book],
    );

  return (
    <div className={classes.book}>
      <div className={classes.bookImg}>
        <img src={ExpBookImg} alt="expbook" />
        <div className={classes.bookCnt}>x{book.cnt}</div>
      </div>
      <div className={classes.bookInfo}>
        <div className={classes.name}>{book.name}</div>
        <div className={classes.bookBtns}>
          <DoubleLeftBtn onClick={minusAllBtnHandler} />
          <MinusBtn onClick={minusBtnHandler} />
          <input
            value={usedBookCnt}
            type={'number'}
            className={classes.curBook}
            onChange={inputHandler}
            onKeyPress={numberKeyValidation}
            onBlur={inputBlurHandler}
          />
          <PlusBtn onClick={plusBtnHandler} />
          <DoubleRightBtn onClick={plusAllBtnHandler} />
        </div>
      </div>
    </div>
  );
};

// allow number, backspace, delete, left, right arrow key
function numberKeyValidation(e: React.KeyboardEvent<HTMLInputElement>) {
  if (
    e.keyCode === 8 ||
    e.keyCode === 46 ||
    e.keyCode === 37 ||
    e.keyCode === 39
  ) {
    return true;
  } else if (!/[0-9]+/.test(e.key)) {
    e.preventDefault();
  }
}

export default ExpBookItem;
