import { CardType } from '../types/card';

type PackingType = {
  packNum: number;
  pack: CardType[];
};

export const packing = (packs: CardType[]) => {
  let result: PackingType[] = [];

  for (let i = 0; i < packs.length / 3; i++) {
    let pack: PackingType = { packNum: i + 1, pack: [] };

    for (let j = i * 3; j < i * 3 + 3; j++) {
      pack.pack.push(packs[j]);
    }

    result.push(pack);
  }

  return result;
};
