import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import useTokenBalanceQuery from 'api/useTokenBalanceQuery';
import useZodiBalanceQuery from 'api/useZodiBalanceQuery';
import CharisIcon from 'assets/images/CHARIS.png';
import ZodiIcon from 'assets/images/ZODI.png';
import { CHARIS, WEAPON_BASE } from 'config';
import { BigNumber } from 'ethers';
import { RootState } from 'store';
import { selectWeapon } from 'store/levelup';

import { useApproveToken, useApproveZodi } from 'hooks/useApproveToken';
import useExpBookCallback from 'hooks/useExpBookCallback';
import { useWeb3 } from 'hooks/useWeb3';

import { ApprovalState } from 'types/contract';
import { WeaponType } from 'types/weapon';

import classes from './UsedTokenForWeaponUpgrade.module.scss';

const UsedTokenForLevelUp = () => {
  const { account } = useWeb3();
  const { expBookForUse, targetWeapon } = useSelector((state: RootState) => ({
    expBookForUse: state.levelupReducer.expBookForUse,
    targetWeapon: state.levelupReducer.targetWeapon,
  }));
  const sumOfExp = expBookForUse.reduce(
    (sum, expBook) => (sum += expBook.useCnt * expBook.expBook.exp),
    0,
  );
  const charisAmount = sumOfExp * 2;

  // approval
  const [zodiApproval, approveZodi] = useApproveZodi(
    WEAPON_BASE,
    BigNumber.from(100),
  );
  const [charisApproval, approveCharis] = useApproveToken(
    CHARIS,
    WEAPON_BASE,
    BigNumber.from(charisAmount),
  );
  const expBookCallback = useExpBookCallback();

  // balance
  const { balance: zodiBalance = '0' } = useZodiBalanceQuery();
  const { balance: charisBalance = BigNumber.from(0) } = useTokenBalanceQuery(
    CHARIS,
    account ?? undefined,
  );

  const { data: weaponMetadata } = useQuery<WeaponType>([
    'weapon',
    String(targetWeapon?.id),
  ]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (weaponMetadata) {
      dispatch(selectWeapon(weaponMetadata));
      // console.log('weaponMetadata changed!!!!', weaponMetadata);
    }
  }, [dispatch, weaponMetadata]);

  const isActive =
    sumOfExp > 0 &&
    Number(zodiBalance) >= 100 &&
    charisBalance.toNumber() >= charisAmount;

  const handleBtnClick = async () => {
    try {
      if (!isActive) return;

      if (zodiApproval === ApprovalState.NOT_APPROVED) {
        await approveZodi();
        return;
      }
      if (charisApproval === ApprovalState.NOT_APPROVED) {
        await approveCharis();
        return;
      }
      await expBookCallback();
    } catch (e) {
      console.error(e);
    }
  };

  const btnText = () => {
    if (zodiApproval === ApprovalState.NOT_APPROVED) {
      return 'Approve ZODI';
    }
    if (charisApproval === ApprovalState.NOT_APPROVED) {
      return 'Approve CHARIS';
    }
    return 'Level Up';
  };

  return (
    <div className={classes.footer}>
      <div className={classes.usedTokenBox}>
        <div className={classes.usedZodi}>
          <img className={classes.tokenIcon} src={ZodiIcon} alt="zodi" />
          <span>100</span> ZODI
        </div>
        <div className={classes.dot}></div>
        <div className={classes.usedCharis}>
          <img className={classes.tokenIcon} src={CharisIcon} alt="charis" />
          <span>{charisAmount}</span> CHARIS
        </div>
      </div>
      <div
        className={classes[isActive ? 'btnPC' : 'btnPC-grey']}
        onClick={handleBtnClick}
      >
        {btnText()}
      </div>
      <div
        className={classes[isActive ? 'btnMobile' : 'btnMobile-grey']}
        onClick={handleBtnClick}
      >
        {btnText()}
      </div>
    </div>
  );
};

export default UsedTokenForLevelUp;
