import DogsWeapon from '../assets/images/weapon/Dog_Weapon.png';
import DragonWeapon from '../assets/images/weapon/Dragon_Weapon.png';
import HenWeapon from '../assets/images/weapon/Hen_Weapon.png';
import HorseWeapon from '../assets/images/weapon/Horse_Weapon.png';
import MonkeyWeapon from '../assets/images/weapon/Monkey_Weapon.png';
import OxWeapon from '../assets/images/weapon/Ox_Weapon.png';
import PigWeapon from '../assets/images/weapon/Pig_Weapon.png';
import RabbitWeapon from '../assets/images/weapon/Rabbit_Weapon.png';
import RatWeapon from '../assets/images/weapon/Rat_Weapon.png';
import SheepWeapon from '../assets/images/weapon/Sheep_Weapon.png';
import SnakeWeapon from '../assets/images/weapon/Snake_Weapon.png';
import TigerWeapon from '../assets/images/weapon/Tiger_Weapon.png';
import EarthElement from '../assets/images/weapon/elements/earth.svg';
import FireElement from '../assets/images/weapon/elements/fire.svg';
import MetalElement from '../assets/images/weapon/elements/metal.svg';
import WaterElement from '../assets/images/weapon/elements/water.svg';
import WoodElement from '../assets/images/weapon/elements/wood.svg';

import { AnimalEnum } from './zodiac';

export const weaponMap = {
  [AnimalEnum.Dog]: {
    img: DogsWeapon,
    name: 'Bone',
  },
  [AnimalEnum.Dragon]: {
    img: DragonWeapon,
    name: 'Fire Ball',
  },
  [AnimalEnum.Hen]: {
    img: HenWeapon,
    name: 'Eggs Bucket',
  },
  [AnimalEnum.Horse]: {
    img: HorseWeapon,
    name: 'Hoof',
  },
  [AnimalEnum.Monkey]: {
    img: MonkeyWeapon,
    name: 'Magic Stick',
  },
  [AnimalEnum.Ox]: {
    img: OxWeapon,
    name: 'Horn',
  },
  [AnimalEnum.Pig]: {
    img: PigWeapon,
    name: 'Pearl Necklace',
  },
  [AnimalEnum.Rabbit]: {
    img: RabbitWeapon,
    name: 'Carrot',
  },
  [AnimalEnum.Rat]: {
    img: RatWeapon,
    name: 'Ninja Star',
  },
  [AnimalEnum.Sheep]: {
    img: SheepWeapon,
    name: 'Shield',
  },
  [AnimalEnum.Snake]: {
    img: SnakeWeapon,
    name: 'Flute',
  },
  [AnimalEnum.Tiger]: {
    img: TigerWeapon,
    name: 'Knuckles',
  },
};

export const weaponNameList = Object.values(weaponMap).map((w) => w.name);

export enum WeaponElement {
  FIRE = 'Fire',
  WATER = 'Water',
  WOOD = 'Wood',
  METAL = 'Metal',
  EARTH = 'Earth',
}

export const weaponElementList = Object.values(WeaponElement);

export const elementImgMap: any = {
  [WeaponElement.FIRE]: FireElement,
  [WeaponElement.WATER]: WaterElement,
  [WeaponElement.WOOD]: WoodElement,
  [WeaponElement.METAL]: MetalElement,
  [WeaponElement.EARTH]: EarthElement,
};
