import { useState } from 'react';

import { getCardPath } from '../lib/cardPath';

import '../scss/CardGallery.scss';

import { ReactComponent as ArrowDark } from '../assets/icons/arrow_dark.svg';

import CardPropertiesGallery from './CardPropertiesGallery';

type CardGalleryProps = {
  animal: string;
};

const CardGallery: React.FC<CardGalleryProps> = ({ animal }) => {
  const [tier, setTier] = useState<string>('Common');
  const [starSign, setStarSign] = useState<string>('Aries');
  const [openProperties, setOpenProperties] = useState<boolean>(false);

  return (
    <div className="card-gallery">
      {tier === 'Legendary' ||
      tier === 'Soul Zodiac' ||
      tier === 'Untouchable' ? (
        <video
          autoPlay
          muted
          loop
          playsInline
          key={getCardPath(tier, animal, starSign)}
        >
          <source src={getCardPath(tier, animal, starSign)} type="video/mp4" />
        </video>
      ) : (
        <img src={getCardPath(tier, animal, starSign)} alt="" />
      )}

      <button onClick={() => setOpenProperties(!openProperties)}>
        {animal}
        <ArrowDark
          style={{
            transform: `${openProperties ? 'rotate(180deg)' : 'rotate(0deg)'}`,
          }}
        />
      </button>

      <div
        className={`properties-wrapper ${
          openProperties ? 'open-properties' : ''
        }`}
      >
        <CardPropertiesGallery
          tier={tier}
          starSign={starSign}
          setTier={setTier}
          setStarSign={setStarSign}
          untouchable={animal === 'Ox'
            ? true
            : animal === 'Tiger'
              ? true
              : false
          }
        />
      </div>
    </div>
  );
};

export default CardGallery;
