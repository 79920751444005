import React from 'react';

import classes from './GradientScrollFog.module.scss';

const GradientScrollFog = ({
  parentRef,
}: {
  parentRef: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <div
      className={classes.gradient}
      onWheel={(e) => {
        if (!parentRef.current) return;
        const currentScroll = parentRef.current.scrollTop;
        const newScroll = currentScroll + e.deltaY;
        parentRef.current.scrollTop = newScroll;
      }}
    ></div>
  );
};

export default GradientScrollFog;
