import '../scss/CardPropertiesTable.scss';

type CardPropertiesProps = {
  title?: string;
  tier: string;
  starSign: string;
  land: string;
  motion: string;
  edge?: string;
  colorBlack?: boolean;
};

const CardPropertiesTable: React.FC<CardPropertiesProps> = ({
  title,
  tier,
  starSign,
  land,
  motion,
  edge,
  colorBlack = false,
}) => {
  return (
    <div className="card-properties-table">
      {title && <p>{title}</p>}
      <table>
        <tbody>
          <tr>
            <th className={colorBlack ? 'color-black' : ''}>Tier:</th>
            <td className={colorBlack ? 'color-black' : ''}>{tier}</td>
          </tr>
          <tr>
            <th className={colorBlack ? 'color-black' : ''}>Star Sign:</th>
            <td className={colorBlack ? 'color-black' : ''}>{starSign}</td>
          </tr>
          <tr>
            <th className={colorBlack ? 'color-black' : ''}>Land:</th>
            <td className={colorBlack ? 'color-black' : ''}>{land}</td>
          </tr>
          <tr>
            <th className={colorBlack ? 'color-black' : ''}>Motion:</th>
            <td className={colorBlack ? 'color-black' : ''}>{motion}</td>
          </tr>
          <tr>
            <th className={colorBlack ? 'color-black' : ''}>Edge:</th>
            <td className={colorBlack ? 'color-black' : ''}>{edge}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CardPropertiesTable;
