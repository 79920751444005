import Button from '../../components/Button';

import { CardType } from '../../types/card';
import { WariningStateType } from '../../types/warning';

import { ReactComponent as Warning } from '../../assets/icons/warning.svg';

type ButtonUpgradeProps = {
  resultCard: CardType | undefined;
  checkError: () => void;
  warningState: WariningStateType;
};

const ButtonUpgrade: React.FC<ButtonUpgradeProps> = ({
  resultCard,
  checkError,
  warningState,
}) => {
  return (
    <div
      className={`upgrade-button-wrapper ${resultCard ? 'margin-short' : ''}`}
    >
      {resultCard ? (
        <Button
          title="Upgrade"
          width="100%"
          height="65px"
          buttonStyle="grey-disable"
        />
      ) : (
        <Button
          title="Upgrade"
          width="100%"
          height="65px"
          onClick={checkError}
        />
      )}

      {warningState.warning && (
        <p className="warning">
          <Warning />
          {warningState.description === 'Length'
            ? 'Please set the number of card packs to pick.'
            : warningState.description === 'Tier'
            ? 'Please check to see if the cards are of the same tier.'
            : warningState.description === 'Legendary'
            ? 'Legendary tiers cannot be upgraded.'
            : warningState.description === 'Soul Zodiac'
            ? 'Soul Zodiac tiers cannot be upgraded.'
            : 'Untouchable tiers cannot be upgraded.'}
        </p>
      )}
    </div>
  );
};

export default ButtonUpgrade;
