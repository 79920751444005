import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as Confirm } from 'assets/icons/confirm.svg';
import { ContractReceipt } from 'ethers';
import { modalClose, modalOpen } from 'store/modal';

import Loading from 'components/Loading';

import { ApprovalState } from 'types/contract';

import { useERC721Contract } from './useContract';
import { useWeb3 } from './useWeb3';

const useApproveForAllNFT = (
  nftAddress: string,
  operator: string,
): [ApprovalState, () => Promise<ContractReceipt | undefined>] => {
  const { account } = useWeb3();
  const contract = useERC721Contract(nftAddress);
  const [isApproved, setIsApproved] = useState(false);
  const dispatch = useDispatch();

  const checkAprroved = useCallback(async () => {
    if (!account) return;
    const isApprovedForAll = await contract.isApprovedForAll(account, operator);
    setIsApproved(isApprovedForAll);
  }, [contract, account, operator]);

  const approveForAll = useCallback(async () => {
    try {
      dispatch(
        modalOpen({
          alertImage: <Loading />,
          title: 'Waiting for Approve NFTs',
          description: `\nConfirm this transaction in your wallet\n\n\n`,
          dimZ: 2500,
        }),
      );
      const tx = await contract.setApprovalForAll(operator, true);
      dispatch(
        modalOpen({
          alertImage: <Loading />,
          title: 'BSC Transaction Submitted',
          description: `\nWaiting for transaction confirmed\n\n\n`,
          dimZ: 2500,
        }),
      );
      const receipt = await tx.wait();
      checkAprroved();

      dispatch(
        modalOpen({
          alertImage: <Confirm />,
          title: 'BSC Transaction Confirmed',
          description: undefined,
          mainButtonTitle: 'Close',
          mainButtonEvent: () => {
            dispatch(modalClose());
          },
          dimZ: 2500,
        }),
      );
      return receipt;
    } catch (e) {
      console.error(e);
      dispatch(
        modalOpen({
          title: 'Alert!',
          description: 'BSC Transaction Error',
          mainButtonTitle: 'OK',
          mainButtonEvent: () => {
            dispatch(modalClose());
          },
          dimZ: 2500,
        }),
      );
    }
  }, [contract, operator, dispatch, checkAprroved]);

  useEffect(() => {
    checkAprroved();
  }, [checkAprroved]);

  return [
    isApproved ? ApprovalState.APPROVED : ApprovalState.NOT_APPROVED,
    approveForAll,
  ];
};

export default useApproveForAllNFT;
