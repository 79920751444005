type VideoTagProps = {
  path: string;
};

const VideoTag: React.FC<VideoTagProps> = ({ path }) => {
  return (
    <video autoPlay muted loop playsInline>
      <source src={path} type="video/mp4" />
    </video>
  );
};

export default VideoTag;
