import { useState } from 'react';

import '../scss/Pagination.scss';

type PaginationProps = {
  current: number;
  perPage: number;
  totalLength: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  modal?: boolean;
};

const Pagination: React.FC<PaginationProps> = ({
  current,
  perPage,
  totalLength,
  setCurrentPage,
  modal = false,
}) => {
  const pages: number[] = [];
  for (let i = 0; i < Math.ceil(totalLength / perPage); i++) pages.push(i);

  const [paginationPage, setPaginationPage] = useState<number>(0);

  const indexOfFirst = paginationPage * 5;
  const indexOfLast = indexOfFirst + 5;

  const currentPaginations = (): number[] => {
    let cur: number[] = pages.slice(indexOfFirst, indexOfLast);

    return cur;
  };

  const prev = (): void => {
    if (paginationPage > 0) setPaginationPage(paginationPage - 1);
  };

  const next = (): void => {
    if (paginationPage < pages.length / 5 - 1)
      setPaginationPage(paginationPage + 1);
  };

  const movedPage = (page: number) => {
    if (modal) {
      const modalTag = document.getElementById('classification-nav');
      modalTag?.scrollIntoView({ block: 'start' });
    } else window.scrollTo(0, 0);

    setCurrentPage(page);
  };

  return (
    <div className="pagination">
      <ul>
        {pages.length > 5 && <li onClick={prev}>Prev</li>}
        {currentPaginations().map((page) => (
          <li
            key={page}
            onClick={() => movedPage(page)}
            className={current === page ? 'current' : ''}
          >
            {page + 1}
          </li>
        ))}
        {pages.length > 5 && <li onClick={next}>Next</li>}
      </ul>
    </div>
  );
};

export default Pagination;
