import Paragraph from '../../components/Paragraph';

import '../../scss/home/AboutUpgrade.scss';

const AboutUpgrade: React.FC = () => {
  return (
    <article className="about-upgrade">
      <Paragraph
        title={`Upgrade Your Way to Assemble\nthe Higher Tier Zodiac!`}
        description={`Zodiac cards are upgradable by achieving specific conditions and merging\nspecific cards with cards. Find out more on My Collection.`}
        buttonTitle={`Go to Upgrade`}
        link={`/myCollection`}
        whiteTitle={true}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      />
      <Paragraph
        title={`Upgrade Your\nWay to Assemble\nthe Higher Tier Zodiac!`}
        description={`Zodiac cards are upgradable by achieving specific\nconditions and merging\nspecific cards with cards. Find out more on\nMy Collection.`}
        buttonTitle={`Go to Upgrade`}
        link={`/myCollection`}
        whiteTitle={true}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      />
      <Paragraph
        title={`Upgrade Your\nWay to Assemble\nthe Higher Tier Zodiac!`}
        description={`Zodiac cards are upgradable\nby achieving specific\nconditions and merging\nspecific cards with cards.\nFind out more on\nMy Collection.`}
        buttonTitle={`Go to Upgrade`}
        link={`/myCollection`}
        whiteTitle={true}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      />
    </article>
  );
};

export default AboutUpgrade;
