const tierPath: any = {
  common: `${process.env.PUBLIC_URL}/12kinds_common`,
  rare: `${process.env.PUBLIC_URL}/12kinds_rare`,
  legendary: `${process.env.PUBLIC_URL}/12kinds_legendary`,
  soulZodiac: `${process.env.PUBLIC_URL}/12kinds_soulzodiac`,
  untouchable: `${process.env.PUBLIC_URL}/12kinds_untouchable`,
};

const commonPath: any = {
  rat: `01_common_rat`,
  ox: `02_common_ox`,
  tiger: `03_common_tiger`,
  rabbit: `04_common_rabbit`,
  dragon: `05_common_dragon`,
  snake: `06_common_snake`,
  horse: `07_common_horse`,
  sheep: `08_common_sheep`,
  monkey: `09_common_monkey`,
  hen: `10_common_hen`,
  dog: `11_common_dog`,
  pig: `12_common_pig`,
};

const rarePath: any = {
  rat: `01_rare_rat`,
  ox: `02_rare_ox`,
  tiger: `03_rare_tiger`,
  rabbit: `04_rare_rabbit`,
  dragon: `05_rare_dragon`,
  snake: `06_rare_snake`,
  horse: `07_rare_horse`,
  sheep: `08_rare_sheep`,
  monkey: `09_rare_monkey`,
  hen: `10_rare_hen`,
  dog: `11_rare_dog`,
  pig: `12_rare_pig`,
};

const legendaryPath: any = {
  rat: `01_legendary_rat`,
  ox: `02_legendary_ox`,
  tiger: `03_legendary_tiger`,
  rabbit: `04_legendary_rabbit`,
  dragon: `05_legendary_dragon`,
  snake: `06_legendary_snake`,
  horse: `07_legendary_horse`,
  sheep: `08_legendary_sheep`,
  monkey: `09_legendary_monkey`,
  hen: `10_legendary_hen`,
  dog: `11_legendary_dog`,
  pig: `12_legendary_pig`,
};

const soulZodiacPath: any = {
  rat: `01_soulzodiac_rat`,
  ox: `02_soulzodiac_ox`,
  tiger: `03_soulzodiac_tiger`,
  rabbit: `04_soulzodiac_rabbit`,
  dragon: `05_soulzodiac_dragon`,
  snake: `06_soulzodiac_snake`,
  horse: `07_soulzodiac_horse`,
  sheep: `08_soulzodiac_sheep`,
  monkey: `09_soulzodiac_monkey`,
  hen: `10_soulzodiac_hen`,
  dog: `11_soulzodiac_dog`,
  pig: `12_soulzodiac_pig`,
};

const untouchablePath: any = {
  ox: `02_untouchable_whiteox`,
  tiger: `03_untouchable_blacktiger`,
};

const starSignPath: any = {
  aquarius: `aquarius`,
  pisces: `pisces`,
  aries: `aries`,
  taurus: `taurus`,
  gemini: `gemini`,
  cancer: `cancer`,
  leo: `leo`,
  virgo: `virgo`,
  libra: `libra`,
  scorpio: `scorpio`,
  sagittarius: `sagittarius`,
  capricorn: `capricorn`,
};

export const getCardPath = (
  tier: string,
  animal: string,
  starSign: string,
): any => {
  let resTier: string, resAnimal: string, resStarSign: string, fileType: string;

  if (tier === 'Common') {
    resTier = tierPath.common;
    fileType = 'png';
  } else if (tier === 'Rare') {
    resTier = tierPath.rare;
    fileType = 'png';
  } else if (tier === 'Legendary') {
    resTier = tierPath.legendary;
    fileType = 'mp4';
  } else if (tier === 'Soul Zodiac') {
    resTier = tierPath.soulZodiac;
    fileType = 'mp4';
  } else {
    resTier = tierPath.untouchable;
    fileType = 'mp4';
  }

  if (animal === 'Rat') {
    if (tier === 'Common') resAnimal = commonPath.rat;
    else if (tier === 'Rare') resAnimal = rarePath.rat;
    else if (tier === 'Legendary') resAnimal = legendaryPath.rat;
    else if (tier === 'Soul Zodiac') resAnimal = soulZodiacPath.rat;
    else resAnimal = untouchablePath.rat;
  } else if (animal === 'Ox') {
    if (tier === 'Common') resAnimal = commonPath.ox;
    else if (tier === 'Rare') resAnimal = rarePath.ox;
    else if (tier === 'Legendary') resAnimal = legendaryPath.ox;
    else if (tier === 'Soul Zodiac') resAnimal = soulZodiacPath.ox;
    else resAnimal = untouchablePath.ox;
  } else if (animal === 'Tiger') {
    if (tier === 'Common') resAnimal = commonPath.tiger;
    else if (tier === 'Rare') resAnimal = rarePath.tiger;
    else if (tier === 'Legendary') resAnimal = legendaryPath.tiger;
    else if (tier === 'Soul Zodiac') resAnimal = soulZodiacPath.tiger;
    else resAnimal = untouchablePath.tiger;
  } else if (animal === 'Rabbit') {
    if (tier === 'Common') resAnimal = commonPath.rabbit;
    else if (tier === 'Rare') resAnimal = rarePath.rabbit;
    else if (tier === 'Legendary') resAnimal = legendaryPath.rabbit;
    else if (tier === 'Soul Zodiac') resAnimal = soulZodiacPath.rabbit;
    else resAnimal = untouchablePath.rabbit;
  } else if (animal === 'Dragon') {
    if (tier === 'Common') resAnimal = commonPath.dragon;
    else if (tier === 'Rare') resAnimal = rarePath.dragon;
    else if (tier === 'Legendary') resAnimal = legendaryPath.dragon;
    else if (tier === 'Soul Zodiac') resAnimal = soulZodiacPath.dragon;
    else resAnimal = untouchablePath.dragon;
  } else if (animal === 'Snake') {
    if (tier === 'Common') resAnimal = commonPath.snake;
    else if (tier === 'Rare') resAnimal = rarePath.snake;
    else if (tier === 'Legendary') resAnimal = legendaryPath.snake;
    else if (tier === 'Soul Zodiac') resAnimal = soulZodiacPath.snake;
    else resAnimal = untouchablePath.snake;
  } else if (animal === 'Horse') {
    if (tier === 'Common') resAnimal = commonPath.horse;
    else if (tier === 'Rare') resAnimal = rarePath.horse;
    else if (tier === 'Legendary') resAnimal = legendaryPath.horse;
    else if (tier === 'Soul Zodiac') resAnimal = soulZodiacPath.horse;
    else resAnimal = untouchablePath.horse;
  } else if (animal === 'Sheep') {
    if (tier === 'Common') resAnimal = commonPath.sheep;
    else if (tier === 'Rare') resAnimal = rarePath.sheep;
    else if (tier === 'Legendary') resAnimal = legendaryPath.sheep;
    else if (tier === 'Soul Zodiac') resAnimal = soulZodiacPath.sheep;
    else resAnimal = untouchablePath.sheep;
  } else if (animal === 'Monkey') {
    if (tier === 'Common') resAnimal = commonPath.monkey;
    else if (tier === 'Rare') resAnimal = rarePath.monkey;
    else if (tier === 'Legendary') resAnimal = legendaryPath.monkey;
    else if (tier === 'Soul Zodiac') resAnimal = soulZodiacPath.monkey;
    else resAnimal = untouchablePath.monkey;
  } else if (animal === 'Hen') {
    if (tier === 'Common') resAnimal = commonPath.hen;
    else if (tier === 'Rare') resAnimal = rarePath.hen;
    else if (tier === 'Legendary') resAnimal = legendaryPath.hen;
    else if (tier === 'Soul Zodiac') resAnimal = soulZodiacPath.hen;
    else resAnimal = untouchablePath.hen;
  } else if (animal === 'Dog') {
    if (tier === 'Common') resAnimal = commonPath.dog;
    else if (tier === 'Rare') resAnimal = rarePath.dog;
    else if (tier === 'Legendary') resAnimal = legendaryPath.dog;
    else if (tier === 'Soul Zodiac') resAnimal = soulZodiacPath.dog;
    else resAnimal = untouchablePath.dog;
  } else {
    if (tier === 'Common') resAnimal = commonPath.pig;
    else if (tier === 'Rare') resAnimal = rarePath.pig;
    else if (tier === 'Legendary') resAnimal = legendaryPath.pig;
    else if (tier === 'Soul Zodiac') resAnimal = soulZodiacPath.pig;
    else resAnimal = untouchablePath.pig;
  }

  if (starSign === 'Aquarius') resStarSign = starSignPath.aquarius;
  else if (starSign === 'Pisces') resStarSign = starSignPath.pisces;
  else if (starSign === 'Aries') resStarSign = starSignPath.aries;
  else if (starSign === 'Taurus') resStarSign = starSignPath.taurus;
  else if (starSign === 'Gemini') resStarSign = starSignPath.gemini;
  else if (starSign === 'Cancer') resStarSign = starSignPath.cancer;
  else if (starSign === 'Leo') resStarSign = starSignPath.leo;
  else if (starSign === 'Virgo') resStarSign = starSignPath.virgo;
  else if (starSign === 'Libra') resStarSign = starSignPath.libra;
  else if (starSign === 'Scorpio') resStarSign = starSignPath.scorpio;
  else if (starSign === 'Sagittarius') resStarSign = starSignPath.sagittarius;
  else resStarSign = starSignPath.capricorn;

  return `${resTier}/${resAnimal}/${resAnimal}_${resStarSign}.${fileType}`;
};
