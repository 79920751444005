import classes from './MultiProgressBar.module.scss';

const MultiProgressBar = ({
  curr,
  next,
  style,
}: {
  curr: number;
  next: number;
  style: any;
}) => {
  return (
    <div className={classes.container} style={style}>
      {curr >= 100 ? (
        <div className={classes.full}></div>
      ) : (
        <>
          <div
            style={{
              width: `${curr}%`,
            }}
            className={classes.curr}
          ></div>
          <div
            style={{
              width: `${next >= 100 ? 100 : next}%`,
            }}
            className={classes.next}
          ></div>
        </>
      )}
    </div>
  );
};

export default MultiProgressBar;
