import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import axios from 'axios';

import Button from '../components/Button';

import useClassification from '../hooks/useClassification';
import useZodiCollection from '../hooks/useZodiCollection';
import useZodiCustomize from '../hooks/useZodiCustomize';

import { CardType } from '../types/card';

import { MyPackState, setMyPack } from '../store/myPack';

import CollectionList from './CollectionList';
import ZodiacCollectionNav from './ZodiacCollectionNav';

let baseURI: string = '';
const tempArr: Array<CardType> = [];
const defaultPack = {
  checkedLength: 0,
  pack: tempArr,
};

export default function ZodiacCollection({ active }: { active: boolean }) {
  const dispatch = useDispatch();

  const classification = useClassification();
  const { getTokenIDList } = useZodiCustomize();
  const { getBaseUriFromContract } = useZodiCollection();

  const [collection, setCollection] = useState<MyPackState>(defaultPack);
  const [collectionLoading, setCollectionLoading] = useState<boolean>(true);

  useEffect(() => {
    if (active) {
      getTokenIDs();
      getBaseUriFromContract().then((res) => {
        baseURI = res;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useEffect(() => {
    dispatch(setMyPack(collection));
  }, [collection, dispatch]);

  const getTokenIDs = async () => {
    try {
      const ids = await getTokenIDList();
      getUserCollection(ids);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserCollection = async (idList: Array<string>) => {
    try {
      const arr: Array<CardType> = [];
      for (const id of idList) {
        try {
          const res = await axios.get(`${baseURI}${id}`);
          if (
            res.data.attributes[2].value === 'Common' ||
            res.data.attributes[2].value === 'Rare'
          ) {
            arr.push({
              id: res.data.id,
              tier: res.data.attributes[2].value,
              animal: res.data.name,
              starSign: res.data.attributes[0].value,
              image: res.data.image,
              checked: false,
            });
          } else {
            arr.push({
              id: res.data.id,
              tier: res.data.attributes[2].value,
              animal: res.data.name,
              starSign: res.data.attributes[0].value,
              image: res.data.animation_url,
              checked: false,
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
      setCollection({
        checkedLength: 0,
        pack: arr,
      });

      setCollectionLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="nav-wrapper">
        <ZodiacCollectionNav classification={classification} />
        {collectionLoading ? (
          <div className="zodiac-upgrade-btn">
            <Button
              title="Upgrade"
              width="100%"
              height="74px"
              buttonStyle="grey-disable"
            />
          </div>
        ) : (
          <Link to="/upgrade">
            <div className="zodiac-upgrade-btn">
              <Button
                title={'Upgrade'}
                width="100%"
                height="74px"
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                }
              />
            </div>
          </Link>
        )}
      </div>

      <CollectionList
        myPack={collection}
        classificationTier={classification.classificationTier}
        animalOptiopn={classification.selectedZodiac}
        starSignOption={classification.selectedStarSign}
        collectionLoading={collectionLoading}
      />
    </>
  );
}
