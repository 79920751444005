import { useMemo, useState } from 'react';

import WeaponCollection from 'containers/WeaponCollection';
import ZodiacCollection from 'containers/ZodiacCollection';

import CategoryTab from 'components/CategoryTab';
import NonConnect from 'components/NonConnect';
import Paragraph from 'components/Paragraph';

import useCleanUpFlow from 'hooks/useCleanUpFlow';
import { useWeb3 } from 'hooks/useWeb3';

import 'scss/MyCollection.scss';

const MyCollection: React.FC = () => {
  useCleanUpFlow();
  const { active } = useWeb3();
  const [category, setCategory] = useState('Zodiac');

  const categories = useMemo(
    () => [
      {
        key: 'Zodiac',
        title: 'KINDs',
      },
      {
        key: 'Weapon',
        title: 'Weapons',
      },
    ],
    [],
  );

  return (
    <main className="my-collection">
      {active ? (
        <>
          <Paragraph
            title="Your Collection"
            description={
              'Connect Your Wallet and Collect Soul Zodiac 12KINDs and Soul Weapons.\nPrepare to take over Planet Trunico'
            }
          />
          <Paragraph
            title="Your Collection"
            description={
              'Connect Your Wallet\nand Collect Soul Zodiac 12KINDs\nand Soul Weapons.\nPrepare to take over Planet Trunico'
            }
          />
          <div className="category-wrapper pc">
            <CategoryTab
              categories={categories}
              selected={category}
              onChangeCategory={setCategory}
            />
          </div>
          <div className="category-wrapper mobile">
            <CategoryTab
              categories={categories}
              selected={category}
              onChangeCategory={setCategory}
            />
          </div>
          <div className="bar" />
          {category === 'Zodiac' ? (
            <ZodiacCollection active={active} />
          ) : (
            <WeaponCollection active={active} />
          )}
        </>
      ) : (
        <>
          <NonConnect
            title={'Your Collection'}
            description={
              'Connect Your Wallet and Collect Soul Zodiac 12KINDs and Soul Weapons.\nPrepare to take over Planet Trunico'
            }
            buttonTitle={'Connect Wallet'}
          />
          <NonConnect
            title={'Your Collection'}
            description={
              'Connect Your Wallet\nand Collect Soul Zodiac 12KINDs\nand Soul Weapons.\nPrepare to take over Planet Trunico'
            }
            buttonTitle={'Connect Wallet'}
          />
        </>
      )}
    </main>
  );
};

export default MyCollection;
