import { useQuery } from 'react-query';

import useZodium from 'hooks/useZodium';

const useZodiBalanceQuery = () => {
  const { getBalanceOf } = useZodium();

  const { data, isLoading } = useQuery(['zodiBalance'], getBalanceOf, {
    refetchInterval: 1000 * 60,
  });

  return { balance: data, isLoading };
};

export default useZodiBalanceQuery;
