import React from 'react';

import { ReactComponent as ButtonXCircle } from 'assets/icons/close-btn-circle.svg';
import { ReactComponent as ButtonX } from 'assets/icons/x.svg';

import Dim from 'components/Dim';

import classes from './ModalWeaponUpgradeWrapper.module.scss';

import UsedTokenForLevelUp from './UsedTokenForLevelUp';
import UsedTokenForWeaponUpgrade from './UsedTokenForWeaponUpgrade';

const ModalWeaponUpgradeWrapper = ({
  type,
  left,
  right,
  onClose,
}: {
  type: 'levelUP' | 'upgrade';
  left: React.ReactNode;
  right: React.ReactNode;
  onClose: () => void;
}) => {
  return (
    <>
      <div className={classes.weaponUpgradeModalContainer}>
        <div className={classes.mobileContainer}>
          <div className={classes.header}>
            <div className={classes.dummy}></div>
            <div className={classes.title}>
              {type === 'levelUP' ? 'Weapon Level-up' : 'Weapon Upgrade'}
            </div>
            <figure className={classes.closePC} onClick={onClose}>
              <ButtonX />
            </figure>
            <figure className={classes.closeMobile} onClick={onClose}>
              <ButtonXCircle />
            </figure>
          </div>
          {type === 'levelUP' && (
            <div className={classes.desc}>
              When it is available to rank up, the weapon has reached its
              maximum EXP.
            </div>
          )}
          <div className={classes.main}>
            <div className={classes.left}>{left}</div>
            <div className={classes.right}>{right}</div>
          </div>
        </div>
        {type === 'levelUP' && <UsedTokenForLevelUp />}
        {type === 'upgrade' && <UsedTokenForWeaponUpgrade />}
      </div>
      <Dim zIndex={1600} />
    </>
  );
};

export default ModalWeaponUpgradeWrapper;
