import { createAction, handleActions } from 'redux-actions';

import { CardType } from '../types/card';

const CONCAT_MY_PACK = 'myPack/CONCAT_MY_PACK' as const;
const FILTER_MY_PACK = 'myPack/FILTER_MY_PACK' as const;
const SET_MY_PACK = 'myPack/SET_MY_PACK' as const;
const SELECT_CARD = 'myPack/SELECT_CARD' as const;
const UNSELECT_CARD = 'myPack/UNSELECT_CARD' as const;
const SELECT_CLEARING = 'myPack/SELECT_CLEARING' as const;

export const concatMyPack = createAction(CONCAT_MY_PACK);
export const filterMyPack = createAction(FILTER_MY_PACK);
export const setMyPack = createAction(SET_MY_PACK);
export const selectCard = createAction(SELECT_CARD);
export const unselectCard = createAction(UNSELECT_CARD);
export const selectClearing = createAction(SELECT_CLEARING);

export type MyPackAction =
  | ReturnType<typeof concatMyPack>
  | ReturnType<typeof filterMyPack>
  | ReturnType<typeof setMyPack>
  | ReturnType<typeof selectCard>
  | ReturnType<typeof unselectCard>
  | ReturnType<typeof selectClearing>;

export type MyPackState = {
  checkedLength: number;
  pack: CardType[];
};

const initialState: MyPackState = {
  checkedLength: 0,
  pack: [
    // {
    //     id: "1",
    //     tier: "Untouchable",
    //     animal: "Ox",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "1q",
    //     tier: "Untouchable",
    //     animal: "Ox",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "1w",
    //     tier: "Untouchable",
    //     animal: "Ox",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "1e",
    //     tier: "Untouchable",
    //     animal: "Ox",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "1r",
    //     tier: "Untouchable",
    //     animal: "Ox",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "1t",
    //     tier: "Untouchable",
    //     animal: "Ox",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "2",
    //     tier: "Legendary",
    //     animal: "Dragon",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "2q",
    //     tier: "Legendary",
    //     animal: "Dragon",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "2w",
    //     tier: "Legendary",
    //     animal: "Dragon",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "2e",
    //     tier: "Legendary",
    //     animal: "Dragon",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "2r",
    //     tier: "Legendary",
    //     animal: "Dragon",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "2t",
    //     tier: "Legendary",
    //     animal: "Dragon",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "3",
    //     tier: "Rare",
    //     animal: "Tiger",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "3q",
    //     tier: "Rare",
    //     animal: "Tiger",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "3w",
    //     tier: "Rare",
    //     animal: "Tiger",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "3e",
    //     tier: "Rare",
    //     animal: "Tiger",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "3r",
    //     tier: "Rare",
    //     animal: "Tiger",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "3t",
    //     tier: "Rare",
    //     animal: "Tiger",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "4",
    //     tier: "Common",
    //     animal: "Dog",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "4q",
    //     tier: "Common",
    //     animal: "Dog",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "4w",
    //     tier: "Common",
    //     animal: "Dog",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "4e",
    //     tier: "Common",
    //     animal: "Dog",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "4r",
    //     tier: "Common",
    //     animal: "Dog",
    //     starSign: "Gemini",
    //     checked: false,
    // },
    // {
    //     id: "4t",
    //     tier: "Common",
    //     animal: "Dog",
    //     starSign: "Gemini",
    //     checked: false,
    // },
  ],
};

const myPack = handleActions(
  {
    [CONCAT_MY_PACK]: (state: MyPackState, action: MyPackAction) => ({
      ...state,
      pack: state.pack.concat(action.payload),
    }),
    [FILTER_MY_PACK]: (state: MyPackState, action: MyPackAction) => ({
      ...state,
      pack: state.pack.filter((card) => card.id !== action.payload.id),
    }),
    [SET_MY_PACK]: (state: MyPackState, action: MyPackAction) => ({
      ...state,
      ...action.payload,
    }),
    [SELECT_CARD]: (state: MyPackState, action: MyPackAction) => ({
      ...state,
      checkedLength: state.checkedLength + 1,
      pack: state.pack.map((card) =>
        card.id === action.payload ? { ...card, checked: true } : card,
      ),
    }),
    [UNSELECT_CARD]: (state: MyPackState, action: MyPackAction) => ({
      ...state,
      checkedLength: state.checkedLength - 1,
      pack: state.pack.map((card) =>
        card.id === action.payload ? { ...card, checked: false } : card,
      ),
    }),
    [SELECT_CLEARING]: (state: MyPackState) => ({
      ...state,
      checkedLength: 0,
      pack: state.pack.map((card) => ({ ...card, checked: false })),
    }),
  },
  initialState,
);

export default myPack;
