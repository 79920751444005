import { useState } from 'react';

import 'scss/CategoryTab.scss';

type CategoryTabProp = {
  categories: {
    key: string;
    title: string;
  }[];
  selected?: string;
  onChangeCategory: (active: string) => void;
};

export default function CategoryTab({
  categories,
  selected,
  onChangeCategory,
}: CategoryTabProp) {
  const [activeCategory, setActiveCategory] = useState(
    selected ?? categories[0].key,
  );
  const handleCategoryClick = (categoryName: string) => {
    setActiveCategory(categoryName);
    onChangeCategory(categoryName);
  };
  return (
    <div className="category-tab">
      {categories.map((category) => (
        <div
          key={category.key}
          className={
            activeCategory === category.key
              ? `${category.key} active`
              : `${category.key}`
          }
          onClick={() => handleCategoryClick(category.key)}
        >
          {category.title}
        </div>
      ))}
    </div>
  );
}
