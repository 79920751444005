import { useCallback, useEffect, useState } from 'react';

import { BigNumber } from 'ethers';

import { useERC20Contract } from './useContract';

const useTokenAllowance = (
  tokenAddress: string,
  owner?: string,
  spender?: string,
): [BigNumber, () => void] => {
  const contract = useERC20Contract(tokenAddress);
  const [allowance, setAllowance] = useState(BigNumber.from(0));

  const getAllowance = useCallback(async () => {
    if (!owner || !spender) return;
    const allowance = await contract.allowance(owner, spender);
    setAllowance(allowance);
  }, [contract, owner, spender]);

  useEffect(() => {
    getAllowance();
  }, [getAllowance]);

  return [allowance, getAllowance];
};

export default useTokenAllowance;
