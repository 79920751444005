import { WeaponClassificationRarity } from '../hooks/useWeaponClassification';

import '../scss/ClassificationTier.scss';

import Button from './Button';

type ClassificationRarityProps = {
  classificationRarity: WeaponClassificationRarity;
  selectRarity: (tier: any) => void;
  forModal?: boolean;
};

function repeatStar(num: number): string {
  let star = '';
  for (let i = 0; i < num; i++) {
    star += '★';
  }

  return star;
}

const ClassificationRarity: React.FC<ClassificationRarityProps> = ({
  classificationRarity,
  selectRarity,
  forModal,
}) => {
  return (
    <section
      className="classification-tier-wrapper"
      // style={{ marginRight: '10px' }}
    >
      <h3>Rarity</h3>
      <div className="classification-tier">
        {classificationRarity.map((c) => (
          <Button
            key={c.rarity}
            title={repeatStar(c.rarity)}
            buttonStyle={`${
              forModal
                ? c.checked
                  ? 'orange-light-pill'
                  : 'grey-round-border'
                : c.checked
                ? 'grey-round-border checked'
                : 'grey-round-border'
            }
            `}
            width="unset"
            height="44px"
            onClick={() => selectRarity(c.rarity)}
          />
        ))}
      </div>
    </section>
  );
};

export default ClassificationRarity;
