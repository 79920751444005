import { weaponMap } from '../lib/weapon';

export default function Weapons() {
  return (
    <div className="weapon-container">
      {Object.values(weaponMap).map((weapon) => (
        <div key={weapon.name} className="weapon-box">
          <div className="weapon-img">
            <img src={weapon.img} alt={weapon.name} />
          </div>
          <div className="weapon-name">{weapon.name}</div>
        </div>
      ))}
    </div>
  );
}
