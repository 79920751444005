const accountString = (
  account: string | null | undefined,
  short: boolean,
): string => {
  if (account) {
    if (short) {
      const accountHead = account.substr(0, 7);
      const accountTail = account.substr(account.length - 6, account.length);
      return `${accountHead}...${accountTail}`;
    } else return account;
  } else {
    return 'No Wallet Address';
  }
};

export default accountString;
