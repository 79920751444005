import { expBookServerURL } from 'api';
import axios from 'axios';

export type ExpBookApiRequest = {
  address: string;
  target_token_id: number;
  use_exp: { amount: number; unit: number }[];
};

export type ExpBookAbiResponse = {
  hash: string;
  r: string;
  s: string;
  v: string;
  signature: string;
  tx_pack: {
    exp_amount: number;
    expired_at: number;
    sender: string;
    token_id: number;
    unique_key: string;
  };
};

const getExpBookNotarizedData = async (data: ExpBookApiRequest) => {
  console.log('request data', data);
  const { data: responseData } = await axios.post<ExpBookAbiResponse>(
    `${expBookServerURL}/delegater/notarize`,
    data,
  );
  return responseData;
};

export default getExpBookNotarizedData;
