import { useCallback } from 'react';

import { ethers } from 'ethers';

import { use12WeaponsContract } from './useContract';

const useWeaponCollection = () => {
  const weaponContract = use12WeaponsContract();

  const getWeaponTokenURI = useCallback(
    async (tokenID: string) => {
      try {
        let uri = await weaponContract.tokenURI(_typecasting(Number(tokenID)));
        return uri;
      } catch (error) {
        throw error;
      }
    },
    [weaponContract],
  );

  return {
    getWeaponTokenURI,
  };
};

//NOTE x util
const _typecasting = (v: number) => {
  return ethers.BigNumber.from(v);
};

export { useWeaponCollection };
