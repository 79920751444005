import { useSelector } from 'react-redux';

import useScrollLock from '../hooks/useScrollLock';

import '../scss/Modal.scss';

import { RootState } from '../store';

import Button from './Button';
import Dim from './Dim';

const Modal: React.FC = () => {
  useScrollLock();
  const modalState = useSelector((state: RootState) => state.modal);

  return (
    <>
      <dialog open>
        {modalState.alertImage !== undefined && modalState.alertImage}

        <h3>{modalState.title}</h3>

        {modalState.description !== undefined && (
          <p>{modalState.description}</p>
        )}

        {modalState.mainButtonTitle !== undefined && (
          <Button
            title={modalState.mainButtonTitle}
            buttonStyle={'orange-gradient'}
            width="100%"
            onClick={modalState.mainButtonEvent}
          />
        )}

        {modalState.subButtonTitle !== undefined && (
          <Button
            title={modalState.subButtonTitle}
            buttonStyle={'grey'}
            width="100%"
            onClick={modalState.subButtonEvent}
          />
        )}
      </dialog>
      <Dim zIndex={modalState.dimZ} dimClose={modalState.dimClose} />
    </>
  );
};

export default Modal;
