import { combineReducers } from 'redux';

import connectWallet from './connectWallet';
import connected from './connected';
import drawed from './drawed';
import levelupReducer from './levelup';
import modal from './modal';
import modalSoulZodiac from './modalSoulZodiac';
import modalUpgrade from './modalUpgrade';
import myPack from './myPack';
import newPack from './newPack';
import newWeapon from './newWeapon';
import userWeapon from './userWeapon';
import walletAddress from './walletAddress';
import weaponUpgradeReducer from './weaponUpgrade';

const rootReducer = combineReducers({
  connectWallet,
  modal,
  modalUpgrade,
  connected,
  myPack,
  newPack,
  walletAddress,
  modalSoulZodiac,
  drawed,
  newWeapon,
  levelupReducer,
  weaponUpgradeReducer,
  userWeapon,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
