import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Button from '../components/Button';
import Card from '../components/Card';
import Loading from '../components/Loading';
import Pagination from '../components/Pagination';

import { ZodiacClassficationTier } from '../hooks/useClassification';

import { tierArrayUntouchable } from '../lib/zodiac';

import { CardType } from '../types/card';

import '../scss/CollectionList.scss';

import { ReactComponent as ArrowDark } from '../assets/icons/arrow_dark.svg';
import { MyPackState } from '../store/myPack';

type CollectionListProps = {
  mint?: boolean;
  myPack: MyPackState;
  setUpgradePackTmp?:
    | React.Dispatch<React.SetStateAction<MyPackState>>
    | undefined;
  classificationTier: ZodiacClassficationTier;
  animalOptiopn: string;
  starSignOption: string;
  modal?: boolean;
  collectionLoading?: boolean;
};

const CollectionList: React.FC<CollectionListProps> = ({
  mint = true,
  myPack,
  setUpgradePackTmp = undefined,
  classificationTier,
  animalOptiopn,
  starSignOption,
  modal = false,
  collectionLoading = true,
}) => {
  const [sortBy, setSortBy] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const filteredPack = myPack.pack
    .filter((card) => {
      let filteredTier: boolean = false,
        filteredAnimal: boolean = false,
        filteredStarSign: boolean = false;

      if (
        classificationTier.find((el) => el.checked && el.tier === card.tier)
      ) {
        filteredTier = true;
      }

      if (animalOptiopn === 'All' || card.animal === animalOptiopn)
        filteredAnimal = true;

      if (starSignOption === 'All' || card.starSign === starSignOption)
        filteredStarSign = true;

      return filteredTier && filteredAnimal && filteredStarSign;
    })
    .sort((a, b) => {
      if (sortBy)
        return (
          tierArrayUntouchable.indexOf(b.tier) -
          tierArrayUntouchable.indexOf(a.tier)
        );
      else
        return (
          tierArrayUntouchable.indexOf(a.tier) -
          tierArrayUntouchable.indexOf(b.tier)
        );
    });

  const indexOfFirst = currentPage * 12;
  const indexOfLast = indexOfFirst + 12;

  const currentCards = (): CardType[] => {
    let cur: CardType[] = filteredPack.slice(indexOfFirst, indexOfLast);

    return cur;
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [classificationTier, animalOptiopn, starSignOption]);

  return (
    <section className="collection-list-wrapper">
      <h2>{filteredPack.length} KINDs</h2>
      <p>
        sort by.{' '}
        <span onClick={() => setSortBy(!sortBy)}>
          {sortBy ? 'Highest Tier' : 'Lowest Tier'}
          <ArrowDark />
        </span>
      </p>

      {collectionLoading ? (
        <Loading />
      ) : filteredPack.length ? (
        <div className="collection-list">
          {currentCards().map((card) => (
            <Card
              id={card.id}
              tier={card.tier}
              animal={card.animal}
              starSign={card.starSign}
              image={card.image}
              mint={mint}
              checked={card.checked}
              myPack={myPack}
              setUpgradePackTmp={setUpgradePackTmp}
              key={card.id}
            />
          ))}
          {filteredPack.length > 12 && (
            <Pagination
              current={currentPage}
              perPage={12}
              totalLength={filteredPack.length}
              setCurrentPage={setCurrentPage}
              modal={modal}
            />
          )}
        </div>
      ) : (
        <div className="no-card">
          <p>
            To open Zodiac Packs, <br className="pad" />
            you need to have ZODI ready <br className="mobile" />
            in your wallet.
            <br />
            One Zodiac Pack includes 3 Zodiac <br className="pad" />
            characters for 100 ZODI
          </p>
          <Link to="/drawZodiac">
            <Button
              title="Draw Zodiac"
              buttonStyle="orange-bright"
              width="260px"
              height="60px"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }}
            />
          </Link>
        </div>
      )}
    </section>
  );
};

export default CollectionList;
