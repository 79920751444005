import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store';

import DrawWeaponContainer from 'containers/draw/weapon/DrawWeaponContainer';
import WeaponDrawResult from 'containers/draw/weapon/WeaponDrawResult';

import NonConnect from 'components/NonConnect';

import useCleanUpFlow from 'hooks/useCleanUpFlow';
import { useWeapon } from 'hooks/useWeapon';
import { useWeb3 } from 'hooks/useWeb3';
import useZodium from 'hooks/useZodium';

const DrawWeapon: React.FC = () => {
  useCleanUpFlow();
  const { active } = useWeb3();
  const { getBalanceOf } = useZodium();
  const { getWeaponDrawPayRate } = useWeapon();

  const [balance, setBalance] = useState('0');
  const [weaponPayRate, setWeaponPayRate] = useState<number>(0);
  const drawed = useSelector((state: RootState) => state.drawed.drawed);
  useEffect(() => {
    if (active) {
      getBalanceOf().then((res) => {
        setBalance(res);
      });

      getWeaponDrawPayRate()
        .then((res) => {
          setWeaponPayRate(res);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, drawed]);

  return (
    <main className="draw-weapon">
      {active ? (
        drawed ? (
          <WeaponDrawResult />
        ) : (
          <DrawWeaponContainer
            balance={balance}
            weaponPayRate={weaponPayRate}
            drawed={drawed}
          />
        )
      ) : (
        <>
          <NonConnect
            title="Draw 12Weapons NFT"
            description={`There are 12 soul weapons for 12 each Zodiac Animals.\nCraft a Soul Weapons that makes the Zodiac Animals even more powerful\nRequires 100 ZODI to draw 1 weapon.`}
            buttonTitle="Connect Wallet"
          />
          <NonConnect
            title="Draw 12Weapons NFT"
            description={`There are 12 soul weapons for\n12 each Zodiac Animals.\nCraft a Soul Weapons that makes the\nZodiac Animals even more powerful\nRequires 100 ZODI to draw 1 weapon.`}
            buttonTitle="Connect Wallet"
          />
        </>
      )}
    </main>
  );
};

export default DrawWeapon;
