import { useDispatch, useSelector } from 'react-redux';

import Button from '../components/Button';
import Card from '../components/Card';
import Dim from '../components/Dim';

import useScrollLock from '../hooks/useScrollLock';

import '../scss/ModalSoulZodiac.scss';

import { RootState } from '../store';
import { modalSoulZodiacClose } from '../store/modalSoulZodiac';

const ModalSoulZodiac: React.FC = () => {
  useScrollLock();
  const dispatch = useDispatch();

  const soulZodiacCard = useSelector(
    (state: RootState) => state.modalSoulZodiac.card,
  );

  return (
    <>
      <section className="modal-soul-zodiac">
        <h4>Congrats!</h4>
        <p>
          You just got Legendary Tier! And, you also made Soul Zodiac
          <br />
          because it matches your date of births!
          <br />
          Check ‘My Collection’
        </p>

        <Card
          id={soulZodiacCard.id}
          tier={soulZodiacCard.tier}
          animal={soulZodiacCard.animal}
          starSign={soulZodiacCard.starSign}
          image={soulZodiacCard.image}
          key={soulZodiacCard.id}
        />

        <Button
          title="Close"
          buttonStyle="grey"
          width="280px"
          height="50px"
          onClick={() => dispatch(modalSoulZodiacClose())}
        />
      </section>
      <Dim zIndex={1400} />
    </>
  );
};

export default ModalSoulZodiac;
