import CardPropertiesTable from '../../components/CardPropertiesTable';
import VideoTag from '../../components/VideoTag';

import { getCardPath } from '../../lib/cardPath';

import '../../scss/home/AboutSoulZodiac.scss';

import { ReactComponent as MyZodiac } from '../../assets/icons/my_zodiac.svg';

const AboutSoulZodiac: React.FC = () => {
  return (
    <section className="about-soul-zodiac">
      <div>
        <VideoTag path={getCardPath('Soul Zodiac', 'Tiger', 'Virgo')} />
        <div className="soul-zodiac-properties">
          <h1>Tier 4 : Soul Zodiac</h1>
          <CardPropertiesTable
            tier="Soul Zodiac"
            starSign="My Zodiac"
            land="Yes"
            motion="Yes"
            edge="Yes"
            colorBlack={true}
          />
          <h3>How to Get Soul Zodiac</h3>
          <div>
            <div>
              <VideoTag path={getCardPath('Legendary', 'Tiger', 'Virgo')} />
              <p>Legendary</p>
            </div>
            <p>+</p>
            <div>
              <MyZodiac />
              <p>My Zodiac</p>
            </div>
            <p>=</p>
            <div>
              <VideoTag path={getCardPath('Soul Zodiac', 'Tiger', 'Virgo')} />
              <p>Soul Zodiac</p>
            </div>
          </div>
          <p>
            You can get Soul Zodiac
            <br className="mobile" /> when you successfully acquire
            <br />
            Legendary Tier NFT that matches your <br className="mobile" />
            inherent zodiac signs.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutSoulZodiac;
