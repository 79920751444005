import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dim from '../components/Dim';

import useClassification from '../hooks/useClassification';
import useScrollLock from '../hooks/useScrollLock';

import '../scss/ModalUpgrade.scss';

import { ReactComponent as ButtonX } from '../assets/icons/x.svg';
import { RootState } from '../store';
import { modalUpgradeClose } from '../store/modalUpgrade';
import { MyPackState, setMyPack } from '../store/myPack';

import CollectionList from './CollectionList';
import ZodiacCollectionNav from './ZodiacCollectionNav';

const ModalUpgrade: React.FC = () => {
  useScrollLock();
  const dispatch = useDispatch();

  const myPack = useSelector((state: RootState) => state.myPack);

  const [upgradePackTmp, setUpgradePackTmp] = useState<MyPackState>(myPack);

  const classification = useClassification();

  return (
    <>
      <section className="modal-upgrade">
        <ButtonX onClick={() => dispatch(modalUpgradeClose())} />
        <h2>
          <span>Select Zodiac Characters to </span>Upgrade
        </h2>
        <button
          onClick={() => {
            dispatch(setMyPack(upgradePackTmp));
            dispatch(modalUpgradeClose());
          }}
        >
          Select
        </button>
        <div className="nav-wrapper">
          <ZodiacCollectionNav classification={classification} />
          <CollectionList
            mint={false}
            myPack={upgradePackTmp}
            setUpgradePackTmp={setUpgradePackTmp}
            classificationTier={classification.classificationTier}
            animalOptiopn={classification.selectedZodiac}
            starSignOption={classification.selectedStarSign}
            modal={true}
            collectionLoading={myPack ? false : true}
          />
        </div>
      </section>
      <Dim zIndex={1200} />
    </>
  );
};

export default ModalUpgrade;
