import { contract_ZODIBASE } from 'config';
import { ethers } from 'ethers';

import { use12KindsContract } from './useContract';
import { useWeb3 } from './useWeb3';

const useZodiCollection = () => {
  const zodium12KindsContract = use12KindsContract();

  const { account } = useWeb3();

  const getBalanceOf = async () => {
    try {
      if (!account) return '0';

      let balance = await zodium12KindsContract.balanceOf(account);
      return balance.toString();
    } catch (error) {
      throw error;
    }
  };

  const getTokenOfOwnerByIndex = async (index: number) => {
    try {
      if (!account) return undefined;

      let tokenID = await zodium12KindsContract.tokenOfOwnerByIndex(
        account,
        _typecasting(index),
      );
      return tokenID.toString();
    } catch (error) {
      throw error;
    }
  };

  const getTokenURI = async (tokenID: string) => {
    try {
      let uri = await zodium12KindsContract.tokenURI(
        _typecasting(Number(tokenID)),
      );
      return uri;
    } catch (error) {
      throw error;
    }
  };

  const approvalForAll = async () => {
    try {
      let txHash = await zodium12KindsContract.setApprovalForAll(
        contract_ZODIBASE.addr,
        true,
      );
      await txHash.wait();
    } catch (error) {
      throw error;
    }
  };

  const getBaseUriFromContract = async () => {
    try {
      const uri = await zodium12KindsContract.getBaseURI();
      return uri;
    } catch (error) {
      throw error;
    }
  };

  return {
    getBalanceOf,
    getTokenOfOwnerByIndex,
    getTokenURI,
    approvalForAll,
    getBaseUriFromContract,
  };
};

//NOTE x util
const _typecasting = (v: number) => {
  return ethers.BigNumber.from(v);
};

export default useZodiCollection;
