import { useState } from 'react';

import CardProperties from '../components/CardProperties';

import '../scss/Card.scss';

import { ReactComponent as ArrowDark } from '../assets/icons/arrow_dark.svg';
import { ReactComponent as ConfirmWhite } from '../assets/icons/confirm_white.svg';
import { MyPackState } from '../store/myPack';

type CardProps = {
  mint?: boolean;
  id?: string;
  tier: string;
  animal: string;
  starSign: string;
  image: string;
  checked?: boolean;
  myPack?: MyPackState;
  setUpgradePackTmp?:
    | React.Dispatch<React.SetStateAction<MyPackState>>
    | undefined;
};

const Card: React.FC<CardProps> = ({
  mint = true,
  id = '',
  tier,
  animal,
  starSign,
  image,
  checked = false,
  myPack = undefined,
  setUpgradePackTmp = undefined,
}) => {
  const [openProperties, setOpenProperties] = useState<boolean>(false);

  const selectCard = (id: string): void => {
    if (myPack && setUpgradePackTmp && myPack.checkedLength < 6)
      setUpgradePackTmp({
        ...myPack,
        checkedLength: myPack.checkedLength + 1,
        pack: myPack.pack.map((card) =>
          card.id === id ? { ...card, checked: true } : card,
        ),
      });
  };

  const unselectCard = (id: string): void => {
    if (myPack && setUpgradePackTmp)
      setUpgradePackTmp({
        ...myPack,
        checkedLength: myPack.checkedLength - 1,
        pack: myPack.pack.map((card) =>
          card.id === id ? { ...card, checked: false } : card,
        ),
      });
  };

  return (
    <div className="card">
      {mint ? (
        tier === 'Common' || tier === 'Rare' ? (
          <img src={image} alt="card" />
        ) : (
          <video autoPlay muted loop playsInline key={image}>
            <source src={image} type="video/mp4" />
          </video>
        )
      ) : (
        <div className="select-card">
          {tier === 'Common' || tier === 'Rare' ? (
            <img src={image} alt="card" onClick={() => selectCard(id)} />
          ) : (
            <video
              autoPlay
              muted
              loop
              playsInline
              onClick={() => selectCard(id)}
              key={image}
            >
              <source src={image} type="video/mp4" />
            </video>
          )}

          {checked && (
            <>
              <ConfirmWhite onClick={() => unselectCard(id)} />
              <div onClick={() => unselectCard(id)} />
            </>
          )}
        </div>
      )}

      <button onClick={() => setOpenProperties(!openProperties)}>
        {animal}
        <ArrowDark
          style={{
            transform: `${openProperties ? 'rotate(180deg)' : 'rotate(0deg)'}`,
          }}
        />
      </button>
      <div
        className={`properties-wrapper ${
          openProperties ? 'open-properties' : ''
        }`}
      >
        <CardProperties
          tier={tier}
          starSign={starSign}
          land={tier === 'Common' ? 'No' : 'Yes'}
          motion={tier === 'Common' || tier === 'Rare' ? 'No' : 'Yes'}
          edge={tier === 'Soul Zodiac' ? 'Yes' : 'No'}
        />
      </div>
    </div>
  );
};

export default Card;
