import { createAction, handleActions } from 'redux-actions';

const SET_WALLET_ADDRESS = 'newPack/SET_WALLET_ADDRESS' as const;

export const setWalletAddress = createAction(SET_WALLET_ADDRESS);

export type WalletAddressAction = ReturnType<typeof setWalletAddress>;

export type WalletAddressState = {
  address: string;
};

const initialState: WalletAddressState = {
  address: '0xDc81800f81AdEb8dc2FBeFB05A9Af6B7ba26C2ba',
};

const walletAddress = handleActions(
  {
    [SET_WALLET_ADDRESS]: (
      state: WalletAddressState,
      action: WalletAddressAction,
    ) => ({ ...state, adddress: action.payload }),
  },
  initialState,
);

export default walletAddress;
