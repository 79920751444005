import { useState } from 'react';

import { MyPackState } from 'store/myPack';

import { CardType } from 'types/card';
import { WariningStateType } from 'types/warning';

const useCheckError = (
  myPack: MyPackState,
  upgrade: () => Promise<void>,
): [WariningStateType, () => void] => {
  const [warningState, setWarningState] = useState<WariningStateType>({
    warning: false,
    description: '',
  });

  const checkTier = (): boolean => {
    const checkedPack = myPack.pack.filter((card: CardType) => card.checked);

    let resultCheckTier: boolean =
      checkedPack.filter((card: CardType) => card.tier === 'Common').length ===
        6 ||
      checkedPack.filter((card: CardType) => card.tier === 'Rare').length ===
        6 ||
      checkedPack.filter((card: CardType) => card.tier === 'Legendary')
        .length === 6 ||
      checkedPack.filter((card: CardType) => card.tier === 'Soul Zodiac')
        .length === 6 ||
      checkedPack.filter((card: CardType) => card.tier === 'Untouchable')
        .length === 6;

    return resultCheckTier;
  };

  const checkAllLegendary = (): boolean => {
    const checkedPack = myPack.pack.filter((card: CardType) => card.checked);

    return (
      checkedPack.filter((card: CardType) => card.tier === 'Legendary')
        .length === 6
    );
  };

  const checkAllSoulZodiac = (): boolean => {
    const checkedPack = myPack.pack.filter((card: CardType) => card.checked);

    return (
      checkedPack.filter((card: CardType) => card.tier === 'Soul Zodiac')
        .length === 6
    );
  };

  const checkAllUntouchable = (): boolean => {
    const checkedPack = myPack.pack.filter((card: CardType) => card.checked);

    return (
      checkedPack.filter((card: CardType) => card.tier === 'Untouchable')
        .length === 6
    );
  };

  const checkError = () => {
    if (myPack.checkedLength < 6)
      setWarningState({
        ...warningState,
        warning: true,
        description: 'Length',
      });
    else if (!checkTier())
      setWarningState({
        ...warningState,
        warning: true,
        description: 'Tier',
      });
    else if (checkAllLegendary())
      setWarningState({
        ...warningState,
        warning: true,
        description: 'Legendary',
      });
    else if (checkAllSoulZodiac())
      setWarningState({
        ...warningState,
        warning: true,
        description: 'Soul Zodiac',
      });
    else if (checkAllUntouchable())
      setWarningState({
        ...warningState,
        warning: true,
        description: 'Untouchable',
      });
    else {
      setWarningState({ ...warningState, warning: false });
      upgrade();
    }
  };

  return [warningState, checkError];
};

export default useCheckError;
