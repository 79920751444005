import { Network, contractEnv } from '.';
import ABI_WALLETBASE from 'abi/walletBase.json';
import ABI_ZODIUM_SWAP from 'abi/zodium/ZodiumSwapForV2.json';
import ABI_ZODIUM12KINDS from 'abi/zodiumV1/zodium12KindsV1.json';
import ABI_ZODIBASE from 'abi/zodiumV1/zodiumBaseV1.json';
import ABI_ZODIINFOVIEWER from 'abi/zodiumV1/zodiumInfoViewerV1.json';
import ABI_ZODIUM from 'abi/zodiumV1/zodiumTokenV1.json';

import { Contract } from 'types/contract';

type V1ContractsKey =
  | 'zodiBase'
  | 'infoViewer'
  | 'zodium'
  | '12kinds'
  | 'walletBase'
  | 'swap';

const v1AddressMap: Record<Network, Record<V1ContractsKey, string>> = {
  [Network.MAINNET]: {
    zodiBase: '0x56459263e29fEDF9EA7E6069Be1C4444e7bcaAC6',
    infoViewer: '0x4852049D62421d5E2302eF48312Bd687CF231Ba5',
    zodium: '0x0cca2f5561bb0fca88e5b9b48b7fbf000349c357',
    '12kinds': '0x4C4Bd203B7bb3a06825cad642Df9F43278EB24C7',
    walletBase: '0x8EB80227418769B2AaDAFC2FF35a0650216B2777',
    swap: '0xCA8621d9af14D5AfEAd136Dc5b76aF5E30Cf368D',
  },
  [Network.BETA]: {
    zodiBase: '',
    infoViewer: '',
    zodium: '',
    '12kinds': '',
    walletBase: '',
    swap: '',
  },
  [Network.PRIVATE]: {
    zodiBase: '0x32519eab35Fe658B0B4888e1BACfd0317f6b837A',
    infoViewer: '0x32CFF93f4Df0bdc770706743fcd8718A7699CdF6',
    zodium: '0xb363E19A5EF94BC2b86f57C62FBd60cEe86B62dc',
    '12kinds': '0x842B635Ee29ae9e0d84c824c45d5eF989Cc25e81',
    walletBase: '0x4d11e061D88430bc11769B237EbE260777258808',
    swap: '',
  },
};

export const contract_ZODIUM: Contract = {
  addr: v1AddressMap[contractEnv].zodium,
  abi: ABI_ZODIUM,
};

export const contract_ZODIBASE: Contract = {
  addr: v1AddressMap[contractEnv].zodiBase,
  abi: ABI_ZODIBASE,
};

export const contract_WALLETBASE: Contract = {
  addr: v1AddressMap[contractEnv].walletBase,
  abi: ABI_WALLETBASE,
};

export const contract_ZODIUM12KINDS: Contract = {
  addr: v1AddressMap[contractEnv]['12kinds'],
  abi: ABI_ZODIUM12KINDS,
};

export const contract_ZODIINFOVIEWER: Contract = {
  addr: v1AddressMap[contractEnv].infoViewer,
  abi: ABI_ZODIINFOVIEWER,
};

export const contract_SWAP: Contract = {
  addr: v1AddressMap[contractEnv].swap,
  abi: ABI_ZODIUM_SWAP,
};

export const ZODIUM = v1AddressMap[contractEnv].zodium;
export const SWAP_ADDRESS = v1AddressMap[contractEnv].swap;
