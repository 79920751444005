import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper.min.css';

import 'swiper/components/navigation/navigation.min.css';

import '../scss/HomeSwiper.scss';

import Button from './Button';

SwiperCore.use([Pagination, Autoplay, Navigation]);

const HomeSwiper: React.FC = () => {
  const [mouseEnter, setMouseEnter] = useState<boolean>(false);

  const settings = useMemo<Swiper>(
    () => ({
      autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }),
    [],
  );

  return (
    <div
      className="home-swiper"
      onMouseEnter={() => {
        setMouseEnter(true);
      }}
      onMouseLeave={() => {
        setMouseEnter(false);
      }}
    >
      <div
        className={
          mouseEnter ? 'swiper-button-prev focus' : 'swiper-button-prev'
        }
      ></div>
      <div
        className={
          mouseEnter ? 'swiper-button-next focus' : 'swiper-button-next'
        }
      ></div>
      <Swiper pagination={{ clickable: true }} {...settings}>
        <SwiperSlide>
          <div className="swiper_inner">
            <div className="swiper_inner_left">
              <h1>
                THE LAND OF BATTLE
                <br />
                GRAND OPEN!
              </h1>
              <p>
                Ready to become the last Zodian reignning over the Land of
                Battle?
              </p>
              <Button
                title="PLAY NOW!"
                buttonStyle="orange"
                width="220px"
                height="60px"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  });
                  window.open('https://battle.zodium.io/');
                }}
              />
            </div>
            <div className="swiper_inner_right">
              <div className="land_of_battle"></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper_inner">
            <div className="swiper_inner_left">
              <h1>
                Draw to Become the
                <br />
                Ultimate Weapon Owner!
              </h1>
              <p>Get ready to march forward with your weapon in hand!</p>
              <Link to="/drawWeapon">
                <Button
                  title={'Draw Weapon'}
                  buttonStyle="orange"
                  width="220px"
                  height="60px"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  }}
                />
              </Link>
            </div>
            <div className="swiper_inner_right">
              <div className="weapon_cards">
                <div className="weapon_card1"></div>
                <div className="weapon_card2"></div>
                <div className="weapon_card3"></div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper_inner">
            <div className="swiper_inner_left">
              <h1>
                Discover Zodiac Signs
                <br />
                to Play and Earn!
              </h1>
              <p>Play, Earn and Be Yourself with Soul Zodiac!</p>
              <Link to="/drawZodiac">
                <Button
                  title={'Draw Zodiac'}
                  buttonStyle="orange"
                  width="220px"
                  height="60px"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  }}
                />
              </Link>
            </div>
            <div className="swiper_inner_right">
              <div className="zodiacs">
                <div className="zodiac1"></div>
                <div className="zodiac2"></div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HomeSwiper;
