import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as WalletConnect } from 'assets/icons/wallet_connect.svg';
import { ReactComponent as ButtonX } from 'assets/icons/x.svg';
import Metamask from 'assets/images/metamask.png';
import {
  connectWalletClose,
  connectWalletFlow,
  connectWalletFlowClose,
} from 'store/connectWallet';
import { r_connect } from 'store/connected';

import { useBirth } from 'hooks/useBirth';
import useScrollLock from 'hooks/useScrollLock';
import { useWeb3 } from 'hooks/useWeb3';

import 'scss/ModalConnectWallet.scss';

import Dim from './Dim';

const META = 'metamask';
const WALLET = 'wallet';
const ConnectWalletModal: React.FC = () => {
  useScrollLock();
  const dispatch = useDispatch();
  const { connect, active } = useWeb3();
  const { getbirth } = useBirth();

  useEffect(() => {
    if (active) {
      confirmBirth().then((res) => {
        dispatch(connectWalletClose());
        if (!res) {
          dispatch(connectWalletFlow());
        } else {
          dispatch(connectWalletFlowClose());
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const connectWallet = async (type: string) => {
    if (type === META) {
      await connect(META);
    } else {
      await connect(WALLET);
    }
  };

  const confirmBirth = async () => {
    try {
      let birth = await getbirth();
      dispatch(
        r_connect({
          month: birth._month.toString().padStart(2, '0'),
          day: birth._day.toString().padStart(2, '0'),
          year: birth._year.toString().padStart(4, '0'),
        }),
      );
      return birth._year.toString() === '0' ? false : true;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <aside className="connect-wallet">
        <figure onClick={() => dispatch(connectWalletClose())}>
          <ButtonX />
        </figure>
        <button
          className="metamask"
          onClick={() => {
            connectWallet(META);
          }}
        >
          <img src={Metamask} alt="MetaMask" />
          <div>
            <h3>MetaMask</h3>
            <p>Connect to your MetaMask wallet</p>
          </div>
        </button>
        <button
          className="wallet-connect"
          onClick={() => connectWallet(WALLET)}
        >
          <WalletConnect />
          <div>
            <h3>WalletConnect</h3>
            <p>Scan with WalletConnect to connect</p>
          </div>
        </button>
      </aside>
      <Dim zIndex={1200} />
    </>
  );
};

export default ConnectWalletModal;
