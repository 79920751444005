import { useRef } from 'react';
import { useSelector } from 'react-redux';

import classes from './ExpBookList.module.scss';

import { RootState } from '../../../store';
import GradientScrollFog from '../../UI/GradientScrollFog';

import ExpBookItem from './ExpBookItem';

const ExpBookList = () => {
  const booksRef = useRef<HTMLDivElement>(null);
  const expBooks = useSelector(
    (state: RootState) => state.levelupReducer.expBooks,
  );

  return (
    <div className={classes.books} ref={booksRef}>
      {expBooks.map((book, i) => (
        <ExpBookItem key={book.name} book={book} />
      ))}
      <div
        style={{
          width: '471px',
          height: '45px',
          position: 'absolute',
          bottom: '104px',
        }}
      >
        <GradientScrollFog parentRef={booksRef} />
      </div>
    </div>
  );
};

export default ExpBookList;
