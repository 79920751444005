import { useCallback, useState } from 'react';

import { ZodiacTier } from 'lib/zodiac';

export type ZodiacClassficationTier = {
  tier: ZodiacTier;
  checked: boolean;
}[];

const useClassification = () => {
  const zodiacTierOptions = Object.values(ZodiacTier).map((key) => {
    return {
      tier: key,
      checked: true,
    };
  });

  const [classificationTier, setClassificationTier] =
    useState<ZodiacClassficationTier>(zodiacTierOptions);

  const selectTier = useCallback(
    (tier) => {
      setClassificationTier(
        classificationTier.map((item) =>
          item.tier === tier
            ? { ...item, checked: !item.checked }
            : { ...item, checked: item.checked },
        ),
      );
    },
    [classificationTier],
  );

  const [selectedZodiac, setSelectedZodiac] = useState('All');
  const [selectedStarSign, setSelectedStarSign] = useState('All');

  const [zodiSelectActive, setZodiSelectActive] = useState<boolean>(false);
  const [starSelectActive, setStarSelectActive] = useState<boolean>(false);

  return {
    classificationTier,
    selectTier,

    selectedZodiac,
    setSelectedZodiac,

    selectedStarSign,
    setSelectedStarSign,

    zodiSelectActive,
    setZodiSelectActive,

    starSelectActive,
    setStarSelectActive,
  };
};

export type useClassificationType = ReturnType<typeof useClassification>;

export default useClassification;
