import { useState } from 'react';

import axios from 'axios';
import { ethers } from 'ethers';

import { useZodibaseContract } from './useContract';
import useZodiCollection from './useZodiCollection';

const useZodiac = () => {
  const zodibaseContract = useZodibaseContract();

  const { getBaseUriFromContract } = useZodiCollection();

  const [isLoading, setIsLoading] = useState(true);
  const [drawTokenIDs, setDrawTokenIDs] = useState<Array<string>>([]);

  const drawzodiac = async (amount: number) => {
    let _tokenIDs: Array<string> = [];
    try {
      const baseURI = await getBaseUriFromContract();
      let estimated_gasPrice = await zodibaseContract.estimateGas.drawZodiac(
        _typecasting(amount),
      );
      let padding_gasLimit = estimated_gasPrice.div(10).add(estimated_gasPrice);
      let txHash = await zodibaseContract.drawZodiac(_typecasting(amount), {
        gasLimit: padding_gasLimit,
      });
      const receipt = await txHash.wait();

      receipt.events?.forEach((x: any) => {
        if (x.event === 'DrawZodiac') {
          for (const item of x.args.tokenIds) {
            _tokenIDs.push(item.toString());
          }

          let retrying = setInterval(() => {
            axios
              .get(`${baseURI}${_tokenIDs[_tokenIDs.length - 1]}`)
              .then((res) => {
                setDrawTokenIDs(_tokenIDs);
                setIsLoading(false);
                clearInterval(retrying);
              })
              .catch((err) => {
                console.log(err);
              });
          }, 600);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const fuseZodiac = async (
    tokenIDs: Array<number>,
    setResultCardTmp: React.Dispatch<React.SetStateAction<string | undefined>>,
  ) => {
    try {
      const baseURI = await getBaseUriFromContract();
      let _tokenIDs: Array<string> = [];
      const coder = new ethers.utils.AbiCoder();
      const data = coder.encode(
        ['uint', 'uint', 'uint', 'uint', 'uint', 'uint'],
        tokenIDs,
      );

      let txHash = await zodibaseContract.fusingZodiac(data);
      const receipt = await txHash.wait();

      receipt.events?.forEach((x: any) => {
        if (x.event === 'FusingZodiac') {
          let retrying = setInterval(() => {
            axios
              .get(`${baseURI}${x.args.tokenId.toString()}`)
              .then((res) => {
                setResultCardTmp(x.args.tokenId.toString());
                _tokenIDs.push(x.args.tokenId.toString());
                setDrawTokenIDs(_tokenIDs);
                setIsLoading(false);
                clearInterval(retrying);
              })
              .catch((err) => {
                console.log(err);
              });
          }, 400);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const getDrawPayRate = async () => {
    try {
      let payRate = await zodibaseContract.getPayRate();
      return Number(payRate.toString());
    } catch (error) {
      throw error;
    }
  };

  const verificationDraw = async () => {
    try {
      const currentCnt = await zodibaseContract.getDrawZodiacCnt();
      const limitCnt = await zodibaseContract.getDrawZodiacLimit();
      return limitCnt.sub(currentCnt).toNumber();
    } catch (error) {
      throw error;
    }
  };

  return {
    drawzodiac,
    fuseZodiac,
    getDrawPayRate,
    verificationDraw,
    isLoading,
    drawTokenIDs,
  };
};

//NOTE x util
const _typecasting = (v: number) => {
  return ethers.BigNumber.from(v).mul(ethers.BigNumber.from(10).pow(18));
};

export default useZodiac;
