import React from 'react';

import { weaponMap } from '../lib/weapon';
import { AnimalEnum } from '../lib/zodiac';

import '../scss/draw/MintedWeaponStatus.scss';

type MintedWeaponStatusProp = {
  zodiacType: AnimalEnum;
  totalLimit: number;
};

export default React.memo(function MintedWeaponStatus({
  zodiacType,
  totalLimit,
}: MintedWeaponStatusProp) {
  return (
    <div className="minted-weapon-item">
      <div className="weapon-img">
        <img src={weaponMap[zodiacType].img} alt={weaponMap[zodiacType].name} />
      </div>
      <div className="name">
        <div className="name__zodi">{zodiacType}'s Weapon</div>
        <div className="name__weapon">{weaponMap[zodiacType].name}</div>
      </div>
      <div className="cnt">{totalLimit}</div>
    </div>
  );
});
