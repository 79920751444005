import { WeaponAttributes } from '../types/weapon';

const extractWeaponAttributes = (attributes: any): WeaponAttributes => {
  const [rarity, name, element, stackedExp, power, level] = attributes.map(
    (attr: any) => attr.value,
  );

  return {
    rarity: Number(rarity),
    name,
    element,
    stackedExp: Number(stackedExp),
    power: Number(power),
    level: Number(level),
  };
};

export default extractWeaponAttributes;
