import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { WeaponMetadataResponse } from 'api/useWeaponCollectionQuery';
import { ReactComponent as Confirm } from 'assets/icons/confirm.svg';
import axios from 'axios';
import { modalClose, modalOpen } from 'store/modal';
import { setWwapon } from 'store/newWeapon';
import { clearWeapon } from 'store/newWeapon';

import Loading from 'components/Loading';

import { useWeapon } from 'hooks/useWeapon';
import useZodium from 'hooks/useZodium';

import extractWeaponAttributes from 'lib/extractWeaponAttributes';

import { WeaponType } from 'types/weapon';

import 'scss/draw/WeaponDrawingContainer.scss';

import BeforeWeaponDraw from './BeforeWeaponDraw';
import WeaponAfterDraw from './WeaponAfterDraw';

type DrawingProps = {
  token: string;
  drawed: boolean;
  weaponPayRate: number;
  isDrawable: boolean;
};

const WeaponDrawing: React.FC<DrawingProps> = ({
  token,
  drawed,
  weaponPayRate,
  isDrawable,
}) => {
  const dispatch = useDispatch();
  // const { drawzodiac, isLoading, drawTokenIDs } = useZodiac();
  const { drawWeapon, isLoading, drawWeaponTokenIDs, baseUri, isDrawing } =
    useWeapon();
  const _baseUri = `https://${baseUri.split('/')[2]}/`;
  const { weaponTokenApprove, getWeaponAllowance } = useZodium();

  const [packs, setPacks] = useState<number>(0);
  const [drawState, setDrawState] = useState<boolean>(false);
  const [processState, setProcessState] = useState<boolean>(false);

  //NOTE x Token mint
  const getDrawWeapon = async () => {
    try {
      await drawWeapon(packs * weaponPayRate);
    } catch (error) {
      console.error(error);
      dispatch(modalClose());
    }
  };

  // NOTE x getAllowance
  const weaponVerityAllowance = async () => {
    if (!drawed) {
      try {
        dispatch(
          modalOpen({
            alertImage: <Loading />,
            title: 'Check Allowance',
            description: `\nConfirm this transaction in your wallet\n\n\n`,
            dimZ: 1200,
          }),
        );
        let approvalAmount = await getWeaponAllowance();
        if (packs * weaponPayRate > Number(approvalAmount)) {
          weaponTokenApprove()
            .then(() => {
              let timer = setTimeout(() => {
                dispatch(
                  modalOpen({
                    alertImage: <Loading />,
                    title: 'Waiting for Approve',
                    description: `\nConfirm this transaction in your wallet\n\n\n`,
                    dimZ: 1200,
                  }),
                );
                getDrawWeapon();
                clearTimeout(timer);
              }, 5000);
            })
            .catch((e) => {
              // user reject approve
              if (e.code === 4001) {
                dispatch(modalClose());
              } else {
                modalOpen({
                  title: 'Alert!',
                  description: 'BSC Transaction Error',
                  mainButtonTitle: 'OK',
                  mainButtonEvent: () => {
                    dispatch(modalClose());
                  },
                  dimZ: 2500,
                });
              }
            });
        } else if (Number(approvalAmount) > 0) {
          getDrawWeapon();
        }
      } catch (error) {
        console.error(error);
        dispatch(modalClose());
      }
    }
  };

  // //NOTE x tokenURI 유효성 부여
  const getMetaData = () => {
    let baseURI = _baseUri;
    let URIs: Array<string> = [];
    for (const id of drawWeaponTokenIDs) {
      const _url = `${baseURI}${id}`;
      URIs.push(_url);
    }
    showDrawWeapon(URIs);
  };

  // //NOTE x JSON schema 데이터 가져와 보여주기
  const showDrawWeapon = async (uriList: Array<string>) => {
    try {
      const weaponDrawNewPacks: WeaponType[] = [];

      for (const uri of uriList) {
        const { data } = await axios.get<WeaponMetadataResponse>(`${uri}`);
        weaponDrawNewPacks.push({
          ...data,
          attributes: extractWeaponAttributes(data.attributes),
        });
      }
      dispatch(clearWeapon());
      dispatch(setWwapon(weaponDrawNewPacks));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isDrawing) {
      dispatch(
        modalOpen({
          alertImage: <Loading />,
          title: 'Waiting for Drawing Weapon',
          description: `\nConfirm this transaction in your wallet\n\n\n`,
          dimZ: 1200,
        }),
      );
    }
  }, [isDrawing, dispatch]);

  useEffect(() => {
    if (!isLoading) {
      //NOTE x isLoading 값이 false가 되면 로딩창이 사라진다.
      dispatch(
        modalOpen({
          alertImage: <Confirm />,
          title: 'BSC Transaction Submitted',
          description: undefined,
          mainButtonTitle: 'Close',
          mainButtonEvent: () => {
            dispatch(modalClose());
            setDrawState(true);
          },
          dimZ: 1200,
        }),
      );
      getMetaData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <section className="weapon-drawing-card">
      {/* {!drawState ? (
                    <img src={`${process.env.PUBLIC_URL}/DefaultImage.png`}></img>
                ) : (
                    <img src={`${process.env.PUBLIC_URL}/Card-5.gif`}></img>
                    )
            } */}
      <img
        className="gif-image"
        src={`${process.env.PUBLIC_URL}/Card-5.gif`}
        alt="card"
      ></img>
      {drawState ? (
        <WeaponAfterDraw
          processState={processState}
          setProcessState={setProcessState}
        />
      ) : (
        <BeforeWeaponDraw
          token={token}
          packs={packs}
          setPacks={setPacks}
          weaponVerityAllowance={weaponVerityAllowance}
          weaponPayRate={weaponPayRate}
          isDrawable={isDrawable}
        />
      )}
    </section>
  );
};

export default WeaponDrawing;
