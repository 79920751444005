import { useState } from 'react';
import { useSelector } from 'react-redux';

import SelectedIcon from 'assets/icons/selected.svg';
import PowerIcon from 'assets/images/weapon/premium-icon-sword.png';
import { RootState } from 'store';

import useDerivedWeaponInfo from 'hooks/useDerivedWeaponInfo';

import { WeaponType } from 'types/weapon';

import classes from './UpgradableWeaponItem.module.scss';

import WeaponImage from '../WeaponImage';

const UpgradableWeaponItem = ({
  weapon,
  onUnselect,
  onSelect,
}: {
  weapon: WeaponType;
  onUnselect: (w: WeaponType) => void;
  onSelect: (w: WeaponType) => void;
}) => {
  const { power, stars } = useDerivedWeaponInfo(weapon);

  const [selected, setSelected] = useState(false);

  const canUpgradeNow = useSelector(
    (state: RootState) => state.weaponUpgradeReducer.canUpgradeNow,
  );

  const clickHandler = () => {
    if (selected) {
      onUnselect(weapon);
      setSelected(false);
      return;
    }

    if (canUpgradeNow) return;

    onSelect(weapon);
    setSelected(true);
  };

  return (
    <div
      className={classes[selected ? 'selected-item' : 'item']}
      onClick={clickHandler}
    >
      <div className={classes['weapon-image']}>
        <WeaponImage weaponImage={weapon.image} />
      </div>
      <div className={classes['power-info']}>
        <img src={PowerIcon} alt="power" />
        <span>{power}</span>
      </div>
      <div className={classes['tier-info']}>{stars()}</div>
      {selected && (
        <div className={classes['selected-icon']}>
          <img src={SelectedIcon} alt="check" />
        </div>
      )}
    </div>
  );
};

export default UpgradableWeaponItem;
