import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { expBookServerURL } from 'api';
import axios from 'axios';

const useObserverTriggerMutation = () => {
  const mutator = useCallback(async () => {
    const { data } = await axios.get(`${expBookServerURL}/observer`);
    return data;
  }, []);

  const { mutate } = useMutation(mutator);

  return mutate;
};

export default useObserverTriggerMutation;
