import { useCallback } from 'react';

import ClassificationTier from '../components/ClassificationTier';
import CollectionSelectBox from '../components/CollectionSelectBox';

import { useClassificationType } from '../hooks/useClassification';

import { animalArray, starSignArray } from '../lib/zodiac';

import '../scss/ZodiacCollectionNav.scss';

type ZodiacCollectionNavProps = {
  classification: useClassificationType;
};

export default function ZodiacCollectionNav({
  classification,
}: ZodiacCollectionNavProps) {
  const {
    setSelectedZodiac,
    setZodiSelectActive,
    setSelectedStarSign,
    setStarSelectActive,
  } = classification;

  const handleZodiSelect = useCallback(
    (selected: string) => {
      setSelectedZodiac(selected);
      setZodiSelectActive(false);
    },
    [setSelectedZodiac, setZodiSelectActive],
  );

  const handleStarSelect = useCallback(
    (selected: string) => {
      setSelectedStarSign(selected);
      setStarSelectActive(false);
    },
    [setSelectedStarSign, setStarSelectActive],
  );

  const zodiSelectBoxClick = useCallback(() => {
    setZodiSelectActive((prev) => !prev);
    setStarSelectActive(false);
  }, [setZodiSelectActive, setStarSelectActive]);

  const starSelectBoxClick = useCallback(() => {
    setStarSelectActive((prev) => !prev);
    setZodiSelectActive(false);
  }, [setStarSelectActive, setZodiSelectActive]);

  return (
    <section className="classification-nav" id="classification-nav">
      <ClassificationTier
        classificationTier={classification.classificationTier}
        selectTier={classification.selectTier}
      />
      <div className="classification-class-wrapper">
        <CollectionSelectBox
          title="Zodiac Animals"
          options={animalArray}
          active={classification.zodiSelectActive}
          onOptionSelected={handleZodiSelect}
          onBoxClick={zodiSelectBoxClick}
        />
        <CollectionSelectBox
          title="Star Signs"
          options={starSignArray}
          active={classification.starSelectActive}
          onOptionSelected={handleStarSelect}
          onBoxClick={starSelectBoxClick}
        />
      </div>
    </section>
  );
}
