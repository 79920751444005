import '../scss/CardProperties.scss';

type CardPropertiesProps = {
  tier: string;
  starSign: string;
  land: string;
  motion: string;
  edge: string;
  border?: boolean;
};

const CardProperties: React.FC<CardPropertiesProps> = ({
  tier,
  starSign,
  land,
  motion,
  edge,
  border = true,
}) => {
  return (
    <div className={`card-properties ${border ? 'border' : ''}`}>
      <p>Properties</p>
      <ul>
        <li>
          <p>Tier</p>
          <p>{tier}</p>
        </li>
        <li>
          <p>Star Sign</p>
          <p>{starSign}</p>
        </li>
        <li>
          <p>Land</p>
          <p>{land}</p>
        </li>
        <li>
          <p>Motion</p>
          <p>{motion}</p>
        </li>
        <li>
          <p>Edge</p>
          <p>{edge}</p>
        </li>
      </ul>
    </div>
  );
};

export default CardProperties;
