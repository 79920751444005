import LoadingSmall from '../components/LoadingSmall';

import { ReactComponent as Confirm } from '../assets/icons/confirm.svg';

type ProcessStateProps = {
  processState: boolean;
};

const ProcessState: React.FC<ProcessStateProps> = ({ processState }) => {
  return <>{processState ? <Confirm /> : <LoadingSmall />}</>;
};

export default ProcessState;
