import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../components/Button';
import Dim from '../components/Dim';
import Loading from '../components/Loading';

import { useBirth } from '../hooks/useBirth';
import useScrollLock from '../hooks/useScrollLock';
import { useWeb3 } from '../hooks/useWeb3';

import {
  convertMonthToNumber,
  dayArray,
  dayArrayFeb,
  dayArrayFebLeaf,
  dayArrayShort,
  isInvalid,
  isLeaf,
  isOver,
  monthArray,
  monthArrayMobile,
  yearArray,
} from '../lib/date';

import { WariningStateType } from '../types/warning';

import '../scss/ModalConnectWalletFlow.scss';

import { ReactComponent as ArrowDark } from '../assets/icons/arrow_dark.svg';
import { ReactComponent as Confirm } from '../assets/icons/confirm.svg';
import { ReactComponent as Warning } from '../assets/icons/warning.svg';
import {
  connectWalletClose,
  connectWalletFlowClose,
} from '../store/connectWallet';
import { r_connect } from '../store/connected';
import { modalClose, modalOpen } from '../store/modal';

const ModalConnectWalletFlow: React.FC = () => {
  useScrollLock();
  const { disconnect } = useWeb3();
  const [month, setMonth] = useState<string>('Month');
  const [openMonth, setOpenMonth] = useState<boolean>(false);
  const [day, setDay] = useState<string>('Day');
  const [openDay, setOpenDay] = useState<boolean>(false);
  const [year, setYear] = useState<string>('Year');
  const [openYear, setOpenYear] = useState<boolean>(false);
  const [warningState, setWarningState] = useState<WariningStateType>({
    warning: false,
    description: '',
  });

  const dispatch = useDispatch();
  const { setbirth } = useBirth();

  const checkError = () => {
    if (month === 'Month') setWarningState({ ...warningState, warning: true });
    else if (day === 'Day') setWarningState({ ...warningState, warning: true });
    else if (year === 'Year')
      setWarningState({ ...warningState, warning: true });
    else if (isOver(year, month, day))
      setWarningState({ ...warningState, warning: true });
    else if (isInvalid(year, month, day))
      setWarningState({ ...warningState, warning: true });
    else {
      setWarningState({ ...warningState, warning: false });
      dispatch(
        modalOpen({
          title: 'Alert!',
          description:
            'Once submitted, your date of birth cannot\nbe modified. Please enter it carefully.',
          mainButtonTitle: 'Confirmed!',
          mainButtonEvent: () => {
            userSetBirth();

            dispatch(
              modalOpen({
                alertImage: <Loading />,
                title: 'Waiting for Confirmation',
                description: '\nConfirm this transaction in your wallet\n\n\n',
                mainButtonTitle: undefined,
                mainButtonEvent: undefined,
                subButtonTitle: undefined,
                subButtonEvent: undefined,
                dimZ: 1500,
              }),
            );
          },
          subButtonTitle: 'Go Back!',
          subButtonEvent: () => dispatch(modalClose()),
          dimZ: 1550,
        }),
      );
    }
  };

  const userSetBirth = async () => {
    try {
      await setbirth(year, convertMonthToNumber(month), day);
      dispatch(
        r_connect({
          month: month,
          day: day,
          year: year,
        }),
      );
      dispatch(modalClose());
      dispatch(connectWalletFlowClose());
      dispatch(connectWalletClose());
      dispatch(
        modalOpen({
          alertImage: <Confirm />,
          title: 'BSC Transaction Submitted',
          description: undefined,
          mainButtonTitle: 'OK',
          mainButtonEvent: () => {
            dispatch(modalClose());
          },
          dimZ: 1200,
        }),
      );
    } catch (error) {
      console.error(error);
      dispatch(modalClose());
      dispatch(connectWalletFlowClose());
      dispatch(connectWalletClose());
      dispatch(
        modalOpen({
          title: 'Alert!',
          description: 'BSC Transaction Error',
          mainButtonTitle: 'OK',
          mainButtonEvent: () => {
            dispatch(modalClose());
            disconnect();
          },
          dimZ: 1200,
        }),
      );
    }
  };

  return (
    <>
      <section className="connect-wallet-flow">
        <h2>
          <span>Want to know</span> your zodiac sign<span>?</span>
        </h2>
        <p>
          Let us know your date of birth!
          <br />
          Then, we will let you know your zodiac signs right away!
        </p>
        <p className="cwf-description">
          This information is not publicly displayed other than yourself.
          <br />
          There will be gas fees you put your birth dates for once.
          <br className="web" />
          We need it in out contract to set you on the right place for making
          Soul Zodiac!
        </p>
        <div className="birth-select-wrapper">
          <div className="select-wrapper">
            <button
              onClick={() => {
                setOpenMonth(!openMonth);
                setOpenDay(false);
                setOpenYear(false);
              }}
            >
              {month}
              <ArrowDark />
            </button>
            {openMonth && (
              <>
                <div className="month-web">
                  {monthArray.map((m) => (
                    <p // 선택시 글자 검정
                      className={`${month !== 'Month' ? 'selected' : ''}`}
                      onClick={() => {
                        setMonth(m);
                        setOpenMonth(false);
                      }}
                      key={m}
                    >
                      {m}
                    </p>
                  ))}
                </div>
                <div className="month-mobile">
                  {monthArrayMobile.map((m) => (
                    <p // 선택시 글자 검정
                      className={`${month !== 'Month' ? 'selected' : ''}`}
                      onClick={() => {
                        setMonth(m);
                        setOpenMonth(false);
                      }}
                      key={m}
                    >
                      {m}
                    </p>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="select-wrapper">
            <button
              onClick={() => {
                setOpenMonth(false);
                setOpenDay(!openDay);
                setOpenYear(false);
              }}
            >
              {day}
              <ArrowDark />
            </button>
            {openDay && (
              <div>
                {month === 'January' ||
                month === 'Jan' ||
                month === 'March' ||
                month === 'Mar' ||
                month === 'May' ||
                month === 'July' ||
                month === 'Jul' ||
                month === 'August' ||
                month === 'Aug' ||
                month === 'October' ||
                month === 'Oct' ||
                month === 'December' ||
                month === 'Dec'
                  ? dayArray.map((d) => (
                      <p // 선택시 글자 검정
                        className={`${day !== 'Day' ? 'selected' : ''}`}
                        onClick={() => {
                          setDay(d);
                          setOpenDay(false);
                        }}
                        key={d}
                      >
                        {d}
                      </p>
                    ))
                  : month === 'February' || month === 'Feb'
                  ? isLeaf(year)
                    ? dayArrayFebLeaf.map((d) => (
                        <p // 선택시 글자 검정
                          className={`${day !== 'Day' ? 'selected' : ''}`}
                          onClick={() => {
                            setDay(d);
                            setOpenDay(false);
                          }}
                          key={d}
                        >
                          {d}
                        </p>
                      ))
                    : dayArrayFeb.map((d) => (
                        <p // 선택시 글자 검정
                          className={`${day !== 'Day' ? 'selected' : ''}`}
                          onClick={() => {
                            setDay(d);
                            setOpenDay(false);
                          }}
                          key={d}
                        >
                          {d}
                        </p>
                      ))
                  : dayArrayShort.map((d) => (
                      <p // 선택시 글자 검정
                        className={`${day !== 'Day' ? 'selected' : ''}`}
                        onClick={() => {
                          setDay(d);
                          setOpenDay(false);
                        }}
                        key={d}
                      >
                        {d}
                      </p>
                    ))}
              </div>
            )}
          </div>
          <div className="select-wrapper">
            <button
              onClick={() => {
                setOpenMonth(false);
                setOpenDay(false);
                setOpenYear(!openYear);
              }}
            >
              {year}
              <ArrowDark />
            </button>
            {openYear && (
              <div>
                {yearArray.map((y) => (
                  <p // 선택시 글자 검정
                    className={`${year !== 'Year' ? 'selected' : ''}`}
                    onClick={() => {
                      setYear(y);
                      setOpenYear(false);
                    }}
                    key={y}
                  >
                    {y}
                  </p>
                ))}
              </div>
            )}
          </div>

          {warningState.warning && (
            <p className="warning">
              <Warning />
              Please check your date of birth again.
            </p>
          )}
        </div>

        <p>
          Click Below
          <br />I agree to Zodium’s terms & conditions and privacy policy.
        </p>

        <Button title="Continue" width="100%" onClick={checkError} />
      </section>
      <Dim zIndex={1200} />
    </>
  );
};

export default ModalConnectWalletFlow;
