import '../../scss/home/AboutZodium.scss';

import { ReactComponent as Characters } from '../../assets/icons/characters.svg';
import { ReactComponent as Money } from '../../assets/icons/money.svg';
import { ReactComponent as Nature } from '../../assets/icons/nature.svg';

const AboutZodium: React.FC = () => {
  return (
    <section className="about-zodium">
      <div className="about-zodium-item">
        <div className="image-place" style={{ backgroundColor: '#FFEDCA' }}>
          <Characters />
        </div>
        <h2>The 12 KINDs Characters</h2>
        <p>
          12 KINDs is an animated 3D character IP NFT
          <br />
          inspired by the combination of
          <br />
          Eastern zodiac animals
          <br className="mobile" />
          and Western astrology.
          <br />
          The former co-founder of LINE FRIENDS
          <br />
          has created the 12 KINDs.
          <br />
          12 KINDs will also appear
          <br className="mobile" />
          in animation, games,
          <br />
          licensed merchandise, and more!
        </p>
      </div>
      <div className="about-zodium-item">
        <div className="image-place" style={{ backgroundColor: '#E2DDFF' }}>
          <Money />
        </div>
        <h2>Community-Driven</h2>
        <p>
          We aim to build a transparent
          <br />
          and user-friendly environment
          <br />
          in which players genuinely own
          <br />
          and run the universe of Zodium.
          <br />
          Be a part of 12 KINDs Guilds and win
          <br />
          amazing guild prize!
        </p>
      </div>
      <div className="about-zodium-item">
        <div className="image-place" style={{ backgroundColor: '#E4FFE8' }}>
          <Nature />
        </div>
        <h2>‘Meta Connection’</h2>
        <p className="meta-connection">
          We want to introduce a new game ecosystem
          <br />
          by connecting users to the characters
          <br />
          they play through the ‘soul zodiac.’
          <br />
          The Soul Zodiac is a zodiac of the same star
          <br />
          and animal signs as its owner.
          <br />
          Once the soul zodiac is received,
          <br />
          users get exclusive benefits.
        </p>
        <p className="meta-connection-mobile">
          We want to introduce
          <br />a new game ecosystem
          <br />
          by connecting users to the characters
          <br />
          they play through the ‘soul zodiac.’
          <br />
          The Soul Zodiac
          <br />
          is a zodiac of the same star
          <br />
          and animal signs as its owner.
          <br />
          Once the soul zodiac is received,
          <br />
          users get exclusive benefits.
        </p>
      </div>
    </section>
  );
};

export default AboutZodium;
