import { useMemo } from 'react';

import LevelUpIcon from 'assets/images/weapon/levelup-icon.svg';
import PowerIcon from 'assets/images/weapon/premium-icon-sword.png';
import { expMap } from 'constants/weaponExp';

import useDerivedWeaponInfo from 'hooks/useDerivedWeaponInfo';

import { WeaponType } from 'types/weapon';

import classes from './WeaponCard.module.scss';

import ProgressBar from '../ProgressBar';
import MultiProgressBar from '../UI/MultiProgressBar';

import WeaponImage from './WeaponImage';

type WeaponProp = {
  weapon: WeaponType;
  forModal?: boolean;
  predictedExp?: number;
  showNextTier?: boolean;
};

export default function WeaponCard({
  weapon,
  forModal = false,
  predictedExp,
  showNextTier = false,
}: WeaponProp) {
  const { stackedExp, power, level, nextRequiredExp, nextStackedExp, stars } =
    useDerivedWeaponInfo(weapon, showNextTier);

  const curLevelStacked = useMemo(() => expMap[level ?? 0].stacked, [level]);
  const progressBarValue = useMemo(() => {
    if (!stackedExp || !nextRequiredExp) return 0;

    if (level === 50) return 100;

    return ((stackedExp - curLevelStacked) / nextRequiredExp) * 100;
  }, [stackedExp, nextRequiredExp, curLevelStacked, level]);

  const showLevelUpMark = useMemo(
    () =>
      stackedExp !== undefined &&
      nextStackedExp !== undefined &&
      stackedExp >= nextStackedExp &&
      level !== 50,
    [stackedExp, nextStackedExp, level],
  );
  return (
    <div className={classes[forModal ? 'weapon-card-modal' : 'weapon-card']}>
      <div className={classes['weapon-info']}>
        <div className={classes['levelup-icon']}>
          {showLevelUpMark && <img src={LevelUpIcon} alt="level up icon" />}
        </div>
        <div className={classes['weapon-image']}>
          <WeaponImage weaponImage={weapon.image} />
        </div>
        <div className={classes['power-info']}>
          <img src={PowerIcon} alt="power" />
          <span>{power}</span>
        </div>
        <div className={classes['tier-info']}>{stars()}</div>
        <div className={classes['level-info']}>
          <div className={classes['level']}>
            <div>Lv.{level}</div>
            <div className={classes['icon']}>
              {showLevelUpMark && (
                <>
                  Upgrade
                  <figure>
                    <img src={LevelUpIcon} alt="" />
                  </figure>
                </>
              )}
            </div>
          </div>
          {forModal &&
          stackedExp !== undefined &&
          nextRequiredExp !== undefined ? (
            <MultiProgressBar
              curr={progressBarValue}
              next={
                predictedExp
                  ? ((predictedExp - curLevelStacked) / nextRequiredExp) * 100
                  : 0
              }
              style={{ height: '13px' }}
            />
          ) : (
            stackedExp !== undefined &&
            nextRequiredExp !== undefined && (
              <ProgressBar value={progressBarValue} />
            )
          )}

          <div className={classes['exp']}>
            {stackedExp}/{nextStackedExp}
          </div>
        </div>
      </div>
    </div>
  );
}
