import { createAction, handleActions } from 'redux-actions';
import invariant from 'tiny-invariant';

import { ExpBook } from '../types/expbook';
import { WeaponType } from '../types/weapon';

import { expMap } from '../constants/weaponExp';

type ExpBookForUse = {
  expBook: ExpBook;
  useCnt: number;
};

type WeaponLevelUpState = {
  targetWeapon: WeaponType | null;
  tier: number;
  level: number;
  tierMaxLevel: number;
  expBooks: ExpBook[];
  expBookForUse: ExpBookForUse[];
  predictedExp: number;
  tierMaxExp: number;
};

const SELECT_WEAPON = 'levelup/SELECT_WEAPON' as const;
const UNSELECT_WEAPON = 'levelup/UNSELECT_WEAPON' as const;
const UPDATE_EXPBOOKS = 'levelup/UPDATE_EXPBOOKS' as const;
const MINUS_EXPBOOK_FOR_USE = 'levelup/MINUS_EXPBOOK_FOR_USE' as const;
const PLUS_EXPBOOK_FOR_USE = 'levelup/PLUS_EXPBOOK_FOR_USE' as const;
const MINUS_ALL_EXPBOOK_FOR_USE = 'levelup/MINUS_ALL_EXPBOOK_FOR_USE' as const;
const PLUS_ALL_EXPBOOK_FOR_USE = 'levelup/PLUS_ALL_EXPBOOK_FOR_USE' as const;
const UPDATE_EXPBOOKS_FOR_USE = 'levelup/UPDATE_EXPBOOKS_FOR_USE' as const;
const CLEAR_EXPBOOKS_FOR_USE = 'levelup/CLEAR_EXPBOOKS_FOR_USE' as const;

export const selectWeapon = createAction(
  SELECT_WEAPON,
  (w: WeaponType): Partial<WeaponLevelUpState> => {
    const { rarity, stackedExp, level } = w.attributes;

    const tierMaxLevel = rarity * 10;
    const tierMaxExp = expMap[tierMaxLevel + 1].stacked;

    return {
      targetWeapon: w,
      tier: rarity,
      level,
      tierMaxLevel,
      predictedExp: stackedExp,
      tierMaxExp,
    };
  },
);
export const unselectWeapon = createAction(UNSELECT_WEAPON);
export const updateExpBooks = createAction(
  UPDATE_EXPBOOKS,
  (expBooks: ExpBook[]) => expBooks,
);
export const minusExpBookForUse = createAction(
  MINUS_EXPBOOK_FOR_USE,
  (expBook: ExpBook) => expBook,
);
export const plusExpBookForUse = createAction(
  PLUS_EXPBOOK_FOR_USE,
  (expBook: ExpBook) => expBook,
);
export const minusAllExpBookForUse = createAction(
  MINUS_ALL_EXPBOOK_FOR_USE,
  (expBook: ExpBook) => expBook,
);
export const plusAllExpBookForUse = createAction(
  PLUS_ALL_EXPBOOK_FOR_USE,
  (expBook: ExpBook) => expBook,
);
export const updateExpBookForUse = createAction(
  UPDATE_EXPBOOKS_FOR_USE,
  (expBookForUse: ExpBookForUse) => expBookForUse,
);
export const clearExpBookForUse = createAction(
  CLEAR_EXPBOOKS_FOR_USE,
  () => [],
);

const initialState: WeaponLevelUpState = {
  targetWeapon: null,
  tier: 0,
  level: 0,
  tierMaxLevel: 0,
  expBooks: [],
  expBookForUse: [],
  predictedExp: 0,
  tierMaxExp: 0,
};

const levelupReducer = handleActions<
  WeaponLevelUpState,
  Partial<WeaponLevelUpState> | ExpBook | ExpBook[] | ExpBookForUse
>(
  {
    [SELECT_WEAPON]: (state, { payload }) => ({
      ...state,
      ...(payload as Partial<WeaponLevelUpState>),
    }),
    [UNSELECT_WEAPON]: (state) => ({
      ...initialState,
      expBooks: state.expBooks,
    }),
    [UPDATE_EXPBOOKS]: (state, { payload }) => ({
      ...state,
      expBooks: [...(payload as ExpBook[])],
    }),
    [MINUS_EXPBOOK_FOR_USE]: (state, { payload }) => {
      try {
        const payloadBook = payload as ExpBook;
        const existingBookIndex = state.expBookForUse.findIndex((book) =>
          isSameExpBook(book.expBook, payloadBook),
        );
        invariant(existingBookIndex !== -1, `we need existing book`);

        const existingBook = state.expBookForUse[existingBookIndex];
        const updatedBook: ExpBookForUse = {
          ...existingBook,
          useCnt: existingBook.useCnt - 1,
        };

        invariant(updatedBook.useCnt >= 0, `it can't be under 0`);

        const updatedExpBookForUse: ExpBookForUse[] = [...state.expBookForUse];
        updatedExpBookForUse[existingBookIndex] = updatedBook;

        const updatedExp = state.predictedExp - updatedBook.expBook.exp;

        console.log('MINUS_EXPBOOK_FOR_USE', updatedExpBookForUse);

        return {
          ...state,
          expBookForUse: updatedExpBookForUse,
          predictedExp: updatedExp,
        };
      } catch (e) {
        return state;
      }
    },
    [PLUS_EXPBOOK_FOR_USE]: (state, { payload }) => {
      try {
        invariant(
          state.predictedExp < state.tierMaxExp,
          `exp can't exceed tier max exp`,
        );
        const payloadBook = payload as ExpBook;
        const existingBookIndex = state.expBookForUse.findIndex((book) =>
          isSameExpBook(book.expBook, payloadBook),
        );
        const existingBook: ExpBookForUse | undefined =
          state.expBookForUse[existingBookIndex];

        let updatedBook: ExpBookForUse;
        if (existingBook) {
          updatedBook = {
            ...existingBook,
            useCnt: existingBook.useCnt + 1,
          };
        } else {
          updatedBook = {
            expBook: payloadBook,
            useCnt: 1,
          };
        }

        invariant(
          updatedBook.useCnt <= updatedBook.expBook.cnt,
          `useCnt can't over exp book stock`,
        );

        let updatedExpBookForUse = [...state.expBookForUse];
        if (existingBook) {
          updatedExpBookForUse[existingBookIndex] = updatedBook;
        } else {
          updatedExpBookForUse = state.expBookForUse.concat(updatedBook);
        }

        const updatedExp = state.predictedExp + updatedBook.expBook.exp;

        console.log('PLUS_EXPBOOK_FOR_USE', updatedExpBookForUse);

        return {
          ...state,
          expBookForUse: updatedExpBookForUse,
          predictedExp: updatedExp,
        };
      } catch (e) {
        return state;
      }
    },
    [MINUS_ALL_EXPBOOK_FOR_USE]: (state, { payload }) => {
      try {
        const payloadBook = payload as ExpBook;

        const existingBookIndex = state.expBookForUse.findIndex((book) =>
          isSameExpBook(book.expBook, payloadBook),
        );
        invariant(existingBookIndex !== -1, `we need existing book`);

        const existingBook = state.expBookForUse[existingBookIndex];

        const updatedExp =
          state.predictedExp - existingBook.expBook.exp * existingBook.useCnt;

        const updatedExpBookForUse: ExpBookForUse[] =
          state.expBookForUse.filter((_, i) => i !== existingBookIndex);

        console.log('MINUS_ALL_EXPBOOK_FOR_USE', updatedExpBookForUse);

        return {
          ...state,
          predictedExp: updatedExp,
          expBookForUse: updatedExpBookForUse,
        };
      } catch (e) {
        return state;
      }
    },
    [PLUS_ALL_EXPBOOK_FOR_USE]: (state, { payload }) => {
      try {
        invariant(state.targetWeapon !== null, `we need target weapon`);
        invariant(
          state.predictedExp < state.tierMaxExp,
          `already reached tier max exp`,
        );

        const payloadExpBook = payload as ExpBook;
        const existingBookIndex = state.expBookForUse.findIndex((book) =>
          isSameExpBook(book.expBook, payloadExpBook),
        );
        const existingBook = state.expBookForUse[existingBookIndex];

        // calculate predictedExp
        let updatedExp = state.predictedExp;
        if (existingBook) {
          updatedExp -= existingBook.expBook.exp * existingBook.useCnt;
        }
        const diff = state.tierMaxExp - updatedExp;
        const needCnt = Math.ceil(diff / payloadExpBook.exp);
        const useCnt =
          needCnt <= payloadExpBook.cnt ? needCnt : payloadExpBook.cnt;
        updatedExp += useCnt * payloadExpBook.exp;

        const updatedBook = { expBook: payloadExpBook, useCnt };
        // updated expBookForUse
        let updatedExpBookForUse: ExpBookForUse[] = [...state.expBookForUse];
        if (existingBookIndex !== -1) {
          updatedExpBookForUse[existingBookIndex] = updatedBook;
        } else {
          updatedExpBookForUse = state.expBookForUse.concat(updatedBook);
        }

        console.log('PLUS_ALL_EXPBOOK_FOR_USE', updatedExpBookForUse);
        return {
          ...state,
          predictedExp: updatedExp,
          expBookForUse: updatedExpBookForUse,
        };
      } catch (e) {
        return state;
      }
    },
    [UPDATE_EXPBOOKS_FOR_USE]: (state, { payload }) => {
      try {
        const payloadExp = (payload as ExpBookForUse).expBook;
        const willUseCnt = (payload as ExpBookForUse).useCnt;

        invariant(
          payloadExp !== null && payloadExp !== undefined,
          'we need exp book for use',
        );
        invariant(willUseCnt !== 0, 'number of book should be more than 0');

        const existingBookIndex = state.expBookForUse.findIndex((book) =>
          isSameExpBook(book.expBook, payloadExp),
        );
        const existingBook: ExpBookForUse | undefined =
          state.expBookForUse[existingBookIndex];

        let updatedExp = state.predictedExp;
        if (existingBook) {
          updatedExp -= existingBook.expBook.exp * existingBook.useCnt;
        }
        updatedExp += willUseCnt * payloadExp.exp;

        const updatedBook = {
          expBook: payloadExp,
          useCnt: willUseCnt,
        };

        let updatedExpBookForUse = [...state.expBookForUse];
        if (existingBookIndex !== -1) {
          updatedExpBookForUse[existingBookIndex] = updatedBook;
        } else {
          updatedExpBookForUse = state.expBookForUse.concat(updatedBook);
        }

        console.log('UPDATE_EXPBOOKS_FOR_USE', updatedExpBookForUse);

        return {
          ...state,
          predictedExp: updatedExp,
          expBookForUse: updatedExpBookForUse,
        };
      } catch (e) {
        return state;
      }
    },
    [CLEAR_EXPBOOKS_FOR_USE]: (state) => {
      return {
        ...state,
        expBookForUse: [],
      };
    },
  },
  initialState,
);

export function isSameExpBook(book1: ExpBook, book2: ExpBook) {
  return book1.name === book2.name && book1.exp === book2.exp;
}

export default levelupReducer;
