import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  MyWalletClose,
  connectWalletClose,
  connectWalletFlowClose,
} from 'store/connectWallet';
import { drawedFalse } from 'store/drawed';
import { modalClose } from 'store/modal';
import { modalUpgradeClose } from 'store/modalUpgrade';
import { clearPack } from 'store/newPack';

const useCleanUpFlow = (): void => {
  const dispatch = useDispatch();

  const cleanUpFlow = useCallback(() => {
    dispatch(modalClose());
    dispatch(MyWalletClose());
    dispatch(connectWalletClose());
    dispatch(connectWalletFlowClose());
    dispatch(modalUpgradeClose());
    dispatch(clearPack());
    dispatch(drawedFalse());
  }, [dispatch]);

  useEffect(() => {
    return cleanUpFlow;
  }, [cleanUpFlow]);
};

export default useCleanUpFlow;
