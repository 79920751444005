import { createAction, handleActions } from 'redux-actions';

import { CardType } from '../types/card';

const SET_PACK = 'newPack/SET_PACK' as const;
const CLEAR_PACK = 'newPack/CLEAR_PACK' as const;

export const setPack = createAction(SET_PACK);
export const clearPack = createAction(CLEAR_PACK);

export type NewPackAction =
  | ReturnType<typeof setPack>
  | ReturnType<typeof clearPack>;

const initialState: CardType[] = [];

const newPack = handleActions(
  {
    [SET_PACK]: (state: CardType[], action: NewPackAction) =>
      state.concat(action.payload),
    [CLEAR_PACK]: (state: CardType[]) => (state = []),
  },
  initialState,
);

export default newPack;
