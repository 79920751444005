import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '../../components/Card';

import { packing } from '../../lib/packing';

import { CardType } from '../../types/card';

import '../../scss/draw/DrawResult.scss';

import { RootState } from '../../store';
import { modalSoulZodiacOpen } from '../../store/modalSoulZodiac';

const DrawResult: React.FC = () => {
  const dispatch = useDispatch();

  const newPacks = useSelector((state: RootState) => state.newPack);
  const packingNewPacks = packing(newPacks);

  useEffect(() => {
    const soulZodiacCard: CardType | undefined = newPacks.find(
      (card: CardType) => card.tier === 'Soul Zodiac',
    );

    if (soulZodiacCard) {
      dispatch(modalSoulZodiacOpen(soulZodiacCard));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {packingNewPacks.map((pack) => (
        <section className="draw-result" key={pack.packNum}>
          <h2>Pack #{pack.packNum}</h2>

          <div className="card-pack">
            {pack.pack.map((card) => (
              <Card
                tier={card.tier}
                animal={card.animal}
                starSign={card.starSign}
                image={card.image}
                key={card.id}
              />
            ))}
          </div>
        </section>
      ))}
    </>
  );
};

export default DrawResult;
