import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { selectForUpgrade, unSelectForUpgrade } from 'store/weaponUpgrade';

import { WeaponType } from '../../../types/weapon';

import classes from './UpgradableWeaponList.module.scss';

import GradientScrollFog from '../../UI/GradientScrollFog';

import UpgradableWeaponItem from './UpgradableWeaponItem';

const UpgradableWeaponList = ({ weapons }: { weapons: WeaponType[] }) => {
  const weaponListRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const weaponSelectHandler = useCallback(
    (selected: WeaponType) => {
      dispatch(selectForUpgrade(selected));
    },
    [dispatch],
  );

  const weaponUnselectHandler = useCallback(
    (selected: WeaponType) => {
      dispatch(unSelectForUpgrade(selected));
    },
    [dispatch],
  );

  return (
    <>
      Required number of weapons needed
      <div className={classes.weaponList} ref={weaponListRef}>
        {weapons.map((w) => (
          <UpgradableWeaponItem
            key={w.id}
            weapon={w}
            onSelect={weaponSelectHandler}
            onUnselect={weaponUnselectHandler}
          />
        ))}
        <div
          style={{
            width: '471px',
            height: '80px',
            position: 'absolute',
            bottom: '116px',
          }}
        >
          <GradientScrollFog parentRef={weaponListRef} />
        </div>
      </div>
    </>
  );
};

export default UpgradableWeaponList;
