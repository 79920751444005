import '../scss/Button.scss';

type ButtonProps = {
  title: string;
  width?: string;
  height?: string;
  fontSize?: string;
  textColor?: string;
  buttonStyle?: string;
  onClick?: any;
  isDisabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  title,
  width = '280px',
  height = '52px',
  fontSize,
  textColor,
  buttonStyle = 'orange-gradient',
  onClick,
  isDisabled,
}) => {
  const size = {
    width: width,
    height: height,
    fontSize,
    textColor,
  };

  return (
    <button
      className={isDisabled ? 'grey-disable' : buttonStyle}
      style={size}
      onClick={isDisabled ? undefined : onClick}
      disabled={isDisabled}
    >
      {title}
    </button>
  );
};

export default Button;
