import CardPropertiesTable from '../../components/CardPropertiesTable';
import VideoTag from '../../components/VideoTag';

import { getCardPath } from '../../lib/cardPath';

import '../../scss/home/AboutUntouchable.scss';

import { ReactComponent as Crown } from '../../assets/icons/crown.svg';

const AboutUntouchable: React.FC = () => {
  return (
    <section className="about-untouchable">
      <div>
        <div className="unctouchable-video">
          <Crown />
          <VideoTag path={getCardPath('Untouchable', 'Tiger', 'Virgo')} />
        </div>
        <div className="untouchable-properties">
          <h1>Tier 5 : Untouchable</h1>
          <CardPropertiesTable
            tier="Untouchable"
            starSign="Random"
            land="Yes"
            motion="Yes"
            edge="No"
            colorBlack={true}
          />
          <p>
            Untouchable Tiers represent
            <br className="mobile" /> a special celebration or event.
            <br />
            For instance, year 2022 is the year of the Black Tiger.
            {/* <br className="web" />
                        special event like charity. <br className="mobile" />
                        They can only be purchased via auction. */}
            <br />
            You can get it only via auction.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUntouchable;
