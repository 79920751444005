import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { BigNumber } from 'ethers';

import { useERC20Contract } from 'hooks/useContract';

const useTokenBalanceQuery = (tokenAddress: string, owner?: string) => {
  const tokenContract = useERC20Contract(tokenAddress);
  const fetcher = useCallback(async () => {
    if (!owner) return BigNumber.from(0);
    const decimal = await tokenContract.decimals();
    const balance = await tokenContract.balanceOf(owner);
    return balance.div(BigNumber.from(10).pow(decimal));
  }, [owner, tokenContract]);

  const { data, isLoading } = useQuery(
    ['balance', tokenAddress, owner],
    fetcher,
    { refetchInterval: 1000 * 60, enabled: !!owner },
  );

  return { balance: data, isLoading };
};

export default useTokenBalanceQuery;
