import '../../scss/home/AboutPartners.scss';

import Animoca from '../../assets/images/animoca.png';
import Anmation from '../../assets/images/anmation.png';
import Astronaut from '../../assets/images/astronaut.png';
import Au21 from '../../assets/images/au21.png';
import Basics from '../../assets/images/basics.png';
import Brinc from '../../assets/images/brinc.png';
import Bybit from '../../assets/images/bybit.png';
import Culture from '../../assets/images/culture.png';
import Dweb3 from '../../assets/images/dweb3.png';
import Evernew from '../../assets/images/evernew.png';
import Gate from '../../assets/images/gate.png';
import Gbic from '../../assets/images/gbic.png';
import Guildfi from '../../assets/images/guildfi.png';
import Hg from '../../assets/images/hg.png';
import Oost from '../../assets/images/iost.png';
import Kucoin from '../../assets/images/kucoin.png';
import Kyros from '../../assets/images/kyros.png';
import Loopring from '../../assets/images/loopring.png';
import M6 from '../../assets/images/m6.png';
import Morningstar from '../../assets/images/morningstar.png';
import Panony from '../../assets/images/panony.png';
// import Terabyte from "../../assets/images/terabyte.png";
import Parachain from '../../assets/images/parachain.png';
import Polkastarter from '../../assets/images/polkastarter.png';
import Polygon from '../../assets/images/polygon.png';
import Rok from '../../assets/images/rok.png';
import Sl2 from '../../assets/images/sl2.png';
import Tayssir from '../../assets/images/tayssir.png';
import Tkventures from '../../assets/images/tkventures.png';
import Tpx from '../../assets/images/tpx.png';
import Uva from '../../assets/images/uva.png';
import Vanda from '../../assets/images/vanda.png';

const partners = [
  Tpx,
  Animoca,
  Brinc,
  Kucoin,
  Polygon,
  Polkastarter,
  Oost,
  Loopring,
  Bybit,
  Au21,
  Morningstar,
  Rok,
  Hg,
  Guildfi,
  Basics,
  Astronaut,
  Evernew,
  M6,
  Kyros,
  Uva,
  Tayssir,
  // Terabyte,
  Parachain,
  Vanda,
  Anmation,
  Sl2,
  Culture,
  Gbic,
  Dweb3,
  Tkventures,
  Panony,
  Gate,
];

const AboutPartners: React.FC = () => {
  return (
    <section className="about-partners">
      <h1>Our Partners</h1>
      <div className="partners-wrapper">
        {partners.map((partner) => (
          <img src={partner} alt="" key={partner} />
        ))}
      </div>
    </section>
  );
};

export default AboutPartners;
