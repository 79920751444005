import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper.min.css';

import 'swiper/components/navigation/navigation.min.css';

import { getCardPath } from '../lib/cardPath';
import { cardAdArray } from '../lib/zodiac';

import '../scss/CardAd.scss';

import VideoTag from './VideoTag';

SwiperCore.use([Pagination]);

const CardAd: React.FC = () => {
  return (
    <>
      {cardAdArray.length ? (
        cardAdArray.length === 1 ? (
          <div className="card-ad">
            <VideoTag
              path={getCardPath(
                cardAdArray[0].tier,
                cardAdArray[0].animal,
                cardAdArray[0].starSign,
              )}
            />
            <h4>
              Tiger <span>Untouchable</span>
            </h4>
            <p>{cardAdArray[0].comment}</p>
          </div>
        ) : (
          <Swiper spaceBetween={30} pagination={{ clickable: true }}>
            {cardAdArray.map((card) => (
              <SwiperSlide key={card.tier + card.animal + card.starSign}>
                <div className="card-ad">
                  <VideoTag
                    path={getCardPath(card.tier, card.animal, card.starSign)}
                  />
                  <h4>
                    {card.animal} <span>{card.starSign}</span>
                  </h4>
                  <p>{card.comment}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default CardAd;
