import { createAction, handleActions } from 'redux-actions';

import { CardType } from '../types/card';

const OPEN = 'modalSoulZodiac/OPEN' as const;
const CLOSE = 'modalSoulZodiac/CLOSE' as const;

export const modalSoulZodiacOpen = createAction(OPEN);
export const modalSoulZodiacClose = createAction(CLOSE);

export type ModalSoulZodiacAction =
  | ReturnType<typeof modalSoulZodiacOpen>
  | ReturnType<typeof modalSoulZodiacClose>;

export type ModalSoulZodiacState = {
  open: boolean;
  card: CardType;
};

const initalState: ModalSoulZodiacState = {
  open: false,
  card: {
    id: '',
    tier: '',
    animal: '',
    starSign: '',
    image: '',
    checked: false,
  },
};

const modalSoulZodiac = handleActions(
  {
    [OPEN]: (state: ModalSoulZodiacState, action: ModalSoulZodiacAction) => ({
      ...state,
      open: true,
      card: { ...action.payload },
    }),

    [CLOSE]: (state: ModalSoulZodiacState) => ({ ...state, open: false }),
  },
  initalState,
);

export default modalSoulZodiac;
