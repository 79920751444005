import { createAction, handleActions } from 'redux-actions';

const OPEN = 'connectWallet/OPEN' as const;
const CLOSE = 'connectWallet/CLOSE' as const;
const TOGGLE = 'connectWallet/TOGGLE' as const;
const FLOW = 'connectWallet/FLOW' as const;
const FLOWCLOSE = 'connectWallet/FLOWCLOSE' as const;
const MYWALLET = 'connectWallet/MYWALLET' as const;
const MYWALLETCLOSE = 'connectWallet/MYWALLETCLOSE' as const;

export const connectWalletOpen = createAction(OPEN);
export const connectWalletClose = createAction(CLOSE);
export const connectWalletToggle = createAction(TOGGLE);
export const connectWalletFlow = createAction(FLOW);
export const connectWalletFlowClose = createAction(FLOWCLOSE);
export const MyWallet = createAction(MYWALLET);
export const MyWalletClose = createAction(MYWALLETCLOSE);

export type ConnectWalletAction =
  | ReturnType<typeof connectWalletOpen>
  | ReturnType<typeof connectWalletClose>
  | ReturnType<typeof connectWalletToggle>;

export type ConnectWalletState = {
  open: boolean;
  flow: boolean;
  myWallet: boolean;
};

const initialState: ConnectWalletState = {
  open: false,
  flow: false,
  myWallet: false,
};

const connectWallet = handleActions(
  {
    [OPEN]: (state: ConnectWalletState) => ({ ...state, open: true }),
    [CLOSE]: (state: ConnectWalletState) => ({ ...state, open: false }),
    [TOGGLE]: (state: ConnectWalletState) => ({
      ...state,
      open: !state.open,
    }),
    [FLOW]: (state: ConnectWalletState) => ({ ...state, flow: true }),
    [FLOWCLOSE]: (state: ConnectWalletState) => ({ ...state, flow: false }),
    [MYWALLET]: (state: ConnectWalletState) => ({
      ...state,
      myWallet: true,
    }),
    [MYWALLETCLOSE]: (state: ConnectWalletState) => ({
      ...state,
      myWallet: false,
    }),
  },
  initialState,
);

export default connectWallet;
