import { useDispatch } from 'react-redux';

import Paragraph from '../components/Paragraph';

import '../scss/NonConnect.scss';

import { ReactComponent as NonConnectIcon } from '../assets/icons/non_connect.svg';
import { connectWalletToggle } from '../store/connectWallet';

type NonConnectProps = {
  title: string;
  description: string;
  buttonTitle: string;
};

const NonConnect: React.FC<NonConnectProps> = ({
  title,
  description,
  buttonTitle,
}) => {
  const dispatch = useDispatch();

  return (
    <section className="non-connect">
      <Paragraph title={title} description={description} />
      <button onClick={() => dispatch(connectWalletToggle())}>
        <NonConnectIcon />
        {buttonTitle}
      </button>
    </section>
  );
};

export default NonConnect;
