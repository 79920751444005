import { useWeb3React } from '@web3-react/core';

import { ZODIUM, chainId } from 'config';

import { injectedConnector, injectedWalletConnector } from 'connector';

const useWeb3 = () => {
  const { active, account, library, connector, activate, deactivate } =
    useWeb3React();

  const connect = async (type: string) => {
    try {
      if (type === 'metamask') {
        await changeToBinanceChain();
        await activate(injectedConnector);
      } else {
        await activate(injectedWalletConnector);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const disconnect = async () => {
    try {
      await deactivate();
    } catch (error) {
      console.error(error);
    }
  };

  return { connect, disconnect, account, active, library, connector };
};

async function changeToBinanceChain() {
  const binanceChainId = '0x' + chainId.toString(16);

  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: binanceChainId }],
    });
  } catch (e: any) {
    if (e.code === 4902) {
      console.log('request add chain');
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainName: 'Binance',
            chainId: binanceChainId,
            nativeCurrency: {
              name: 'BNB',
              decimals: 18,
              symbol: 'BNB',
            },
            rpcUrls: ['https://bsc-dataseed.binance.org/'],
          },
        ],
      });
    }
  }
}

const addTokenToMetamask = async () => {
  try {
    const wasZodiAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: ZODIUM,
          symbol: 'ZODI',
          decimals: 18,
          // image: "",
        },
      },
    });
    if (!wasZodiAdded) alert('추가 실패');
    // const wasCharisAdded = await window.ethereum.request({
    //     method: "wallet_watchAsset",
    //     params: {
    //         type: "ERC20",
    //         options: {
    //             address: "",
    //             symbol: "CHARIS",
    //             decimals: 18,
    //             // image: "",
    //         },
    //     },
    // });
  } catch (e) {
    console.log(e);
  }
};

export { useWeb3, addTokenToMetamask };
