import { useState } from 'react';
import Clipboard from 'react-clipboard.js';

import '../scss/WalletAddress.scss';

import { ReactComponent as ClipBoard } from '../assets/icons/clipboard.svg';
import Copied from '../assets/images/copied.png';
import Copy from '../assets/images/copy.png';

type WalletAddressProps = {
  account: string | null | undefined;
  short?: boolean;
  prepend?: string;
};

const WalletAddress: React.FC<WalletAddressProps> = ({
  account,
  short = false,
  prepend = '',
}) => {
  const [copied, setCopied] = useState<boolean>(false);

  const accountString = (
    account: string | null | undefined,
    short: boolean,
  ): string => {
    if (account) {
      if (short) {
        const accountHead = account.substr(0, 7);
        const accountTail = account.substr(account.length - 6, account.length);
        return `${accountHead}...${accountTail}`;
      } else return account;
    } else {
      return 'No Wallet Address';
    }
  };

  const copy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <Clipboard
      data-clipboard-text={accountString(account, false)}
      className="wallet-address"
      onClick={copy}
    >
      <p>
        {prepend}
        {accountString(account, short)}
      </p>
      <ClipBoard />
      {/* <img src={Copy} alt="" className="copy" /> */}
      {!copied && <img src={Copy} alt="" className="copy" />}
      {copied && (
        <img src={Copied} alt="" className={`copied ${short ? 'short' : ''}`} />
      )}
    </Clipboard>
  );
};

export default WalletAddress;
