import { ethers } from 'ethers';

import { useWalletBaseContract } from './useContract';

const useBirth = () => {
  const walletBase = useWalletBaseContract();

  const getbirth = async () => {
    try {
      let birth = await walletBase.getBirth();
      return birth;
    } catch (error) {
      throw error;
    }
  };

  const setbirth = async (year: string, month: string, day: string) => {
    try {
      await walletBase.setBirth(
        _typecasting(year),
        _typecasting(month),
        _typecasting(day),
      );
    } catch (error) {
      throw error;
    }
  };

  return { getbirth, setbirth };
};

//NOTE x util
const _typecasting = (v: number | string) => {
  return ethers.BigNumber.from(v);
};

export { useBirth };
