import { ZodiacClassficationTier } from '../hooks/useClassification';

import '../scss/ClassificationTier.scss';

import Button from './Button';

type ClassificationTierProps = {
  classificationTier: ZodiacClassficationTier;
  selectTier: (tier: any) => void;
};

const ClassificationTier: React.FC<ClassificationTierProps> = ({
  classificationTier,
  selectTier,
}) => {
  return (
    <section className="classification-tier-wrapper">
      <h3>Tier</h3>
      <div className="classification-tier">
        {classificationTier.map((c) => (
          <Button
            key={c.tier}
            title={c.tier}
            buttonStyle={`grey-round-border ${c.checked ? 'checked' : ''}`}
            width="unset"
            height="44px"
            onClick={() => selectTier(c.tier)}
          />
        ))}
      </div>
    </section>
  );
};

export default ClassificationTier;
