import { useState } from 'react';

import Weapons from '../containers/Weapons';
import Zodiac from '../containers/Zodiac';

import CategoryTab from '../components/CategoryTab';
import Paragraph from '../components/Paragraph';

import '../scss/Zodiverse.scss';

const Zodiverse: React.FC = () => {
  const [category, setCategory] = useState('KINDs');
  return (
    <main className="zodiac">
      <Paragraph
        title="Explore the Zodiverse!"
        description={`Meet the 12 KINDs and Weapons in different Tiers, Raritys, Types, Elements!`}
      />
      <CategoryTab
        onChangeCategory={setCategory}
        categories={[
          {
            key: 'KINDs',
            title: 'KINDs',
          },
          {
            key: 'Weapons',
            title: 'Weapons',
          },
        ]}
      />
      {category === 'KINDs' ? <Zodiac /> : <Weapons />}
    </main>
  );
};

export default Zodiverse;
