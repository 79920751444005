import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import DrawWeapon from './pages/DrawWeapon';
import DrawZodiac from './pages/DrawZodiac';
import FindZodi from './pages/FindZodi';
import Home from './pages/Home';
import MyCollection from './pages/MyCollection';
import Upgrade from './pages/Upgrade';
import V2Transform from './pages/V2Transform';
import Zodiverse from './pages/Zodiverse';

import Footer from './containers/Footer';
import Header from './containers/Header';
import ModalConnectWalletFlow from './containers/ModalConnectWalletFlow';
import ModalSoulZodiac from './containers/ModalSoulZodiac';
import ModalUpgrade from './containers/ModalUpgrade';
import MyWallet from './containers/MyWallet';

import Modal from './components/Modal';
import ModalConnectWallet from './components/ModalConnectWallet';

import './scss/reset.css';
import { RootState } from './store';

const App: React.FC = () => {
  const connectWalletState = useSelector(
    (state: RootState) => state.connectWallet,
  );

  const modalState = useSelector((state: RootState) => state.modal.open);

  const modalUpgradeState = useSelector(
    (state: RootState) => state.modalUpgrade.open,
  );

  const modalSoulZodiacState = useSelector(
    (state: RootState) => state.modalSoulZodiac.open,
  );

  return (
    <>
      <Header />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/v2-transform" component={V2Transform} />
        <Route path="/drawZodiac" component={DrawZodiac} />
        <Route path="/drawWeapon" component={DrawWeapon} />
        <Route path="/myCollection" component={MyCollection} />
        <Route path="/upgrade" component={Upgrade} />
        <Route path="/zodiverse" component={Zodiverse} />
        <Route path="/findZodi" component={FindZodi} />
      </Switch>
      {connectWalletState.open && <ModalConnectWallet />}
      {connectWalletState.flow && <ModalConnectWalletFlow />}
      {connectWalletState.myWallet && <MyWallet />}
      {modalState && <Modal />}
      {modalUpgradeState && <ModalUpgrade />}
      {modalSoulZodiacState && <ModalSoulZodiac />}
      <Footer />
    </>
  );
};

export default App;
