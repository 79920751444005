import { useSelector } from 'react-redux';

import { RootState } from 'store';

import useDerivedWeaponInfo from 'hooks/useDerivedWeaponInfo';

import WeaponDetailCard from './WeaponDetailCard';

const UpgradeDetailInfo = () => {
  const canUpgradeNow = useSelector(
    (state: RootState) => state.weaponUpgradeReducer.canUpgradeNow,
  );
  const weapon = useSelector(
    (state: RootState) => state.weaponUpgradeReducer.targetWeapon,
  );

  const {
    level: curLevel,
    tierMaxLevel: curMaxLevel,
    power: curPower,
    stackedExp: curExp,
    tierMaxExp: curMaxExp,
  } = useDerivedWeaponInfo(weapon ?? undefined);

  const {
    power: nextPower,
    level: nextLevel,
    tierMaxLevel: nextMaxLevel,
    tierMaxExp: nextMaxExp,
  } = useDerivedWeaponInfo(weapon ?? undefined, true);

  return canUpgradeNow && weapon ? (
    <WeaponDetailCard
      curLevel={curLevel ?? 0}
      curMaxLevel={curMaxLevel ?? 0}
      nextLevel={nextLevel ?? 0}
      nextMaxLevel={nextMaxLevel ?? 0}
      curExp={curExp ?? 0}
      curMaxExp={curMaxExp ?? 0}
      nextExp={curExp ?? 0}
      nextMaxExp={nextMaxExp ?? 0}
      power={curPower ?? 0}
      nextPower={nextPower ?? 0}
    />
  ) : (
    <WeaponDetailCard
      curLevel={0}
      curMaxLevel={0}
      nextLevel={0}
      nextMaxLevel={0}
      curExp={0}
      curMaxExp={0}
      nextExp={0}
      nextMaxExp={0}
      power={0}
      nextPower={0}
    />
  );
};

export default UpgradeDetailInfo;
